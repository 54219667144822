import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { APIURL } from "../../Methods/Fetch";
import { axiosGet } from "../../Methods/Save";
import Menu from "../DriverDashboard/Menu";
import EventModal from "./EventModal";
import SyncLoader from "react-spinners/SyncLoader";

const localizer = momentLocalizer(moment);
function Body(props) {
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };
  const { driverDetails } = props;
  const [selectedEvent, setSelectedEvent] = useState({});
  const [eventsData, setEventsData] = useState([]);
  useEffect(() => {
    setLoading(true);
    var url = APIURL() + `event-show/${driverDetails.id}`;
    axiosGet(url).then((response) => {
      var arr = [];
      response.data[0].response.data.forEach((element) => {
        var start_date = element.start.split(",");
        var end_date = element.end.split(",");
        arr.push({
          id: element.id,
          start: new Date(
            start_date[0],
            start_date[1] - 1,
            start_date[2],
            start_date[3],
            start_date[4],
            start_date[5]
          ),
          end: new Date(
            end_date[0],
            end_date[1] - 1,
            end_date[2],
            end_date[3],
            end_date[4],
            end_date[5]
          ),
          booking_no: element.booking_no,
          resturant: element.resturant,
          title: element.title,
          username: element.username,
          price: element.price,
        });
      });
      setEventsData(arr);
      setLoading(false);
    });
  }, []);

  // const handleSelect = ({ start, end }) => {
  //   const title = window.prompt("New Event name");
  //   if (title)
  //     setEventsData([
  //       ...eventsData,
  //       {
  //         start,
  //         end,
  //         title,
  //       },
  //     ]);
  // };
  console.log(eventsData);
  const [loading, setLoading] = useState(false);
  return (
    <>
      <SyncLoader
        loading={loading}
        className="react-loader-new"
        color="#9a13d2"
      />
      <section className="banner-sec">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 mx-auto">
              <div className="driver-dashboard-page">
                <Menu driverDetails={driverDetails} />
                <div className="row">
                  <Calendar
                    views={["day", "month"]}
                    selectable
                    localizer={localizer}
                    defaultDate={new Date()}
                    defaultView="month"
                    events={eventsData}
                    style={{ height: "100vh" }}
                    onSelectEvent={(event) => {
                      setModal(true);
                      setSelectedEvent(event);
                    }}
                    // onSelectSlot={handleSelect}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <EventModal modal={modal} toggle={toggle} selectedEvent={selectedEvent} />
    </>
  );
}

export default Body;
