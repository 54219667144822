import React, { useState } from "react";
import VideoModal from "../../OrderProcess/StepOne/VideoModal";

function VideoScreen() {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  return (
    <>
      <div className="book-data2">
        <div className="row">
          <div className="col-lg-12">
            <div className="event-title">
              <h4>How to</h4>
            </div>
          </div>
        </div>
        <div>
          <video
            id="myVideo2"
            onClick={toggle}
            data-bs-toggle="modal"
            data-bs-target="#myModal"
            muted
            autoPlay
            loop
            playsInline
          >
            <source src="images/sample-video.mp4" type="video/mp4" />
            Your browser does not support HTML5 video.
          </video>
          <a
            onClick={toggle}
            href="#!"
            data-bs-toggle="modal"
            data-bs-target="#myModal"
          >
            <i className="fas fa-expand" />
          </a>
        </div>
        <VideoModal toggle={toggle} modal={modal} setModal={setModal} />
      </div>
    </>
  );
}

export default VideoScreen;
