import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import DriverMenus from "../Components/Header/DriverMenus";
import MobileMenu from "../Components/Header/MobileMenu";
import UserMenus from "../Components/Header/UserMenus";
import { APIURL } from "../Methods/Fetch";
import { axiosGet } from "../Methods/Save";
import LoginModal from "./LoginModal";
import NewsLetter from "../Components/Footer/NewsLetter";

function Header(props) {
  const navigate = useNavigate();
  const [loginModal, setLoginModal] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [notification, setNotification] = useState([]);
  const { dashBoard, userDetails, driver, driverDetails } = props;

  const [loggedIn, setLoggedIn] = useState(false);
  const [driverLoggedIn, setDriverLoggedIn] = useState(false);
  useEffect(() => {
    if (localStorage.getItem("userId") != null) setLoggedIn(true);
    if (localStorage.getItem("driverId") != null) setDriverLoggedIn(true);
  }, []);
  useEffect(() => {
    if (userDetails) {
      var url = APIURL() + `user-notifications/${userDetails.id}`;
      axiosGet(url).then((response) => {
        setNotification(response.data[0].response.data);
      });
    }
  }, [userDetails, refresh]);
  const readNotification = (id) => {
    var url = APIURL() + `user-read-notifications/${userDetails.id}/${id}`;
    axiosGet(url).then((response) => {
      setRefresh(!refresh);
    });
  };
  const [openNotification, setOpenNotification] = useState(false);
  const toggleLoginModal = () => {
    setLoginModal(!loginModal);
  };
  const [activeTab, setActiveTab] = useState();
  const changeTab = (tab) => {
    if (tab == activeTab) {
      setActiveTab();
    } else setActiveTab(tab);
  };
  const toggleMenu = () => {
    setOpenMenu(!openMenu);
  };
  return (
    <>
      <div id="container">
        <div className="sticky-header">
          <MobileMenu loggedIn={loggedIn} driverLoggedIn={driverLoggedIn} />
          {openMenu ? (
            <button
              class={dashBoard ? "menu-btn-dashboard mobile-sec" : "menu-btn"}
              onClick={(e) => setOpenMenu(!openMenu)}
            >
              &#9587;
            </button>
          ) : (
            <button
              class={dashBoard ? "menu-btn-dashboard mobile-sec" : "menu-btn"}
              onClick={(e) => setOpenMenu(!openMenu)}
            >
              &#9776;
            </button>
          )}
          <div className="container-fluid">
            <div className="row">
              <div className="sticky-headermain">
                <div className="sticky-header1">
                  <a
                    className={
                      openMenu
                        ? "mobile-menu-toggle js-toggle-menu hamburger-menu open"
                        : "mobile-menu-toggle js-toggle-menu hamburger-menu"
                    }
                    href="#"
                    onClick={toggleMenu}
                  >
                    <span className="menu-item" />
                    <span className="menu-item" />
                    <span className="menu-item" />
                  </a>
                  {openMenu && (
                    <nav className="mobile-nav-wrap" role="navigation">
                      <ul className="mobile-header-nav">
                        <li>
                          <Link to="/what-we-do">What We Do</Link>
                        </li>
                        <li>
                          <Link to="/blogs">Blog</Link>
                        </li>
                        <li>
                          <Link to="/contact-us">Contact Us</Link>
                        </li>
                        <li>
                          <Link to="/login">Login</Link>
                        </li>
                        <li>
                          <Link to="/register">Signup</Link>
                        </li>
                      </ul>
                    </nav>
                  )}
                </div>
                <div className="sticky-header2">
                  <Link to="/">
                    <img src="/images/logo-wh.png" alt />
                  </Link>
                </div>
                {dashBoard ? (
                  <div className="pf-menu-pic sticky-header3">
                    <div className="btn-nt">
                      <div className="dropdown">
                        <a
                          href="#"
                          role="button"
                          id="Notification"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          onClick={(e) =>
                            setOpenNotification(!openNotification)
                          }
                        >
                          <span>
                            <i className="fas fa-bell" />
                          </span>
                          <div className="ntd-ctn">
                            <span>
                              {notification && notification.length > 0
                                ? notification.length
                                : "0"}
                            </span>
                          </div>
                        </a>
                        <div
                          className={
                            openNotification
                              ? "dropdown-menu new_drop_cont show"
                              : "dropdown-menu new_drop_cont"
                          }
                          aria-labelledby="Notification"
                          style={{}}
                        >
                          <div className="no-text">
                            {notification && notification.length > 0
                              ? notification.map((noti) => (
                                  <a
                                    onClick={(e) => readNotification(noti.id)}
                                    className="btn_anchor"
                                  >
                                    {" "}
                                    <p>{noti.data}</p>
                                  </a>
                                ))
                              : ""}
                          </div>
                          <div className="show-more-box">
                            <Link to="/user-notifications" className="show-btn">
                              Show More
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    {driver ? (
                      <DriverMenus driverDetails={driverDetails} />
                    ) : (
                      <UserMenus />
                    )}
                  </div>
                ) : (
                  <div className="sticky-header3">
                    <ul>
                      <li className="nav-item">
                        {loggedIn ? (
                          <Link to="/dashboard" className="btn_anchor">
                            <i className="fal fa-sign-in-alt" />
                            Dashboard
                          </Link>
                        ) : driverLoggedIn ? (
                          <Link
                            to="/driver-dashboard"
                            className="login-btn nav-link"
                          >
                            <i className="fal fa-sign-in-alt" />
                            Driver Dashboard
                          </Link>
                        ) : (
                          <ul>
                            <li>
                              <a
                                onClick={() => setLoginModal(!loginModal)}
                                className="btn_anchor"
                              >
                                <i className="fal fa-sign-in-alt" />
                                Login
                              </a>
                            </li>
                            <li>
                              <a
                                onClick={() => {
                                  setShowRegister(true);
                                  setLoginModal(!loginModal);
                                }}
                                className="btn_anchor"
                              >
                                <i className="fal fa-sign-in-alt" />
                                Signup
                              </a>
                            </li>
                          </ul>
                        )}
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <footer className="fullscreen-btm">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-8">
                <div className="row br-footer">
                  <div className="col-lg-12 col-md-12 mx-auto">
                    <div className="footer-tabbing">
                      <ul className="nav nav-tabs">
                        <li className="nav-item">
                          <a
                            className={
                              activeTab == 1 ? "nav-link active" : "nav-link"
                            }
                            data-bs-toggle="tab"
                            href="#footer-tab1"
                            onClick={(e) => changeTab(1)}
                          >
                            Legal <i className="far fa-angle-right" />
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className={
                              activeTab == 2 ? "nav-link active" : "nav-link"
                            }
                            data-bs-toggle="tab"
                            href="#footer-tab2"
                            onClick={(e) => changeTab(2)}
                          >
                            About Us <i className="far fa-angle-right" />
                          </a>
                        </li>
                        {/* <li className="nav-item">
                    <a
                      className={
                        activeTab == 2 ? "nav-link active" : "nav-link"
                      }
                      data-bs-toggle="tab"
                      href="#footer-tab2"
                      onClick={(e) => changeTab(2)}
                    >
                      Service <i className="far fa-angle-right" />
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={
                        activeTab == 3 ? "nav-link active" : "nav-link"
                      }
                      data-bs-toggle="tab"
                      href="#footer-tab3"
                      onClick={(e) => changeTab(3)}
                    >
                      Support <i className="far fa-angle-right" />
                    </a>
                  </li> */}
                        <li className="nav-item">
                          <a
                            className={
                              activeTab == 4 ? "nav-link active" : "nav-link"
                            }
                            data-bs-toggle="tab"
                            href="#footer-tab4"
                            onClick={(e) => changeTab(4)}
                          >
                            Join Us <i className="far fa-angle-right" />
                          </a>
                        </li>
                      </ul>
                      <div className="tab-content">
                        <div
                          className={
                            activeTab == 1 ? "tab-pane active" : "tab-pane"
                          }
                          id="footer-tab1"
                        >
                          <div className="footer-tabbing-link">
                            <div className="row">
                              <div className="col-lg-4 col-md-4">
                                <ul>
                                  <li>
                                    <NavLink
                                      activeClassName="active"
                                      to="/terms"
                                    >
                                      Terms and Conditions
                                    </NavLink>
                                  </li>
                                  <li>
                                    <NavLink
                                      activeClassName="active"
                                      to="/privacy"
                                    >
                                      Privacy
                                    </NavLink>
                                  </li>
                                </ul>
                              </div>
                              <div className="col-lg-8 col-md-8">
                                <NewsLetter />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className={
                            activeTab == 2 ? "tab-pane active" : "tab-pane"
                          }
                          id="footer-tab2"
                        >
                          <div className="footer-tabbing-link">
                            <div className="row">
                              <div className="col-lg-4 col-md-4">
                                <ul>
                                  <li>
                                    <NavLink
                                      activeClassName="active"
                                      to="/blogs"
                                    >
                                      Blog
                                    </NavLink>
                                  </li>
                                  <li>
                                    <NavLink
                                      activeClassName="active"
                                      to="/become-a-driver"
                                    >
                                      Become a driver
                                    </NavLink>
                                  </li>
                                  <li>
                                    <NavLink activeClassName="active" to="/">
                                      Booking
                                    </NavLink>
                                  </li>
                                  <li>
                                    <NavLink
                                      activeClassName="active"
                                      to="/contact-us"
                                    >
                                      Contact us
                                    </NavLink>
                                  </li>
                                  <li>
                                    <NavLink activeClassName="active" to="/faq">
                                      FAQ
                                    </NavLink>
                                  </li>
                                  <li>
                                    <NavLink
                                      activeClassName="active"
                                      to="/driver-login"
                                    >
                                      Create Ticket
                                    </NavLink>
                                  </li>
                                  <li>
                                    <NavLink
                                      activeClassName="active"
                                      to="/about-us"
                                    >
                                      About Us
                                    </NavLink>
                                  </li>
                                </ul>
                              </div>
                              <div className="col-lg-8 col-md-8">
                                <NewsLetter />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className={
                            activeTab == 3 ? "tab-pane active" : "tab-pane"
                          }
                          id="footer-tab3"
                        >
                          <div className="footer-tabbing-link">
                            <div className="row">
                              <div className="col-lg-4 col-md-4">
                                <ul>
                                  <li>
                                    <NavLink
                                      activeClassName="active"
                                      to="/contact-us"
                                    >
                                      Contact us
                                    </NavLink>
                                  </li>
                                  <li>
                                    <NavLink activeClassName="active" to="/faq">
                                      FAQ
                                    </NavLink>
                                  </li>
                                  <li>
                                    <NavLink
                                      activeClassName="active"
                                      to="/driver-login"
                                    >
                                      Create Ticket
                                    </NavLink>
                                  </li>
                                  <li>
                                    <NavLink
                                      activeClassName="active"
                                      to="/about-us"
                                    >
                                      About Us
                                    </NavLink>
                                  </li>
                                </ul>
                              </div>
                              <div className="col-lg-8 col-md-8">
                                <NewsLetter />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className={
                            activeTab == 4 ? "tab-pane active" : "tab-pane"
                          }
                          id="footer-tab4"
                        >
                          <div className="footer-tabbing-link">
                            <div className="row">
                              <div className="col-lg-4 col-md-4">
                                <ul>
                                  <li>
                                    <NavLink
                                      activeClassName="active"
                                      to="/marketing-intern"
                                    >
                                      Marketing intern
                                    </NavLink>
                                  </li>
                                  <li>
                                    <NavLink
                                      activeClassName="active"
                                      to="/editor"
                                    >
                                      Editor
                                    </NavLink>
                                  </li>
                                  <li>
                                    <NavLink
                                      activeClassName="active"
                                      to="/become-a-driver"
                                    >
                                      Drivers
                                    </NavLink>
                                  </li>
                                </ul>
                              </div>
                              <div className="col-lg-8 col-md-8">
                                <NewsLetter />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="row">
                  <div className="col-lg-12 text-center">
                    <p className="copy-right">
                      © 2023 <a href="#">NOPLAN</a> All rights reserved.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>

      <LoginModal
        loginModal={loginModal}
        toggleLoginModal={toggleLoginModal}
        showRegister={showRegister}
        setShowRegister={setShowRegister}
      />
    </>
  );
}

export default Header;
