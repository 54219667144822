import React, { useEffect } from "react";
import { useState } from "react";
import { APIURL } from "../../Methods/Fetch";
import { axiosGet } from "../../Methods/Save";
import { SyncLoader } from "react-spinners";

function Body() {
  const [pageDetails, setPageDetails] = useState([]);

  useEffect(() => {
    setLoading(true);
    var url = APIURL() + `our-partners`;
    axiosGet(url).then((response) => {
      setPageDetails(response.data[0].response.data);
      setLoading(false);
    });
  }, []);
  const [loading, setLoading] = useState(false);

  return (
    <>
      <SyncLoader
        loading={loading}
        className="react-loader-new"
        color="#9a13d2"
      />
      <div className="bodywork-sec">
        <div className="row align-items-center">
          <div className="col-lg-12">
            <div className="partner-main">
              <div className="row justify-content-center">
                {pageDetails && pageDetails.length > 0
                  ? pageDetails.map((partner) => (
                      <div className="col-lg-3 col-md-4">
                        <div className="partner-box">
                          <a target="_blank" href={partner.link}>
                            <img src={partner.image} alt />
                          </a>
                        </div>
                      </div>
                    ))
                  : ""}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Body;
