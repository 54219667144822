import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { APIURL } from "../../Methods/Fetch";
import { axiosPost } from "../../Methods/Save";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

import {
  alphaBetsOnly,
  BlankValidation,
  emailOnly,
} from "../../Methods/ValidateForms";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { error } from "jquery";
import PasswordStrengthBar from "react-password-strength-bar";
import ReCAPTCHA from "react-google-recaptcha";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";

function RegisterBody({ setShowRegister }) {
  const navigate = useNavigate();
  const captchaRef = useRef(null);
  const [user, setUser] = useState([]);
  const nameRef = useRef();
  const [checked, setChecked] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [modal, setModal] = useState(false);
  const [pwScore, setpwScore] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [profile, setProfile] = useState();
  const [registerData, setRegisterData] = useState({
    name: "",
    date: "",
    userName: "",
    email: "",
    password: "",
    confirmpassword: "",
    phoneNumber: "",
  });
  const [errors, setErrors] = useState({
    name: "blank",
    date: "blank",
    userName: "blank",
    email: "blank",
    password: "blank",
    confirmpassword: "blank",
    phoneNumber: "blank",
  });
  const handleChange = (e) => {
    const { name, value, id, type } = e.target;

    setRegisterData({ ...registerData, [name]: value });

    if (value.length == 0) {
      setErrors({ ...errors, [name]: `${id} cannot be empty` });
    } else {
      setErrors({ ...errors, [name]: "" });
    }
  };
  const register = () => {
    if (registerData.password !== registerData.confirmpassword) {
      toast.error("Password and confirm password does'nt match", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return;
    }
    const id = toast.loading("Please wait...");

    var data = { ...registerData };
    var url = APIURL() + "register";
    axiosPost(url, data)
      .then((res) => {
        toast.update(id, {
          render: res.data.msg,
          type: "success",
          isLoading: false,
          autoClose: 3000,
          theme: "dark",
        });
        localStorage.setItem("userId", res.data.data.id);
        localStorage.setItem("userDetails", JSON.stringify(res.data.data));
        setTimeout(() => {
          navigate("/dashboard");
        }, 1000);
      })
      .catch((err) => {
        if (err.response.data.errors.email) {
          setErrors({ ...errors, email: err.response.data.errors.email[0] });
        }
        toast.update(id, {
          render: err.response.data.message,
          type: "error",
          isLoading: false,
          autoClose: 3000,
          theme: "dark",
        });
      });
  };
  const validate = () => {
    if (!checked)
      toast.error("Please agree to our terms!", {
        position: toast.POSITION.TOP_RIGHT,
        theme: "dark",
      });
    else {
      const result = BlankValidation(
        registerData,
        register,
        errors,
        setErrors,
        toast,
        pwScore
      );
      if (result == false)
        toast.error("Please fill all the mandatory fields!", {
          position: toast.POSITION.TOP_RIGHT,
          theme: "dark",
        });
    }
  };
  const toggle = () => {
    setModal(!modal);
  };
  console.log("registerData", registerData);
  useEffect(() => {
    if (registerData.date == null)
      setErrors({ ...errors, date: `Date cannot be empty` });
    else setErrors({ ...errors, date: `` });
  }, [registerData.date]);
  const responseFacebook = (response) => {
    console.log(response);
  };
  const Googlelogin = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
  });
  useEffect(() => {
    if (user) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          setProfile(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [user]);
  useEffect(() => {
    if (profile) {
      const id = toast.loading("Please wait...");
      var data = { ...profile };
      var url = APIURL() + "facebooklogin";
      axiosPost(url, data)
        .then((res) => {
          localStorage.setItem(
            "userDetails",
            JSON.stringify({ ...res.data.user_data, id: res.data.user_id })
          );
          localStorage.setItem("userId", JSON.stringify(res.data.user_id));
          toast.update(id, {
            render: res.data.message,
            type: "success",
            isLoading: false,
            autoClose: 3000,
            theme: "dark",
          });
          navigate("/dashboard");
        })
        .catch((err) => {
          var message = "";
          if (err.response.data.response == "false") {
            register();
          }
          if (err.response.data.password) {
            setErrors({ ...errors, password: err.response.data.password });
            message = err.response.data.password;
          }
          toast.update(id, {
            render: message,
            type: "error",
            isLoading: false,
            autoClose: 3000,
            theme: "dark",
          });
        });
    }
  }, [profile]);

  return (
    <>
      <ToastContainer />
      <main className="d-flex">
        <div className="main-container">
          <div className="row nm-row">
            <div className="col-lg-6 nm-bgi d-none d-lg-flex">
              <div className="overlay">
                <div className="hero-item">
                  <a href="index.html" aria-label="Nimoy">
                    <img src="images/logo-wh.png" alt="Logo" />
                  </a>
                </div>
                <div className="hero-item hero-item-1">
                  <h2>Some Title Goes Here</h2>
                  <h2>Some Title Goes Here</h2>
                </div>
              </div>
            </div>
            <div className="col-lg-6 nm-mb-1 nm-mb-md-1 nm-aic">
              <div className="card register_uma">
                <div className="card-content">
                  <div className="header">
                    <h4>Create an Account</h4>
                  </div>
                  <div className="form-group">
                    <div className="input-group nm-gp">
                      <span className="nm-gp-pp">
                        <i className="fal fa-user" />
                      </span>
                      <input
                        id="Name"
                        name="name"
                        className={
                          errors.name === "blank"
                            ? "form-control"
                            : errors.name == ""
                            ? "form-control is-valid"
                            : "form-control is-invalid"
                        }
                        type="text"
                        tabIndex={1}
                        placeholder="Enter Your Name"
                        required
                        value={registerData.name}
                        autoComplete="off"
                        onChange={(e) =>
                          alphaBetsOnly(
                            e,
                            setRegisterData,
                            registerData,
                            setErrors,
                            errors
                          )
                        }
                      />
                      <div class="invalid-feedback">{errors.name}</div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="input-group nm-gp date-pick">
                      <span className="nm-gp-pp">
                        <i className="fas fa-suitcase" />
                      </span>
                      {/* <input
                        className={
                          errors.date === "blank"
                            ? "form-control"
                            : errors.date == ""
                            ? "form-control is-valid"
                            : "form-control is-invalid"
                        }
                        ref={ref}
                        type="text"
                        id="Date"
                        name="date"
                        value={registerData.date}
                        onFocus={() => (ref.current.type = "date")}
                        onBlur={() => (ref.current.type = "text")}
                        onChange={(e) => handleChange(e)}
                        placeholder="Enter Your Date of birth"
                      />*/}
                      <DatePicker
                        selected={registerData.date}
                        className={
                          errors.date === "blank"
                            ? "form-control"
                            : errors.date == ""
                            ? "form-control is-valid"
                            : "form-control is-invalid"
                        }
                        placeholderText="Date of Birth"
                        id="Date"
                        name="date"
                        onChange={(date) => {
                          setRegisterData({ ...registerData, date: date });
                        }}
                      />
                      {/*<span style={{ cursor: "pointer !important" }}>
                        <i
                          style={{ color: "#cb52ce" }}
                          onClick={(e) => nameRef.current.click()}
                          className="fal fa-calendar-alt"
                        />
                    </span>*/}
                      <div
                        class={
                          errors.date.length > 0
                            ? "date_picker_feedback"
                            : "d-none"
                        }
                      >
                        <span>{errors.date}</span>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="input-group nm-gp">
                      <span className="nm-gp-pp">
                        <i className="fas fa-id-card" />
                      </span>
                      <input
                        id="Username"
                        name="userName"
                        className={
                          errors.userName === "blank"
                            ? "form-control"
                            : errors.userName == ""
                            ? "form-control is-valid"
                            : "form-control is-invalid"
                        }
                        type="text"
                        tabIndex={1}
                        placeholder="User Name"
                        required
                        value={registerData.userName}
                        autoComplete="off"
                        onChange={(e) => handleChange(e)}
                      />
                      <div class="invalid-feedback">{errors.userName}</div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="input-group nm-gp">
                      <span className="nm-gp-pp">
                        <i className="fas fa-envelope-open" />
                      </span>
                      <input
                        id="Email"
                        name="email"
                        className={
                          errors.email === "blank"
                            ? "form-control"
                            : errors.email == ""
                            ? "form-control is-valid"
                            : "form-control is-invalid"
                        }
                        type="email"
                        tabIndex={1}
                        placeholder="Your Email"
                        required
                        value={registerData.email}
                        autoComplete="off"
                        onChange={(e) =>
                          emailOnly(
                            e,
                            setRegisterData,
                            registerData,
                            setErrors,
                            errors
                          )
                        }
                      />
                      <div class="invalid-feedback">{errors.email}</div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="input-group nm-gp">
                      <span className="nm-gp-pp">
                        <i className="fas fa-lock" />
                      </span>
                      <input
                        id="Password"
                        name="password"
                        className={
                          errors.password === "blank"
                            ? "form-control"
                            : errors.password == ""
                            ? "form-control is-valid"
                            : "form-control is-invalid"
                        }
                        type={showPassword ? "text" : "password"}
                        tabIndex={2}
                        placeholder="Enter Your Password"
                        required
                        value={registerData.password}
                        autoComplete="off"
                        onChange={(e) => handleChange(e)}
                      />
                      {showPassword ? (
                        <VisibilityIcon
                          onClick={(e) => setShowPassword(false)}
                          style={{
                            position: "absolute",
                            right: "13px",
                            top: "11px",
                            cursor: "pointer",
                            zIndex: "99999",
                          }}
                        />
                      ) : (
                        <VisibilityOffIcon
                          onClick={(e) => setShowPassword(true)}
                          style={{
                            position: "absolute",
                            right: "13px",
                            top: "11px",
                            cursor: "pointer",
                            zIndex: "99999",
                          }}
                        />
                      )}{" "}
                      <div class="invalid-feedback">{errors.password}</div>
                    </div>
                    {registerData.password.length > 0 && (
                      <PasswordStrengthBar
                        password={registerData.password}
                        onChangeScore={(score, feedback) => {
                          setpwScore(score);
                        }}
                      />
                    )}
                  </div>
                  <div className="form-group">
                    <div className="input-group nm-gp">
                      <span className="nm-gp-pp">
                        <i className="fas fa-lock" />
                      </span>
                      <input
                        id="Confirm-Password"
                        name="confirmpassword"
                        className={
                          errors.confirmpassword === "blank"
                            ? "form-control"
                            : errors.confirmpassword == ""
                            ? "form-control is-valid"
                            : "form-control is-invalid"
                        }
                        type={showPassword1 ? "text" : "password"}
                        tabIndex={2}
                        placeholder="Confirm Your Password"
                        required
                        value={registerData.confirmpassword}
                        autoComplete="off"
                        onChange={(e) => handleChange(e)}
                      />
                      {showPassword1 ? (
                        <VisibilityIcon
                          onClick={(e) => setShowPassword1(false)}
                          style={{
                            position: "absolute",
                            right: "13px",
                            top: "11px",
                            cursor: "pointer",
                            zIndex: "99999",
                          }}
                        />
                      ) : (
                        <VisibilityOffIcon
                          onClick={(e) => setShowPassword1(true)}
                          style={{
                            position: "absolute",
                            right: "13px",
                            top: "11px",
                            cursor: "pointer",
                            zIndex: "99999",
                          }}
                        />
                      )}{" "}
                      <div class="invalid-feedback">
                        {errors.confirmpassword}
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="input-group nm-gp">
                      <span className="nm-gp-pp">
                        <i className="far fa-phone-alt" />
                      </span>
                      <input
                        className={
                          errors.phoneNumber === "blank"
                            ? "form-control"
                            : errors.phoneNumber == ""
                            ? "form-control is-valid"
                            : "form-control is-invalid"
                        }
                        name="phoneNumber"
                        id="Phone-Number"
                        type="number"
                        tabIndex={2}
                        value={registerData.phoneNumber}
                        placeholder="Enter Your Phone Number"
                        required
                        onWheel={(event) => event.currentTarget.blur()}
                        autoComplete="off"
                        onChange={(e) => handleChange(e)}
                      />
                      <div class="invalid-feedback">{errors.phoneNumber}</div>
                    </div>
                  </div>
                  <div className="d-flex nm-jcb nm-mb-1 nm-mt-1">
                    <div className="registerchk chkboxmain">
                      <input
                        id="Option5"
                        type="checkbox"
                        checked={checked}
                        onChange={(e) => setChecked(!checked)}
                      />
                      <label className="checkbox" htmlFor="Option5">
                        I accept the{" "}
                        <a
                          onClick={(e) => toggle()}
                          href="#"
                          className="forgotpw"
                        >
                          terms &amp; conditions
                        </a>
                      </label>
                    </div>
                  </div>

                  <button
                    type="submit"
                    className="btn btn-block btn-primary nm-btn"
                    onClick={validate}
                  >
                    Sign Up
                  </button>
                  <p className="divider">
                    Or continue with your social profile:
                  </p>
                  <div className="row social nm-mb-1">
                    <div className="col-md-4 mb-2 mb-md-0">
                      <FacebookLogin
                        appId="858916368519667"
                        // autoLoad
                        callback={responseFacebook}
                        render={(renderProps) => (
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={renderProps.onClick}
                            className="btn btn-block nm-btn btn-facebook"
                          >
                            <i className="fab fa-facebook-f nm-mr-1h" />
                            Facebook
                          </a>
                        )}
                      />
                    </div>
                    <div className="col-md-4">
                      <a
                        onClick={Googlelogin}
                        style={{ cursor: "pointer" }}
                        className="btn btn-block nm-btn btn-google"
                      >
                        <i className="fab fa-google nm-mr-1h" />
                        Google
                      </a>
                    </div>
                    <div className="col-md-4">
                      <a href="#" className="btn btn-block nm-btn btn-google">
                        <i className="fab fa-apple" />
                        Apple
                      </a>
                    </div>
                  </div>
                  <p className="text-center nm-ft-b nm-wh mb-0 noacnt">
                    Already a member?{" "}
                    <a
                      className="btn_anchor"
                      style={{ color: "#cb52ce" }}
                      onClick={() => setShowRegister(false)}
                    >
                      Login
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Modal isOpen={modal} toggle={toggle} backdrop="static">
        <ModalHeader>
          <h4 style={{ fontSize: "17px", margin: "0px" }}>
            Terms and Conditions
          </h4>
        </ModalHeader>
        <ModalBody>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum
          </p>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            class="btn btn-danger"
            onClick={(e) => toggle()}
          >
            Close
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default RegisterBody;
