import React from "react";
import { Link } from "react-router-dom";

function Body(props) {
  const { toggle } = props;
  return (
    <>
      {/* <div className="desktop-sec">
        <section className="banner-sec">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 mx-auto">
                <div className="top-img">
                  <div classname="videoplay-screen">
                    <video
                      id="myVideo"
                      onclick="{toggle}"
                      data-bs-toggle="modal"
                      data-bs-target="#myModal"
                      muted
                      autoPlay
                      loop
                      playsInline
                    >
                      <source src="images/sample-video.mp4" type="video/mp4" />
                      Your browser does not support HTML5 video.
                    </video>
                    <a
                      onclick="{toggle}"
                      href="#!"
                      data-bs-toggle="modal"
                      data-bs-target="#myModal"
                    >
                      <i classname="fas fa-expand"></i>
                    </a>
                    <i classname="fas fa-expand"></i>
                  </div>
                  <i classname="fas fa-expand">
                    <div className="scene-prevbtn">
                      <link to="/" />
                      <span>Back</span>
                    </div>
                    <div classname="scene-nextbtn">
                      <link to="/step-two" />
                      <span>Next</span>
                    </div>
                  </i>
                </div>
                <i classname="fas fa-expand"></i>
              </div>
              <i classname="fas fa-expand"></i>
            </div>
            <i classname="fas fa-expand"></i>
          </div>
          <i classname="fas fa-expand"></i>
        </section>
        <i classname="fas fa-expand"></i>
      </div> */}
      <div className="desktop-sec">
        <section className="banner-sec">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 mx-auto">
                <div className="top-img">
                  <div className="videoplay-screen">
                    <video
                      id="myVideo"
                      onClick={toggle}                 
                      muted
                      autoPlay
                      loop
                      playsInline
                    >
                      <source src="images/sample-video.mp4" type="video/mp4" />
                      Your browser does not support HTML5 video.
                    </video>
                    <a
                      href="#"              
                      onClick={toggle}
                    >
                      <i className="fas fa-expand" />
                    </a>
                  </div>
                  <div className="scene-prevbtn">
                    <Link to="/">
                      <span>Back</span>
                    </Link>
                  </div>
                  <div className="scene-nextbtn">
                    <Link to="/step-two">
                      <span>Next</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <i classname="fas fa-expand"></i>

      {/* <div className="desktop-sec">
        <section className="banner-sec">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 mx-auto">
                <div className="top-img">
                  <div className="step3-body">
                    <div className="step3-playerchoose video_heading">
                      <h2>How does NOPLAN work?</h2>
                    </div>
                  </div>

                  <div className="videoplay-screen">
                    <video
                      id="myVideo"
                      onClick={toggle}
                      data-bs-toggle="modal"
                      data-bs-target="#myModal"
                      muted
                      autoPlay
                      loop
                      playsInline
                    >
                      <source src="images/sample-video.mp4" type="video/mp4" />
                      Your browser does not support HTML5 video.
                    </video>
                    <a
                      onClick={toggle}
                      href="#!"
                      data-bs-toggle="modal"
                      data-bs-target="#myModal"
                    >
                      <i className="fas fa-expand" />
                    </a>
                  </div>
                  <div class="scene-prevbtn">
                    <Link to="/">
                      <span>Back</span>
                    </Link>
                  </div>
                  <div className="scene-nextbtn">
                    <Link to="/step-two">
                      <span>Next</span>
                    </Link>
                  </div>
                  <img src="images/animated-desktop1.gif" alt />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div> */}
      <div className="mobile-sec">
        <div className="mob-img-box step2">
          <div className="videoplay-screen">
            <video id="myVideo-mobile" muted autoPlay loop playsInline>
              <source src="images/sample-video.mp4" type="video/mp4" />
              Your browser does not support HTML5 video.
            </video>
            <a onClick={toggle} href="#">
              <i className="fas fa-expand" />
            </a>
          </div>
          <div class="scene-prevbtn">
            <Link to="/">
              <span>Back</span>
            </Link>
          </div>
          <div className="scene-nextbtn">
            <Link to="/step-two">
              <span>Next</span>
            </Link>
          </div>
          <img src="images/scene4.gif" alt />
        </div>
      </div>
    </>
  );
}

export default Body;
