import { React, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import Footer from "../../Common/Footer";
import Header from "../../Common/Header";
import Icons from "../../Common/Icons";
import Body from "../../Components/OrderProcess/StepSix/Body";

function StepSix(props) {
  const navigate=useNavigate();
  const { setBackGroundColor } = props;
  useEffect(() => {
    setBackGroundColor("step7-fullbg");
  }, []);
  useEffect(() => {
    var isSignedIn = false;
    if (localStorage.getItem("userId") != null) {
      isSignedIn = true;
    }
    if (isSignedIn) {
      navigate("/step-seven");
    }
  }, [])
  return (
    <>
      <Header />
      <Icons />
      <div className="box-margin">
        <Body />
      </div>
    </>
  );
}

export default StepSix;
