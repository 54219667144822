import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Calendar, utils } from "react-modern-calendar-datepicker-fix";
import { useNavigate } from "react-router-dom";
import { APIURL } from "../../../Methods/Fetch";
import { axiosGet, axiosPost } from "../../../Methods/Save";

function DateAndTime(props) {
  const navigate = useNavigate();

  const {
    selectedDay,
    setSelectedDay,
    selectedTime,
    setSelectedTime,
    setTime,
    setLoading,
    date,
    setDate
  } = props;
  const [bookingTimes, setBookingTimes] = useState([]);
  console.log(bookingTimes);
  const [maxDate, setMaxDate] = useState();
  const [availableDays, setAvailableDays] = useState([]);
  const [id, setId] = useState();
  const selectDate = (time, index) => {
    // setSelectedDate(date.aAppointmentDate.split("T")[0]);
    // setSelectedDateObject(date);
    setSelectedTime(time);
    setId(index);
  };
  const saveInLocal = () => {
    localStorage.setItem("time", selectedTime);
    localStorage.setItem(
      "date",
      `${selectedDay.year}-${selectedDay.month}-${selectedDay.day}`
    );
    localStorage.setItem("dateObj", JSON.stringify(selectedDay));
    navigate("/step-four");
  };
  
  useEffect(() => {
    if (localStorage.getItem("dateObj") != null) {
      setSelectedDay(JSON.parse(localStorage.getItem("dateObj")));
    }
    if (localStorage.getItem("time") != null) {
      setTime(localStorage.getItem("time"));
    }
  }, []);
  // useEffect(() => {
  //   var url = APIURL() + "event-date";
  //   axiosGet(url).then((response) => {
  //     var avail_days = [];
  //     var date = response.data[0].response.max_endDate.split("-");
  //     var all_date = response.data[0].response.data;
  //     setMaxDate({
  //       year: date[0],
  //       month: date[1],
  //       day: date[2],
  //     });
  //     console.log(all_date);
  //     for (const property in all_date) {
  //       console.log(`${property}: ${all_date[property].start_date}`);
  //       var dates = all_date[property].start_date.split("-");
  //       avail_days.push({
  //         year: Number(dates[0]),
  //         month: Number(dates[1]),
  //         day: Number(dates[2]),
  //         className: "navyBlueDay",
  //       });
  //     }
  //     setAvailableDays(avail_days);
  //   });
  // }, []);
  useEffect(() => {
    var url = APIURL() + "event-date";
    axiosGet(url).then((response) => {
      var avail_days = [];
      var date = response.data[0].response.max_endDate.split("-");
      var all_date = response.data[0].response.data;
      setMaxDate({
        year: date[0],
        month: date[1],
        day: date[2],
      });
      for (const property in all_date) {
        var dates = all_date[property].start_date.split("-");
        avail_days.push({
          year: Number(dates[0]),
          month: Number(dates[1]),
          day: Number(dates[2]),
          className: "navyBlueDay",
        });
      }
      setAvailableDays(avail_days);
    });
  }, []);

  useEffect(() => {
    setId();
    setSelectedTime();
    if (selectedDay) {
      setLoading(true);
      var date = `${selectedDay.year}-${selectedDay.month}-${selectedDay.day}`;
      setDate(date);
      var data = { start_date: date };
      var url = APIURL() + "event-time";
      axiosPost(url, data).then((res) => {
        setLoading(false);
        var times=new Set(res.data[0].response.data);
        setBookingTimes([...times]);
      });
    }
  }, [selectedDay]);
  return (
    <>
      <div className="sl-dateTime">Select date and time</div>

      <div className="k-flex k-display-flex">
        <Calendar
          className="col-6"
          value={selectedDay}
          onChange={setSelectedDay}
          shouldHighlightWeekends
          minimumDate={utils().getToday()}
          maximumDate={maxDate}
          customDaysClassName={availableDays}
        />

        <div className="standard-product">
          <div className="date-standard-box">
            {bookingTimes && bookingTimes.length > 0 ? (
              <div>
                <ul>
                  {bookingTimes.map((bt, index) => (
                    <li
                      key={index}
                      onClick={(e) => selectDate(bt, index)}
                      className={id == index ? "active" : ""}
                    >
                      {bt}
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              <h6>No time slots available</h6>
            )}
          </div>
          <div className="pro-new-btn">
            {date && selectedTime ? (
              <a onClick={saveInLocal} className="btn-style-two">
                Proceed
              </a>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default DateAndTime;
