import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { APIURL } from "../../Methods/Fetch";
import { axiosGet } from "../../Methods/Save";

function Body() {
  const [pageDetails, setPageDetails] = useState([]);
  useEffect(() => {
    var url = APIURL() + "home";
    axiosGet(url).then((response) => {
      setPageDetails(response.data[0].response.data);
    });
  }, []);
  return (
    <>
      <div className="booking-driver2">
        <div className="row">
          <div className="col-lg-6">
            <div className="row">
              <div className="col-lg-12 driver-form">
                <label>
                  Choose Your Charecter <span>*</span>
                </label>
                <select
                  className="form-select"
                  aria-label="Default select example"
                >
                  <option selected>David Josen </option>
                  <option value={1}>One</option>
                  <option value={2}>Two</option>
                  <option value={3}>Three</option>
                </select>
              </div>
              <div className="col-lg-12 driver-form">
                <label>
                  Choose Your Cloth <span>*</span>
                </label>
                <select
                  className="form-select"
                  aria-label="Default select example"
                >
                  <option selected>Select Cloth</option>
                  <option value={1}>One</option>
                  <option value={2}>Two</option>
                  <option value={3}>Three</option>
                </select>
              </div>
              <div className="col-lg-12 driver-form">
                <label>
                  Date <span>*</span>
                </label>
                <div className="date-j">
                  <input
                    type="text"
                    id="datepicker"
                    placeholder="choose your date"
                    className="form-control"
                  />
                  <i className="fal fa-calendar-alt" />
                </div>
              </div>
              <div className="col-lg-12">
                <div className="hashrag-booking">
                  <ul>
                    <li>
                      <a href="#">#Foodie</a>
                    </li>
                    <li>
                      <a href="#">#Party</a>
                    </li>
                    <li>
                      <a href="#">#DJ</a>
                    </li>
                    <li>
                      <a href="#">#Club</a>
                    </li>
                    <li>
                      <a href="#">#Dance</a>
                    </li>
                    <li>
                      <a href="#">#Social</a>
                    </li>
                    <li>
                      <a href="#">#Couple</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="char-img">
              <img src="/images/cartoon-characters7.png" alt="a" />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 text-center">
            <button type="button" className="btn-style-two">
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Body;
