import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { APIURL } from "../../../Methods/Fetch";
import { axiosPost } from "../../../Methods/Save";
import Paymentjs from "../../../Views/Paymentjs";

function PaymentModal(props) {
  const navigate = useNavigate();

  const { modal, toggle, price, bookingId, setRefresh, refresh, toast,eventsObj } = props;
  const [loading, setLoading] = useState(false);
  const [paymentCompleted, setPaymentCompleted] = useState(false);

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} backdrop="static">
        <ModalHeader>
          <div className="row">
            <div className="col-6">
              <h4 style={{ fontSize: "17px", margin: "0px" }}>
                Payment Details
              </h4>
            </div>
            <div className="col-6 text-right">
            
              <h4 style={{ fontSize: "17px", margin: "0px" }}>
              <i class="fas fa-shopping-cart me-2" style={{ color:"#830cb5" }}></i>
                {eventsObj.name} : £ {price}
              </h4>
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <Paymentjs
            price={price}
            bookingId={bookingId}
            setRefresh={setRefresh}
            refresh={refresh}
            setLoading={setLoading}
            loading={loading}
            paymentCompleted={paymentCompleted}
            setPaymentCompleted={setPaymentCompleted}
          />
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            class="btn btn-danger"
            onClick={(e) => {
              setRefresh(!refresh);
              if (paymentCompleted == true) {
                toast.success("Payment succesful!", {
                  position: toast.POSITION.TOP_RIGHT,
                  theme: "dark",
                });
                setTimeout(() => {
                  navigate("/thank-you");
                }, 2000);
              }

              toggle();
            }}
            disabled={loading ? true : false}
          >
            Close
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default PaymentModal;
