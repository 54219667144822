import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SyncLoader } from "react-spinners";
import { APIURL } from "../../Methods/Fetch";
import { axiosGet } from "../../Methods/Save";
import PaymentModal from "../OrderProcess/StepSeven/PaymentModal";
import { ToastContainer, toast } from "react-toastify";
import PaginationIcons from "../../Common/PaginationIcons";

function Body(props) {
  const navigate = useNavigate();
  const { userDetails } = props;

  const [bookings, setBookings] = useState([]);
  const [bookingId, setBookingId] = useState();
  const [price, setPrice] = useState();
  const [refresh, setRefresh] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(6);
  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const posts = bookings.slice(firstPostIndex, lastPostIndex);
  const [pageNo, setPageNo] = useState(1);
  const tableRef = useRef();

  useEffect(() => {
    setLoading(true);
    var url = APIURL() + `booking-lists/${userDetails.id}`;
    axiosGet(url).then((response) => {
      setBookings(response.data[0].response.data);
      setLoading(false);
    });
  }, [refresh]);
  const edit = (event) => {
    var dateobj = event.booking_date.split("-");
    localStorage.setItem(
      "dateObj",
      JSON.stringify({
        day: Number(dateobj[2]),
        month: Number(dateobj[1]),
        year: Number(dateobj[0]),
      })
    );
    localStorage.setItem("date", event.booking_date);
    localStorage.setItem("time", event.booking_time);
    localStorage.setItem(
      "eventsObj",
      JSON.stringify({ id: event.event_id, price: event.price })
    );
    localStorage.setItem(
      "hotelsObj",
      JSON.stringify({ id: event.resturant_id })
    );
    localStorage.setItem(
      "characterOne",
      JSON.stringify({ id: event.character_one.id })
    );
    localStorage.setItem(
      "characterTwo",
      JSON.stringify({ id: event.character_two.id })
    );
    localStorage.setItem("hotels", event.resturant_id);
    localStorage.setItem("players", event.players);
    localStorage.setItem("booking_id", event.id);
    localStorage.setItem(
      "characterOne",
      JSON.stringify({ ...event.character_one, image: event.character_one_img })
    );
    localStorage.setItem(
      "characterTwo",
      JSON.stringify({ ...event.character_two, image: event.character_two_img })
    );
    navigate("/step-three");
  };
  const [cardDetails, setCardDetails] = useState({
    cardholderName: "",
    cardNumber: "",
    expiryDate: "",
    securityCode: "",
  });
  const [errors, setErrors] = useState({
    cardholderName: "blank",
    cardNumber: "blank",
    expiryDate: "blank",
    securityCode: "blank",
  });
  const toggle = () => setModal(!modal);
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const paynow = (booking) => {
    setBookingId(booking.id);
    setPrice(booking.price);
    toggle();
  };
  return (
    <>
      <SyncLoader
        loading={loading}
        className="react-loader-new"
        color="#9a13d2"
      />
      <ToastContainer />
      <section className="banner-sec" ref={tableRef}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-9 mx-auto">
              <div className="driver-dashboard-page">
                <div className="cs-da-bo">
                  <div className="cs-title">
                    <h5>Welcome {userDetails.name}</h5>
                  </div>
                </div>
                <div className="cs-table">
                  {posts && posts.length > 0
                    ? posts.map((bookingDetail) => (
                        <div className="col-lg-12 mb-3">
                          <h4>Your Active Bookings</h4>
                          <div className="profile-confirm-page">
                            <div className="profile-cr">
                              <div className="profile-title-flex">
                                <div className="pro-img">
                                  <img src={userDetails.profile} alt />
                                </div>
                                <div className="pro-title">
                                  <div className="row">
                                    <div className="col-lg-6  col-md-6 col-md-6">
                                      <div className="pro_title_desc">
                                        <ul>
                                          {bookingDetail.driver &&
                                            bookingDetail.driver != "" && (
                                              <li>
                                                <h6>Driver Name: </h6>
                                                <span>
                                                  {bookingDetail.driver.name}
                                                </span>
                                              </li>
                                            )}

                                          {bookingDetail.driver &&
                                            bookingDetail.driver != "" && (
                                              <li>
                                                {" "}
                                                <h6>
                                                  Driver Contact No:{" "}
                                                  <span>
                                                    {bookingDetail.driver.phone}
                                                  </span>
                                                </h6>
                                              </li>
                                            )}

                                          <li>
                                            {" "}
                                            <h6>Time :</h6>
                                            <span>
                                              {bookingDetail.booking_time}
                                            </span>{" "}
                                          </li>
                                          <li>
                                            <h6>Player : </h6>
                                            <span>
                                              {bookingDetail.players}P
                                            </span>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                    <div className="col-lg-6  col-md-6 ">
                                      <div className="pro_title_desc">
                                        <ul>
                                          <li>
                                            <h6>Booking date :</h6>
                                            <span>
                                              {bookingDetail.booking_date}
                                            </span>
                                          </li>
                                          <li>
                                            {" "}
                                            <h6>Booking ID : </h6>
                                            <span>
                                              {bookingDetail.booking_no}
                                            </span>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="pickup-box raise-form">
                              <div className="pickup-date">
                                <div className="row">
                                  <div className="col-lg-12 col-md-10">
                                    <div className="row">
                                      {bookingDetail.players == 2 ? (
                                        <div className="col-lg-6  col-md-6 pb-3">
                                          <div className="row">
                                            <div className="col-lg-6  col-md-6 col-6">
                                              <img
                                                src={
                                                  bookingDetail.character_one_img
                                                }
                                                alt=""
                                              />
                                            </div>
                                            <div className="col-lg-6  col-md-6 col-6">
                                              <img
                                                src={
                                                  bookingDetail.character_two_img
                                                }
                                                alt=""
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="col-lg-6  col-md-6 d-flex justify-content-center">
                                          <img
                                            src={
                                              bookingDetail.character_one_img
                                            }
                                            alt=""
                                          />
                                        </div>
                                      )}
                                      <div className="col-lg-6  col-md-6 pro_title_desc">
                                        <ul>
                                          <li>
                                            <h6>Restaurant :</h6>
                                            <span>
                                              {bookingDetail.resturant}
                                            </span>
                                          </li>
                                          <li>
                                            <h6>Event :</h6>
                                            <span>
                                              {bookingDetail.event_name}
                                            </span>
                                          </li>
                                          <li>
                                            <h6>Price : </h6>
                                            <span>$ {bookingDetail.price}</span>
                                          </li>
                                          {bookingDetail.mode == 0 ? (
                                            <li>
                                              <h6>Pick-up Location : </h6>
                                              <span>
                                                N/A : Choose to reach on your
                                                own
                                              </span>
                                            </li>
                                          ) : (
                                            <li>
                                              <h6>Pick-up Location : </h6>
                                              <span>
                                                {bookingDetail.address}
                                              </span>
                                            </li>
                                          )}
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                  {bookingDetail &&
                                  bookingDetail.is_paid == 1 ? (
                                    <div className="col-lg-6">
                                      <a href="#" className="btn-accept">
                                        Paid
                                      </a>
                                    </div>
                                  ) : (
                                    <div className="col-lg-6  col-md-6 mb-2">
                                      <a
                                        onClick={(e) => paynow(bookingDetail)}
                                        className="btn-one"
                                      >
                                        Pay now
                                      </a>
                                    </div>
                                  )}
                                  {bookingDetail.status == "1" ? (
                                    <div className="col-lg-6  col-md-6 mb-2">
                                      <a
                                        onClick={(e) => edit(bookingDetail)}
                                        className="btn-reject"
                                      >
                                        Edit
                                      </a>
                                    </div>
                                  ) : (
                                    <div className="col-lg-6  col-md-6 mb-2">
                                      <a className="btn-one">Approved</a>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    : ""}
                  {bookings && bookings.length > postsPerPage ? (
                    <PaginationIcons
                      totalposts={bookings.length}
                      postsperpage={postsPerPage}
                      setCurrentPage={setCurrentPage}
                      setPage={setPageNo}
                      page={pageNo}
                      tableRef={tableRef}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <PaymentModal
        setCardDetails={setCardDetails}
        cardDetails={cardDetails}
        setErrors={setErrors}
        errors={errors}
        modal={modal}
        setModal={setModal}
        toggle={toggle}
        // character_id={character.id}
        price={Number(price)}
        bookingId={bookingId}
        setRefresh={setRefresh}
        refresh={refresh}
        toast={toast}
      />
    </>
  );
}

export default Body;
