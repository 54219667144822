import { Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { APIURL } from "../../Methods/Fetch";
import { axiosGet } from "../../Methods/Save";

function Body() {
  const [pageDetails, setPageDetails] = useState([]);
  useEffect(() => {
    var url = APIURL() + "aboutus";
    axiosGet(url).then((response) => {
      console.log(response);
      setPageDetails(response.data[0].response.data);
    });
  }, []);
  console.log(pageDetails);
  return (
    <>
      <div className="bodywork-sec">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="about-img mr-36">
              {pageDetails && pageDetails.image ? (
                <img src={pageDetails.image} alt="Image" />
              ) : (
                <Skeleton
                  className="fullwidth_skeleton"
                  sx={{ bgcolor: "#ffffff61" }}
                  variant="rectangular"
                  height={400}
                />
              )}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="about-content ml-36">
              <h2>{pageDetails.title}</h2>
              <div
                dangerouslySetInnerHTML={{ __html: pageDetails.description }}
              ></div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="about-content ml-36">
              <ul>
                <li>
                  <div className="icon">
                    <i className="fas fa-shield-check" />
                  </div>
                  <h3>{pageDetails.title_two}</h3>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: pageDetails.description_two,
                    }}
                  ></div>
                </li>
                {/*<li>
                                          <div class="icon">
                                              <i class="fas fa-university"></i>
                                          </div>
                                          <h3>Infrastructure Integration Technology</h3>
                                          <p>Integrate local and cloud resources, user traffic and cloud resources, user traffic
                                              and endpoints.</p>
                                      </li>*/}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Body;
