import { React, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { APIURL } from "../../Methods/Fetch";
import { axiosGet } from "../../Methods/Save";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { Skeleton } from "@mui/material";

function Body(props) {
  const navigate = useNavigate();
  const [pageDetails, setPageDetails] = useState([]);
  useEffect(() => {
    var url = APIURL() + "blog";
    axiosGet(url).then((response) => {
      setPageDetails(response.data[0].response.data);
    });
  }, []);
  const goTo = (blog) => {
    localStorage.setItem("blogId", blog.id);
    var para = blog.title.toLowerCase().split(" ").join("-");
    navigate(`/blog/${para}`);
  };
  return (
    <>
      <div className="bodywork-sec">
        <div className="row">
          {pageDetails && pageDetails.length > 0 ? (
            <ResponsiveMasonry
              columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
            >
              <Masonry className="my-masonry-grid">
                {pageDetails && pageDetails.length > 0
                  ? pageDetails.map((blog) => (
                      <div className="col-lg-12">
                        <a
                          className="btn_anchor"
                          onClick={(e) => goTo(blog)}
                        >
                          <div className="blog-box">
                            <div className="blog-title">
                              <h3>{blog.title}</h3>
                              <div className="img">
                                {blog.image ? (
                                  <img src={blog.image} alt="blogpic" />
                                ) : (
                                  ""
                                )}

                                <div className="tags">
                                  <Link to="/" className="gat">
                                    Trending
                                  </Link>
                                  <Link to="#" className="tag">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={16}
                                      height={10}
                                      viewBox="0 0 16 10"
                                    >
                                      <polygon
                                        fillRule="evenodd"
                                        points="104.988 9.2 109.488 9.2 109.488 13.7 107.76 11.972 103.062 16.688 100.074 13.7 95.574 18.2 94.512 17.138 100.074 11.594 103.062 14.582 106.716 10.928"
                                        transform="translate(-94 -9)"
                                      />
                                    </svg>
                                  </Link>
                                </div>
                              </div>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: blog.description,
                                }}
                              ></div>
                              <div className="nk-post-by">
                                <Link to="/">{blog.author}</Link> in {blog.date}
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    ))
                  : ""}
              </Masonry>
            </ResponsiveMasonry>
          ) : (
            <Skeleton
              className="fullwidth_skeleton"
              sx={{ bgcolor: "#ffffff61" }}
              variant="rectangular"
              height={400}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default Body;
