import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { APIURL } from "../../Methods/Fetch";
import { axiosGet } from "../../Methods/Save";
import SyncLoader from "react-spinners/SyncLoader";
import { Skeleton } from "@mui/material";

function Body() {
  const [pageDetails, setPageDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    var url = APIURL() + "become-driver";
    axiosGet(url).then((response) => {
      setPageDetails(response.data[0].response.data);
      setLoading(false);
    });
  }, []);
  console.log("pageDetails", pageDetails);
  return (
    <>
      <SyncLoader
        loading={loading}
        className="react-loader-new"
        color="#9a13d2"
      />
      <div className="booking-driver">
        <div className="row">
          <div className="col-lg-6 booking-driver-left">
            <div className="spin-star spin">
              <img src="images/star.png" alt />
            </div>
            <h2>{pageDetails.title}</h2>
            <div
              dangerouslySetInnerHTML={{ __html: pageDetails.description }}
            ></div>
            <div className="bookdriver-btm">
              <Link to="/become-a-driver2" className="btn-style-one small ">
                {pageDetails.btn_name}
              </Link>
              <a href="#">
                <i className="fal fa-play-circle" /> Watch video
              </a>
            </div>
          </div>
          <div className="col-lg-6">
            {pageDetails.image ? (
              <img src={pageDetails.image} alt />
            ) : (
              <Skeleton
                className="fullwidth_skeleton"
                sx={{ bgcolor: "#ffffff61" }}
                variant="rectangular"
                height={400}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Body;
