import logo from "./logo.svg";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter, Routes, Route, useParams } from "react-router-dom";
import ScrollToTop from "./Common/ScrollToTop";
import Homepage from "./Views/Homepage";
import { useEffect, useState } from "react";
import Booking from "./Views/Booking";
import WhatWeDo from "./Views/WhatWeDo";
import Career from "./Views/Career";
import Blog from "./Views/Blog";
import BlogDetails from "./Views/BlogDetails";
import Login from "./Views/Login";
import StepOne from "./Views/OrderProcess/StepOne";
import StepTwo from "./Views/OrderProcess/StepTwo";
import StepThree from "./Views/OrderProcess/StepThree";
import UserDashboard from "./Views/UserDashboard";
import ProtectedRoutes from "./Common/ProtectedRoutes";
import Register from "./Views/Register";
import StepFour from "./Views/OrderProcess/StepFour";
import StepFive from "./Views/OrderProcess/StepFive";
import StepSix from "./Views/OrderProcess/StepSix";
import StepSeven from "./Views/OrderProcess/StepSeven";
import Contact from "./Views/Contact";
import EditProfile from "./Views/EditProfile";
import Faq from "./Views/Faq";
import BecomeADriver from "./Views/BecomeADriver";
import BecomeADriver2 from "./Views/BecomeADriver2";
import ChangePassword from "./Views/ChangePassword";
import UserBookings from "./Views/UserBookings";
import Terms from "./Views/Terms";
import ForgotPassword from "./Views/ForgotPassword";
import DriverLogin from "./Views/DriverLogin";
import DriverDashboard from "./Views/DriverDashboard";
import DriverSettings from "./Views/DriverSettings";
import DriverRaiseTicket from "./Views/DriverRaiseTicket";
import DriverBookingHistory from "./Views/DriverBookingHistory";
import ChangePasswordDriver from "./Views/ChangePasswordDriver";
import MarketingIntern from "./Views/MarketingIntern";
import NotFound from "./Views/NotFound";
import Editor from "./Views/Editor";
import Privacy from "./Views/Privacy";
import AboutUs from "./Views/AboutUs";
import DriverCalendar from "./Views/DriverCalendar";
import Notifications from "./Views/Notifications";
import ProtectedRoutesDriver from "./Common/ProtectedRoutesDriver";
import DriverTickets from "./Views/DriverTickets";
import DriverTicketDetails from "./Views/DriverTicketDetails";
import DateAndTime from "./Components/OrderProcess/StepTwo/DateAndTime";
import OurPartners from "./Views/OurPartners";
import ThankYouPage from "./Views/OrderProcess/ThankYouPage";
import LandingPage from "./Views/LandingPage";

function App() {
  const [metaDesc, setMetaDesc] = useState("");
  const [metaKeyWords, setMetaKw] = useState("");
  useEffect(() => {
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", metaDesc ? metaDesc : "");
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute("content", metaKeyWords ? metaKeyWords : "");
  }, [metaDesc, metaKeyWords]);
  const [backgroundColor, setBackGroundColor] = useState("bg-texture-gradient");
  return (
    <div className={backgroundColor}>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route
            path="/"
            element={
              <LandingPage
                setMetaDesc={setMetaDesc}
                setMetaKw={setMetaKw}
                setBackGroundColor={setBackGroundColor}
              />
            }
          ></Route>
          <Route
            path="/get-started"
            element={
              <Homepage
                setMetaDesc={setMetaDesc}
                setMetaKw={setMetaKw}
                setBackGroundColor={setBackGroundColor}
              />
            }
          ></Route>
          <Route
            path="/booking"
            element={
              <Booking
                setMetaDesc={setMetaDesc}
                setMetaKw={setMetaKw}
                setBackGroundColor={setBackGroundColor}
              />
            }
          ></Route>
          <Route
            path="/contact-us"
            element={
              <Contact
                setMetaDesc={setMetaDesc}
                setMetaKw={setMetaKw}
                setBackGroundColor={setBackGroundColor}
              />
            }
          ></Route>
          <Route
            path="/what-we-do"
            element={
              <WhatWeDo
                setMetaDesc={setMetaDesc}
                setMetaKw={setMetaKw}
                setBackGroundColor={setBackGroundColor}
              />
            }
          ></Route>
          <Route
            path="/career"
            element={
              <Career
                setMetaDesc={setMetaDesc}
                setMetaKw={setMetaKw}
                setBackGroundColor={setBackGroundColor}
              />
            }
          ></Route>
          <Route
            path="/blogs"
            element={
              <Blog
                setMetaDesc={setMetaDesc}
                setMetaKw={setMetaKw}
                setBackGroundColor={setBackGroundColor}
              />
            }
          ></Route>
          <Route
            path="/our-partners"
            element={
              <OurPartners
                setMetaDesc={setMetaDesc}
                setMetaKw={setMetaKw}
                setBackGroundColor={setBackGroundColor}
              />
            }
          ></Route>
          <Route
            path="/login"
            element={
              <Login
                setMetaDesc={setMetaDesc}
                setMetaKw={setMetaKw}
                setBackGroundColor={setBackGroundColor}
              />
            }
          ></Route>
          <Route
            path="/driver-login"
            element={
              <DriverLogin
                setMetaDesc={setMetaDesc}
                setMetaKw={setMetaKw}
                setBackGroundColor={setBackGroundColor}
              />
            }
          ></Route>
          <Route
            path="/driver-dashboard"
            element={
              <ProtectedRoutesDriver>
                <DriverDashboard
                  setMetaDesc={setMetaDesc}
                  setMetaKw={setMetaKw}
                  setBackGroundColor={setBackGroundColor}
                />
              </ProtectedRoutesDriver>
            }
          ></Route>
          <Route
            path="/driver-settings"
            element={
              <ProtectedRoutesDriver>
                <DriverSettings
                  setMetaDesc={setMetaDesc}
                  setMetaKw={setMetaKw}
                  setBackGroundColor={setBackGroundColor}
                />
              </ProtectedRoutesDriver>
            }
          ></Route>
          <Route
            path="/driver-raise-ticket"
            element={
              <ProtectedRoutesDriver>
                <DriverRaiseTicket
                  setMetaDesc={setMetaDesc}
                  setMetaKw={setMetaKw}
                  setBackGroundColor={setBackGroundColor}
                />
              </ProtectedRoutesDriver>
            }
          ></Route>
          <Route
            path="/driver-history"
            element={
              <ProtectedRoutesDriver>
                <DriverBookingHistory
                  setMetaDesc={setMetaDesc}
                  setMetaKw={setMetaKw}
                  setBackGroundColor={setBackGroundColor}
                />
              </ProtectedRoutesDriver>
            }
          ></Route>
          <Route
            path="/driver-calendar"
            element={
              <ProtectedRoutesDriver>
                <DriverCalendar
                  setMetaDesc={setMetaDesc}
                  setMetaKw={setMetaKw}
                  setBackGroundColor={setBackGroundColor}
                />
              </ProtectedRoutesDriver>
            }
          ></Route>
          <Route
            path="/driver-tickets"
            element={
              <ProtectedRoutesDriver>
                <DriverTickets
                  setMetaDesc={setMetaDesc}
                  setMetaKw={setMetaKw}
                  setBackGroundColor={setBackGroundColor}
                />
              </ProtectedRoutesDriver>
            }
          ></Route>
          <Route
            path="/driver-ticket/:id"
            element={
              <ProtectedRoutesDriver>
                <DriverTicketDetails
                  setMetaDesc={setMetaDesc}
                  setMetaKw={setMetaKw}
                  setBackGroundColor={setBackGroundColor}
                />
              </ProtectedRoutesDriver>
            }
          ></Route>
          <Route
            path="/register"
            element={
              <Register
                setMetaDesc={setMetaDesc}
                setMetaKw={setMetaKw}
                setBackGroundColor={setBackGroundColor}
              />
            }
          ></Route>
          <Route
            path="/step-one"
            element={
              <StepOne
                setMetaDesc={setMetaDesc}
                setMetaKw={setMetaKw}
                setBackGroundColor={setBackGroundColor}
              />
            }
          ></Route>
          <Route
            path="/step-two"
            element={
              <StepTwo
                setMetaDesc={setMetaDesc}
                setMetaKw={setMetaKw}
                setBackGroundColor={setBackGroundColor}
              />
            }
          ></Route>
          <Route
            path="/step-three"
            element={
              <StepThree
                setMetaDesc={setMetaDesc}
                setMetaKw={setMetaKw}
                setBackGroundColor={setBackGroundColor}
              />
            }
          ></Route>
          <Route
            path="/step-four"
            element={
              <StepFour
                setMetaDesc={setMetaDesc}
                setMetaKw={setMetaKw}
                setBackGroundColor={setBackGroundColor}
              />
            }
          ></Route>
          <Route
            path="/step-five"
            element={
              <StepFive
                setMetaDesc={setMetaDesc}
                setMetaKw={setMetaKw}
                setBackGroundColor={setBackGroundColor}
              />
            }
          ></Route>
          <Route
            path="/step-six"
            element={
              <StepSix
                setMetaDesc={setMetaDesc}
                setMetaKw={setMetaKw}
                setBackGroundColor={setBackGroundColor}
              />
            }
          ></Route>
          <Route
            path="/step-seven"
            element={
              <StepSeven
                setMetaDesc={setMetaDesc}
                setMetaKw={setMetaKw}
                setBackGroundColor={setBackGroundColor}
              />
            }
          ></Route>
          <Route
            path="/thank-you"
            element={
              <ThankYouPage
                setMetaDesc={setMetaDesc}
                setMetaKw={setMetaKw}
                setBackGroundColor={setBackGroundColor}
              />
            }
          ></Route>

          <Route
            path="/dashboard"
            element={
              <ProtectedRoutes>
                <UserDashboard
                  setMetaDesc={setMetaDesc}
                  setMetaKw={setMetaKw}
                  setBackGroundColor={setBackGroundColor}
                />
              </ProtectedRoutes>
            }
          ></Route>
          <Route
            path="/edit-profile"
            element={
              <ProtectedRoutes>
                <EditProfile
                  setMetaDesc={setMetaDesc}
                  setMetaKw={setMetaKw}
                  setBackGroundColor={setBackGroundColor}
                />
              </ProtectedRoutes>
            }
          ></Route>
          <Route
            path="/change-password"
            element={
              <ProtectedRoutes>
                <ChangePassword
                  setMetaDesc={setMetaDesc}
                  setMetaKw={setMetaKw}
                  setBackGroundColor={setBackGroundColor}
                />
              </ProtectedRoutes>
            }
          ></Route>
          <Route
            path="/change-password-driver"
            element={
              <ProtectedRoutes>
                <ChangePasswordDriver
                  setMetaDesc={setMetaDesc}
                  setMetaKw={setMetaKw}
                  setBackGroundColor={setBackGroundColor}
                />
              </ProtectedRoutes>
            }
          ></Route>
          <Route
            path="/user-notifications"
            element={
              <ProtectedRoutes>
                <Notifications
                  setMetaDesc={setMetaDesc}
                  setMetaKw={setMetaKw}
                  setBackGroundColor={setBackGroundColor}
                />
              </ProtectedRoutes>
            }
          ></Route>
          <Route
            path="/my-bookings"
            element={
              <ProtectedRoutes>
                <UserBookings
                  setMetaDesc={setMetaDesc}
                  setMetaKw={setMetaKw}
                  setBackGroundColor={setBackGroundColor}
                />
              </ProtectedRoutes>
            }
          ></Route>
          <Route
            path="/faq"
            element={
              <Faq
                setMetaDesc={setMetaDesc}
                setMetaKw={setMetaKw}
                setBackGroundColor={setBackGroundColor}
              />
            }
          ></Route>
          <Route
            path="/forgot-password"
            element={
              <ForgotPassword
                setMetaDesc={setMetaDesc}
                setMetaKw={setMetaKw}
                setBackGroundColor={setBackGroundColor}
              />
            }
          ></Route>
          <Route
            path="/forgot-password-driver"
            element={
              <ForgotPassword
                setMetaDesc={setMetaDesc}
                setMetaKw={setMetaKw}
                setBackGroundColor={setBackGroundColor}
                driver={true}
              />
            }
          ></Route>
          <Route
            path="/terms"
            element={
              <Terms
                setMetaDesc={setMetaDesc}
                setMetaKw={setMetaKw}
                setBackGroundColor={setBackGroundColor}
              />
            }
          ></Route>
          <Route
            path="/about-us"
            element={
              <AboutUs
                setMetaDesc={setMetaDesc}
                setMetaKw={setMetaKw}
                setBackGroundColor={setBackGroundColor}
              />
            }
          ></Route>
          <Route
            path="/privacy"
            element={
              <Privacy
                setMetaDesc={setMetaDesc}
                setMetaKw={setMetaKw}
                setBackGroundColor={setBackGroundColor}
              />
            }
          ></Route>
          <Route
            path="/test"
            element={
              <DateAndTime
                setMetaDesc={setMetaDesc}
                setMetaKw={setMetaKw}
                setBackGroundColor={setBackGroundColor}
              />
            }
          ></Route>
          <Route
            path="/marketing-intern"
            element={
              <MarketingIntern
                setMetaDesc={setMetaDesc}
                setMetaKw={setMetaKw}
                setBackGroundColor={setBackGroundColor}
              />
            }
          ></Route>
          <Route
            path="/editor"
            element={
              <Editor
                setMetaDesc={setMetaDesc}
                setMetaKw={setMetaKw}
                setBackGroundColor={setBackGroundColor}
              />
            }
          ></Route>
          <Route
            path="/become-a-driver"
            element={
              <BecomeADriver
                setMetaDesc={setMetaDesc}
                setMetaKw={setMetaKw}
                setBackGroundColor={setBackGroundColor}
              />
            }
          ></Route>
          <Route
            path="/become-a-driver2"
            element={
              <BecomeADriver2
                setMetaDesc={setMetaDesc}
                setMetaKw={setMetaKw}
                setBackGroundColor={setBackGroundColor}
              />
            }
          ></Route>
          <Route
            path="/blog/:title"
            element={
              <BlogDetails
                setMetaDesc={setMetaDesc}
                setMetaKw={setMetaKw}
                setBackGroundColor={setBackGroundColor}
              />
            }
          ></Route>
          <Route
            path="*"
            element={
              <NotFound
                setMetaDesc={setMetaDesc}
                setMetaKw={setMetaKw}
                setBackGroundColor={setBackGroundColor}
              />
            }
          ></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
