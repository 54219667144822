import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";
import { APIURL } from "../Methods/Fetch";
import { axiosPost } from "../Methods/Save";

function Paymentjs(props) {
  const {
    price,
    bookingId,
    setRefresh,
    refresh,
    loading,
    setLoading,
    paymentCompleted,
    setPaymentCompleted,
  } = props;
  const stripePromise = loadStripe(
    "pk_test_51L5D4ZDmx4oxEHJfb06aITFzoz1WnPZqIEs4vY9CDJIji2oz9XL9N0ZzQG5qSBf8TtT49GeECtgNRDYAE54WXXpa00Svy9K24I"
  );
  const successMessage = () => {
    return (
      <div className="success-msg">
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 16 16"
          className="bi bi-check2"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"
          />
        </svg>
        <div className="title">Payment Successful</div>
      </div>
    );
  };
  useEffect(() => {
    if (paymentCompleted == true) {
      var data = {
        booking_id: bookingId,
        is_paid: 1,
      };
      var url = APIURL() + "booking-store";
      axiosPost(url, data).then((response) => {});
    }
  }, [paymentCompleted]);
  return (
    <>
      <div className="row s-box">
        {paymentCompleted ? (
          successMessage()
        ) : (
          <React.Fragment>
            <div className="col-md-12 order-md-1">
              <Elements stripe={stripePromise}>
                <CheckoutForm
                  amount={price}
                  setPaymentCompleted={setPaymentCompleted}
                  loading={loading}
                  setLoading={setLoading}
                />
              </Elements>
            </div>
          </React.Fragment>
        )}
      </div>
    </>
  );
}

export default Paymentjs;
