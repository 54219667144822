import React from "react";
import { Navigate } from "react-router-dom";

function ProtectedRoutes(props) {
  var isSignedIn = false;
  if (localStorage.getItem("userId") != null) {
    isSignedIn = true;
  }

  const { children } = props;
  if (!isSignedIn) {
    return <Navigate to="/login" replace />;
  }
  return children;
}

export default ProtectedRoutes;
