import React, { useEffect, useState } from "react";
import { APIURL } from "../../Methods/Fetch";
import { axiosGet, axiosPost } from "../../Methods/Save";
import Menu from "../DriverDashboard/Menu";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { BlankValidation } from "../../Methods/ValidateForms";
import { ToastContainer, toast } from "react-toastify";
import Dropzone from "react-dropzone";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link } from "react-router-dom";

function Body(props) {
  const { driverDetails } = props;
  const [selectedBooking, setSelectedBooking] = useState();
  const [bookingDetails, setbookingDetails] = useState([]);
  const [uploadedImages, setUploadedImages] = useState([]);
  useEffect(() => {
    var url = APIURL() + `driver-bookings/${driverDetails.id}`;
    axiosGet(url).then((response) => {
      setbookingDetails(response.data[0].response.data);
    });
  }, []);
  const [ticketDetails, setTicketDetails] = useState({
    booking_id: "",
    title: "",
    priority: "",
    description: "",
  });
  const [errors, setErrors] = useState({
    booking_id: "blank",
    title: "blank",
    priority: "blank",
    description: "blank",
  });
  const handleChange = (e) => {
    const { name, value, id } = e.target;
    setTicketDetails({ ...ticketDetails, [name]: value });
    if (value.length == 0) {
      setErrors({ ...errors, [name]: `${id} cannot be empty` });
    } else {
      setErrors({ ...errors, [name]: "" });
    }
    if (name == "booking_id") {
      var booking = bookingDetails.filter((bookingDetail) => {
        return bookingDetail.booking_id == value;
      });
      if (value != "") setSelectedBooking(booking[0]);
      else setSelectedBooking();
    }
  };
  const validate = () => {
    const result = BlankValidation(
      ticketDetails,
      raiseTicket,
      errors,
      setErrors
    );
    if (result == false)
      toast.error("Please fill all the mandatory fields!", {
        position: toast.POSITION.TOP_RIGHT,
        theme: "dark",
      });
  };
  const raiseTicket = () => {
    const id = toast.loading("Please wait...");
    var data = { ...ticketDetails, user_id: driverDetails.id };
    var url = APIURL() + "ticket-store";
    axiosPost(url, data, "", "", uploadedImages)
      .then((res) => {
        toast.update(id, {
          render: res.data[0].response.msg,
          type: "success",
          isLoading: false,
          autoClose: 3000,
          theme: "dark",
        });
        setUploadedImages([]);
        setTicketDetails({
          booking_id: "",
          title: "",
          priority: "",
          description: "",
        });
        // localStorage.setItem("userId", res.data.data.id);
        // localStorage.setItem("userDetails", JSON.stringify(res.data.data));
        // setTimeout(() => {
        //   navigate("/dashboard");
        // }, 1000);
      })
      .catch((err) => {
        if (err.response.data.errors.email) {
          setErrors({ ...errors, email: err.response.data.errors.email[0] });
        }
        toast.update(id, {
          render: err.response.data.message,
          type: "error",
          isLoading: false,
          autoClose: 3000,
          theme: "dark",
        });
      });
  };
  const getImageFromUpload = (data) => {
    return URL.createObjectURL(data);
  };
  const handleImageRemove = (data) => {
    const filteredPeople = uploadedImages.filter(
      (item) => item.name !== data.name
    );
    setUploadedImages(filteredPeople);
  };
  return (
    <>
      <ToastContainer />
      <section className="banner-sec">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 mx-auto">
              <div className="driver-dashboard-page">
                <Menu driverDetails={driverDetails} />
                <div>
                  <div className="desktop-sec">
                    <section className="banner-sec">
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-lg-12 mx-auto">
                            <div className="driver-dashboard-page">
                              <div className="black-box">
                                <div className="raise-ticket">
                                  <div className="raise-title">
                                    <p>Raise a Ticket</p>
                                  </div>
                                  <div className="raise-btn-box">
                                    <Link
                                      to="/driver-tickets"
                                      className="btn-four"
                                    >
                                      View Tickets
                                    </Link>
                                  </div>
                                </div>
                                <div className="spacer1px" />

                                <div className="raise-form">
                                  {selectedBooking ? (
                                    <div
                                      className="card mb-3"
                                      style={{ width: "18rem" }}
                                    >
                                      <div className="card-body">
                                        <h5 className="card-title">
                                          Booking Details
                                        </h5>
                                        <p className="card-text">
                                          {selectedBooking.customer_name}
                                        </p>
                                      </div>
                                      <ul className="list-group list-group-flush">
                                        <li className="list-group-item">
                                          Date : {selectedBooking.booking_date}
                                        </li>
                                        <li className="list-group-item">
                                          Status : {selectedBooking.booking_status}
                                        </li>
                                        <li className="list-group-item">
                                          Phone Number :{" "}
                                          {selectedBooking.customer_phone}
                                        </li>
                                      </ul>
                                    </div>
                                  ) : (
                                    ""
                                  )}

                                  <div className="row">
                                    <div className="col-lg-6">
                                      <div className="mb-3">
                                        <label htmlFor>Booking ID</label>
                                        <select
                                          name="booking_id"
                                          id="Booking Id"
                                          className={
                                            errors.booking_id === "blank"
                                              ? "form-control"
                                              : errors.booking_id == ""
                                              ? "form-control is-valid"
                                              : "form-control is-invalid"
                                          }
                                          tabIndex={1}
                                          value={ticketDetails.booking_id}
                                          onChange={(e) => handleChange(e)}
                                        >
                                          <option value="" selected>
                                            Select a booking id
                                          </option>
                                          {bookingDetails &&
                                            bookingDetails.length > 0 &&
                                            bookingDetails.map(
                                              (bookingDetail) => (
                                                <option
                                                  value={
                                                    bookingDetail.booking_id
                                                  }
                                                >
                                                  {bookingDetail.booking_id}
                                                </option>
                                              )
                                            )}
                                        </select>
                                        <div class="invalid-feedback">
                                          {errors.booking_id}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-lg-6">
                                      <div className="mb-3">
                                        <label htmlFor>Title</label>
                                        <input
                                          type="text"
                                          className={
                                            errors.title === "blank"
                                              ? "form-control"
                                              : errors.title == ""
                                              ? "form-control is-valid"
                                              : "form-control is-invalid"
                                          }
                                          tabIndex={1}
                                          placeholder="Title"
                                          name="title"
                                          value={ticketDetails.title}
                                          onChange={(e) => handleChange(e)}
                                        />
                                        <div class="invalid-feedback">
                                          {errors.title}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-lg-12">
                                      <div className="mb-3">
                                        <label htmlFor>Priority</label>
                                        <select
                                          name="priority"
                                          id="Priority"
                                          className={
                                            errors.priority === "blank"
                                              ? "form-control"
                                              : errors.priority == ""
                                              ? "form-control is-valid"
                                              : "form-control is-invalid"
                                          }
                                          tabIndex={1}
                                          value={ticketDetails.priority}
                                          onChange={(e) => handleChange(e)}
                                        >
                                          <option value="" selected>
                                            Select a Priority
                                          </option>
                                          <option value="1">High</option>
                                          <option value="2">Medium</option>
                                          <option value="3">Low</option>
                                        </select>
                                        <div class="invalid-feedback">
                                          {errors.priority}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-lg-12">
                                      <div className="mb-3">
                                        <label htmlFor>Description</label>
                                        <CKEditor
                                          editor={ClassicEditor}
                                          data=""
                                          onReady={(editor) => {
                                            // You can store the "editor" and use when it is needed.
                                            console.log(
                                              "Editor is ready to use!",
                                              editor
                                            );
                                          }}
                                          onChange={(event, editor) => {
                                            const data = editor.getData();
                                            console.log({
                                              event,
                                              editor,
                                              data,
                                            });
                                            setTicketDetails({
                                              ...ticketDetails,
                                              description: data,
                                            });
                                          }}
                                          onBlur={(event, editor) => {
                                            console.log("Blur.", editor);
                                          }}
                                          onFocus={(event, editor) => {
                                            console.log("Focus.", editor);
                                          }}
                                        />
                                        <div class="invalid-feedback">
                                          {errors.description}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-lg-12 mb-5">
                                      <div
                                        className="tab-pane active react-dropzone"
                                        id="home"
                                        role="tabpanel"
                                      >
                                        <h5>Add attachments</h5>
                                        <Dropzone
                                          accept="image/*"
                                          onDrop={(acceptedFiles) => {
                                            acceptedFiles.map((res) => {
                                              if (
                                                res.type == "image/jpeg" ||
                                                res.type == "image/jpg" ||
                                                res.type == "image/png"
                                              ) {
                                                setUploadedImages(
                                                  (oldArray) => [
                                                    ...oldArray,
                                                    res,
                                                  ]
                                                );
                                              } else {
                                              }
                                            });
                                          }}
                                        >
                                          {({
                                            getRootProps,
                                            getInputProps,
                                            isDragActive,
                                          }) => (
                                            <div
                                              {...getRootProps({
                                                className: "dropzone",
                                              })}
                                            >
                                              <input {...getInputProps()} />
                                              {isDragActive ? (
                                                <p>Drop the files here ...</p>
                                              ) : (
                                                <p>
                                                  Drag 'n' drop some files here,
                                                  or click to select files
                                                </p>
                                              )}
                                            </div>
                                          )}
                                        </Dropzone>
                                        {uploadedImages.length > 0 && (
                                          <React.Fragment>
                                            <h4 style={{ marginTop: "20px" }}>
                                              Uploaded Files :
                                            </h4>
                                            <div className="row">
                                              {uploadedImages.map((res) => (
                                                <div className="col-lg-2 col-md-2">
                                                  <img
                                                    style={{
                                                      height: "100px",
                                                      width: "100%",
                                                    }}
                                                    src={getImageFromUpload(
                                                      res
                                                    )}
                                                    alt="img"
                                                  />
                                                  <DeleteIcon
                                                    onClick={() =>
                                                      handleImageRemove(res)
                                                    }
                                                    style={{
                                                      marginTop: "-124%",
                                                      marginLeft: "86%",
                                                      background: "#fff",
                                                      color: "red",
                                                      cursor: "pointer",
                                                    }}
                                                  />
                                                </div>
                                              ))}
                                            </div>
                                          </React.Fragment>
                                        )}
                                      </div>
                                    </div>
                                    <a
                                      onClick={validate}
                                      href="#"
                                      className="btn-one"
                                    >
                                      Submit
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                  <div className="mobile-sec ">
                    <div className="mob-img-box ">
                      <div className="driver-dashboard-page">
                        <div className="black-box">
                          <div className="raise-ticket">
                            <div className="raise-title">
                              <p>Raise a Ticket</p>
                            </div>
                            <div className="raise-btn-box">
                              <Link to="/driver-tickets" className="btn-four">
                                View Tickets
                              </Link>
                            </div>
                          </div>
                          <div className="spacer1px" />
                          <div className="raise-form">
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <label htmlFor>Order No</label>
                                  <select name id className="form-select">
                                    <option value selected>
                                      123456789
                                    </option>
                                    <option value>2547892</option>
                                    <option value>554862</option>
                                    <option value>55789631</option>
                                    <option value>458789632</option>
                                    <option value>14587236</option>
                                    <option value>88965741</option>
                                    <option value>66952347</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <label htmlFor>Title</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Lorem Epsum"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-12">
                                <div className="mb-3">
                                  <label htmlFor>Priority</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Lorem Epsum"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-12">
                                <div className="mb-3">
                                  <label htmlFor>Description</label>
                                  <div id="app2"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Body;
