import { React, useEffect } from "react";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import Icons from "../Common/Icons";
import Body from "../Components/Blog/Body";

function Blog(props) {
  const { setBackGroundColor } = props;
  useEffect(() => {
    setBackGroundColor("bg-texture-gradient gradient2");
  }, []);
  return (
    <>
      <Header />
      <Icons />
      <div className="box-margin bgcolor7">
        <Body />
        {/* <Footer className="border-top" /> */}
      </div>
    </>
  );
}

export default Blog;
