import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { APIURL } from "../../../Methods/Fetch";
import { axiosPost } from "../../../Methods/Save";
import SyncLoader from "react-spinners/SyncLoader";
import { ToastContainer, toast } from "react-toastify";

function Body() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [hotels, setHotels] = useState();
  const [selectedEvents, setSelectedEvents] = useState(
    localStorage.getItem("events")
  );
  const [eventsObj, setEventsObj] = useState(
    JSON.parse(localStorage.getItem("eventsObj"))
  );
  const [selectedHotels, setSelectedHotels] = useState(
    JSON.parse(localStorage.getItem("hotelsObj"))
  );
  const saveInLocal = () => {
    if (Object.keys(selectedHotels).length > 0) {
      localStorage.setItem("hotels", selectedHotels.resturant_name);
      localStorage.setItem("hotelsObj", JSON.stringify(selectedHotels));
      navigate("/step-six");
    } else {
      toast.error("Please select a hotel !", {
        position: toast.POSITION.TOP_RIGHT,
        theme: "dark",
      });
    }
  };
  useEffect(() => {
    setLoading(true);
    var url = APIURL() + "restaurants";
    var data = {
      event: eventsObj.id,
    };
    axiosPost(url, data).then((response) => {
      setLoading(false);
      setHotels(response.data[0].response.data);
    });
  }, [selectedEvents]);
  return (
    <>
      <ToastContainer />
      <SyncLoader
        loading={loading}
        className="react-loader-new"
        color="#9a13d2"
      />
      <div className="desktop-sec">
        <section className="banner-sec">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 mx-auto">
                <div className="top-img top-img-uma">
                  <div className="step-hashtag hashtag_res">
                    <ul>
                      {hotels && hotels.length > 0
                        ? hotels.map((hotel) => (
                            <li
                              className={
                                selectedHotels.id == hotel.id ? "selected" : ""
                              }
                            >
                              <a
                                onClick={(e) => setSelectedHotels(hotel)}
                                href="#!"
                              >
                                {hotel.resturant_name}
                              </a>
                            </li>
                          ))
                        : ""}
                    </ul>
                    {/* -------------- */}
                    <div className="step4-playerchoose">
                      <p>
                        Choose the core aspect or keyword that captures the
                        essence of your restaurant.
                      </p>
                    </div>

                    {/* ----------------- */}
                  </div>
                  <div class="scene-prevbtn">
                    <Link to="/step-four">
                      <span>Back</span>
                    </Link>
                  </div>
                  <div className="scene-nextbtn">
                    <a style={{ cursor: "pointer" }} onClick={saveInLocal}>
                      <span>Next</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="mobile-sec ">
        <div className="mob-img-box step4">
          <div className="step-hashtag hashtag_res">
            <ul>
              {hotels && hotels.length > 0
                ? hotels.map((hotel) => (
                    <li
                      className={
                        selectedHotels.id == hotel.id ? "selected" : ""
                      }
                    >
                      <a onClick={(e) => setSelectedHotels(hotel)} href="#!">
                        {hotel.resturant_name}
                      </a>
                    </li>
                  ))
                : ""}
            </ul>
          </div>
          <div class="scene-prevbtn">
            <Link to="/step-four">
              <span>Back</span>
            </Link>
          </div>
          <div className="scene-nextbtn">
            <a style={{ cursor: "pointer" }} onClick={saveInLocal}>
              <span>Next</span>
            </a>
          </div>
          <img src="images/new6.gif" alt />
        </div>
      </div>
    </>
  );
}

export default Body;
