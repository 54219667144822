import React, { useState } from "react";
import { BlankValidation } from "../../../Methods/ValidateForms";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { APIURL } from "../../../Methods/Fetch";
import { axiosPost } from "../../../Methods/Save";
import LoginModal from "./LoginModal";

function Body() {
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [login, setLogin] = useState(false);
  const [userDetails, setUserDetails] = useState(
    JSON.parse(localStorage.getItem("customerRegistration"))
  );
  const [errors, setErrors] = useState({
    name: "blank",
    phone: "blank",
    email: "blank",
  });
  const handleChange = (e) => {
    const { name, value, id } = e.target;
    setUserDetails({ ...userDetails, [name]: value });
    if (value.length == 0) {
      setErrors({ ...errors, [name]: `${id} cannot be empty` });
    } else {
      setErrors({ ...errors, [name]: "" });
    }
  };
  const saveInLocal = () => {
    const id = toast.loading("Please wait...");
    var data = { ...userDetails, phoneNumber: userDetails.phone };
    var url = APIURL() + "register";
    axiosPost(url, data)
      .then((res) => {
        console.log();
        toast.update(id, {
          render: res.data.msg,
          type: "success",
          isLoading: false,
          autoClose: 3000,
          theme: "dark",
        });
        localStorage.setItem("userId", res.data.data.id);
        localStorage.setItem("userDetails", JSON.stringify(res.data.data));
        localStorage.setItem(
          "customerRegistration",
          JSON.stringify(userDetails)
        );
        setTimeout(() => {
          navigate("/step-seven");
        }, 1000);
      })
      .catch((err) => {
        if (err.response.data.errors.email) {
          toggle();
          setErrors({ ...errors, email: err.response.data.errors.email[0] });
        }
        toast.update(id, {
          render: "The email has already been taken.Please login to continue.",
          type: "error",
          isLoading: false,
          autoClose: 3000,
          theme: "dark",
        });
      });
  };
  const validate = () => {
    const result = BlankValidation(userDetails, saveInLocal, errors, setErrors);
    if (result == false)
      toast.error("Please fill all the mandatory fields!", {
        position: toast.POSITION.TOP_RIGHT,
        theme: "dark",
      });
  };
  const toggle = () => setModal(!modal);
  return (
    <>
      <ToastContainer />
      <LoginModal
        setLogin={setLogin}
        setModal={setModal}
        modal={modal}
        toggle={toggle}
      />
      <div className="desktop-sec">
        <section className="banner-sec">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 mx-auto">
                <div className="top-img">
                  <div className="screen7-formsection">
                    <div className="row">
                      <div className="col-lg-12">
                        <h3>Customer Registration </h3>
                        <div className="row">
                          <div className="col-lg-12 registration-form mb-3">
                            <input
                              type="text"
                              className={
                                errors.name === "blank"
                                  ? "form-control"
                                  : errors.name == ""
                                  ? "form-control is-valid"
                                  : "form-control is-invalid"
                              }
                              placeholder="Enter Your Name"
                              name="name"
                              id="Name"
                              value={userDetails.name}
                              onChange={(e) => handleChange(e)}
                            />
                            <div class="invalid-feedback">{errors.name}</div>
                          </div>
                          <div className="col-lg-12 registration-form mb-3">
                            <input
                              type="number"
                              onWheel={(event) => event.currentTarget.blur()}
                              className={
                                errors.phone === "blank"
                                  ? "form-control"
                                  : errors.phone == ""
                                  ? "form-control is-valid"
                                  : "form-control is-invalid"
                              }
                              name="phone"
                              id="Phone Number"
                              value={userDetails.phone}
                              onChange={(e) => handleChange(e)}
                              placeholder="Enter Your Phone Number"
                            />
                            <div class="invalid-feedback">{errors.phone}</div>
                          </div>
                          <div className="col-lg-12 registration-form mb-3">
                            <input
                              type="email"
                              className={
                                errors.email === "blank"
                                  ? "form-control"
                                  : errors.email == ""
                                  ? "form-control is-valid"
                                  : "form-control is-invalid"
                              }
                              name="email"
                              id="E-Mail"
                              value={userDetails.email}
                              onChange={(e) => handleChange(e)}
                              placeholder="Ener Your Email"
                            />
                            <div class="invalid-feedback">{errors.email}</div>
                          </div>
                          <div className="col-lg-12 text-center">
                            <button
                              onClick={(e) => validate()}
                              type="button"
                              className="btn-style-two"
                            >
                              Submit
                            </button>
                            <p class="register">Already registered? <a onClick={toggle} href="#!">Login</a></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="mobile-sec ">
        <div className="mob-img-box ">
          <div className="screen7-formsection">
            <div className="row">
              <div className="col-lg-12">
                <h3>Customer Registration </h3>
                <div className="row">
                  <div className="col-lg-12 registration-form">
                    <input
                      type="text"
                      className={
                        errors.name === "blank"
                          ? "form-control"
                          : errors.name == ""
                          ? "form-control is-valid"
                          : "form-control is-invalid"
                      }
                      placeholder="Enter Your Name"
                      name="name"
                      id="Name"
                      value={userDetails.name}
                      onChange={(e) => handleChange(e)}
                    />
                    <div class="invalid-feedback">{errors.name}</div>
                  </div>
                  <div className="col-lg-12 registration-form">
                    <input
                      type="text"
                      className={
                        errors.phone === "blank"
                          ? "form-control"
                          : errors.phone == ""
                          ? "form-control is-valid"
                          : "form-control is-invalid"
                      }
                      name="phone"
                      id="Phone Number"
                      value={userDetails.phone}
                      onChange={(e) => handleChange(e)}
                      placeholder="Enter Your Phone Number"
                    />
                    <div class="invalid-feedback">{errors.phone}</div>
                  </div>
                  <div className="col-lg-12 registration-form">
                    <input
                      type="email"
                      className={
                        errors.email === "blank"
                          ? "form-control"
                          : errors.email == ""
                          ? "form-control is-valid"
                          : "form-control is-invalid"
                      }
                      name="email"
                      id="E-Mail"
                      value={userDetails.email}
                      onChange={(e) => handleChange(e)}
                      placeholder="Ener Your Email"
                    />
                    <div class="invalid-feedback">{errors.email}</div>
                  </div>
                  <div className="col-lg-12 text-center">
                    <button
                      onClick={(e) => validate()}
                      type="button"
                      className="btn-style-two"
                    >
                      Submit
                    </button>
                    <p class="text-center nm-ft-b nm-wh mb-0 noacnt">Already registered? <a href="/login">Login</a></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img src="images/scene7.gif" alt />
        </div>
      </div>
    </>
  );
}

export default Body;
