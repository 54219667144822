// import { Calendar } from "@progress/kendo-react-dateinputs";
import { Calendar, utils } from "react-modern-calendar-datepicker-fix";
import { useEffect, useState } from "react";
import "@progress/kendo-theme-default/dist/all.css";
import { APIURL } from "../../../Methods/Fetch";
import { axiosGet, axiosPost } from "../../../Methods/Save";
import { SyncLoader } from "react-spinners";


function DateAndTime() {
  const [selectedDay, setSelectedDay] = useState();
  const [date, setDate] = useState();
  const [selectedTime, setSelectedTime] = useState();
  const [bookingTimes, setBookingTimes] = useState([]);

 

  useEffect(() => {
    setId();
    setSelectedTime();
    if (selectedDay) {
      setLoading(true);
      var date = `${selectedDay.year}-${selectedDay.month}-${selectedDay.day}`;
      setDate(date);
      var data = { start_date: date };
      var url = APIURL() + "event-time";
      axiosPost(url, data).then((res) => {
        console.log(res);
        setLoading(false);
        setBookingTimes(res.data[0].response.data);
      });
    }
  }, [selectedDay]);
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState();
  const selectDate = (time, index) => {
    // setSelectedDate(date.aAppointmentDate.split("T")[0]);
    // setSelectedDateObject(date);
    setSelectedTime(time);
    setId(index);
  };
  const [maxDate, setMaxDate] = useState();
  const [availableDays, setAvailableDays] = useState([]);
  useEffect(() => {
    var url = APIURL() + "event-date";
    axiosGet(url).then((response) => {
      var avail_days = [];
      var date = response.data[0].response.max_endDate.split("-");
      var all_date = response.data[0].response.data;
      setMaxDate({
        year: date[0],
        month: date[1],
        day: date[2],
      });
      console.log(all_date);
      for (const property in all_date) {
        console.log(`${property}: ${all_date[property].start_date}`);
        var dates = all_date[property].start_date.split("-");
        avail_days.push({
          year: Number(dates[0]),
          month: Number(dates[1]),
          day: Number(dates[2]),
          className: "navyBlueDay",
        });
      }
      setAvailableDays(avail_days);
    });
  }, []);
  return (
    <>
      <SyncLoader
        loading={loading}
        className="react-loader-new"
        color="#9a13d2"
      />
      <div className="k-my-8 align-center">
        <div className="k-mb-4 k-font-weight-bold text-center">
          Select date and time
        </div>

        <div className="k-flex k-display-flex k-mb-4">
          <Calendar
            className="col-6"
            value={selectedDay}
            onChange={setSelectedDay}
            shouldHighlightWeekends
            minimumDate={utils().getToday()}
            maximumDate={maxDate}
            customDaysClassName={availableDays}
          />

          <div className="k-ml-4 k-display-flex k-flex-col col-6">
            <div className="standard-product">
              {bookingTimes && bookingTimes.length > 0 ? (
                <div>
                  <ul>
                    {bookingTimes.map((bt, index) => (
                      <li
                        key={index}
                        onClick={(e) => selectDate(bt, index)}
                        className={id == index ? "active" : ""}
                      >
                        {bt}
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                <h6>No time slots available</h6>
              )}
            </div>
          </div>
        </div>
        {date && selectedTime ? <a className="btn-style-two">Proceed</a> : ""}
      </div>
    </>
  );
}

export default DateAndTime;
