import React from "react";
import { Link } from "react-router-dom";

function Body() {
  return (
    <>
      <div>
        <div className="starsec" />
        <div className="starthird" />
        <div className="starfourth" />
        <div className="starfifth" />
      </div>
      <div className="lamp__wrap">
        <div className="lamp">
          <div className="cable" />
          <div className="cover" />
          <div className="in-cover">
            <div className="bulb" />
          </div>
          <div className="light" />
        </div>
      </div>
      <section className="error">
        <div className="error__content">
          <div className="error__message message">
            <div className="logo2">
              <Link to="/">
                <img src="images/logo-wh.png" alt />
              </Link>
            </div>
            <h1 className="message__title">404</h1>
            <h3 className="message__title2">Page Not Found</h3>
            <p className="message__text">
              We're sorry, the page you were looking for isn't found here.
            </p>
          </div>
          <div className="error__nav e-nav">
            <a href target="_blanck" className="e-nav__link" />
          </div>
        </div>
      </section>
    </>
  );
}

export default Body;
