import { React, useEffect } from "react";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import Icons from "../Common/Icons";
import Body from "../Components/Login/Body";

function Login(props) {
  const { setBackGroundColor } = props;
  useEffect(() => {
    setBackGroundColor("bg-texture-gradient gradient2");
  }, []);
  return (
    <>
      <Header />
      <Icons />
      <div className="box-margin">
        <Body />
        {/* <Footer className="border-top" /> */}
      </div>
    </>
  );
}

export default Login;
