import React from "react";
import { Navigate } from "react-router-dom";

function ProtectedRoutesDriver(props) {
  var isSignedIn_driver = false;

  if (localStorage.getItem("driverId") != null) {
    isSignedIn_driver = true;
  }
  const { children } = props;
  if (!isSignedIn_driver) {
    return <Navigate to="/driver-login" replace />;
  }
  return children;
}

export default ProtectedRoutesDriver;
