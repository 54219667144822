import React, { useEffect, useState } from "react";
import { APIURL } from "../../../Methods/Fetch";
import { axiosGet } from "../../../Methods/Save";
import Carousel from "better-react-carousel";
import { SyncLoader } from "react-spinners";
import { Skeleton } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";

function WardrobeScreen({ setScreen }) {
  const [charTypes, setCharTypes] = useState();
  const [selectedCharTypes, setSelectedCharType] = useState(
    JSON.parse(localStorage.getItem("charType"))
  );
  const [characters, setCharacters] = useState();
  const [selectedCharObj, setSelectedCharObj] = useState(
    JSON.parse(localStorage.getItem("characterOne"))
  );
  const [selectedCharObj2, setSelectedCharObj2] = useState(
    JSON.parse(localStorage.getItem("characterTwo"))
  );
  const [activateSelectSecondPlayer, setActivateSelectSecondPlayer] = useState(
    localStorage.getItem("activateSelectSecondPlayer")
  );
  const [showNext, setShowNext] = useState(false);
  const [players, setPlayers] = useState(localStorage.getItem("players"));

  useEffect(() => {
    var url = APIURL() + "types";
    axiosGet(url).then((response) => {
      setCharTypes(response.data[0].response.data);
    });
  }, []);
  useEffect(() => {
    if (selectedCharTypes && Object.keys(selectedCharTypes).length > 0) {
      var url = APIURL() + `chars/${selectedCharTypes.id}`;
      axiosGet(url).then((response) => {
        setCharacters(response.data[0].response.data);
      });
    }
  }, [selectedCharTypes]);
  const handleChange = (event) => {
    setSelectedCharType(
      charTypes.filter((charType) => {
        return charType.id == event.target.value;
      })[0]
    );
  };
  const selectCharacter = (char) => {
    if (activateSelectSecondPlayer == "yes") {
      if (selectedCharObj.id == char.id) {
        toast.error("Unselected first player!", {
          position: toast.POSITION.TOP_CENTER,
          theme: "dark",
        });
        setActivateSelectSecondPlayer("no");
        setSelectedCharObj({});
        // setReselectChar1(true);
        setSelectedCharObj2({});
      } else {
        setSelectedCharObj2(char);
        // setShowNext(true);
      }
    } else {
      setSelectedCharObj(char);
      selectCharacter1();
    }
  };
  const selectCharacter1 = () => {
    if (players == 1) setShowNext(true);
    else {
      toast.success("Please Select Second Player!", {
        position: toast.POSITION.TOP_CENTER,
        theme: "dark",
      });
      setActivateSelectSecondPlayer("yes");
    }
  };
  const saveInLocal = () => {
    localStorage.setItem(
      "activateSelectSecondPlayer",
      activateSelectSecondPlayer
    );
    localStorage.setItem("players", players);
    // localStorage.setItem("showChars", showChars);
    localStorage.setItem("charType", JSON.stringify(selectedCharTypes));
    localStorage.setItem("characterOne", JSON.stringify(selectedCharObj));
    if (players == 2)
      localStorage.setItem("characterTwo", JSON.stringify(selectedCharObj2));
    // navigate("/step-three");
    setScreen(0);
  };

  return (
    <>
      <ToastContainer />
      <div className="book-data2">
        <div className="row">
          <div className="col-lg-12">
            <div className="event-title">
              <h4>Character Selection</h4>
            </div>
          </div>
        </div>
        <div className="step-seven-form">
          <select className="form-select" onChange={handleChange}>
            <option value="" selected>
              Select a character type
            </option>
            {charTypes &&
              charTypes.map((character) => (
                <option
                  value={character.id}
                  selected={selectedCharTypes.id == character.id ? true : false}
                >
                  {character.name}
                </option>
              ))}
          </select>
        </div>
        <div className="desc-char step3-playerchoose">
          {characters && characters.length > 0 ? (
            <Carousel cols={2} rows={1} hideArrow={true}>
              {characters.map((char, index) => (
                <Carousel.Item>
                  <div className="flex-char">
                    <div className="p-tag">
                      <h2
                        className={
                          char.id == selectedCharObj.id ? "" : "opa_zero"
                        }
                      >
                        P1
                      </h2>
                      <h2
                        className={
                          char.id == selectedCharObj2.id ? "" : "opa_zero"
                        }
                      >
                        P2
                      </h2>
                    </div>

                    <a
                      style={{ border: "1px solid red" }}
                      className={
                        Object.keys(selectedCharObj).length === 0
                          ? ""
                          : char.id == selectedCharObj.id ||
                            char.id == selectedCharObj2.id
                          ? "active"
                          : "grayScaled"
                      }
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        selectCharacter(char);
                      }}
                    >
                      <img src={char.image} alt="char" />
                    </a>
                  </div>
                </Carousel.Item>
              ))}
            </Carousel>
          ) : (
            <Skeleton
              sx={{ bgcolor: "#ffffff61" }}
              variant="rectangular"
              height={150}
              className="fullwidth_skeleton"
            />
          )}
          <div className="">
            <a onClick={saveInLocal} className="btn_anchor">
              <span> Save</span>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default WardrobeScreen;
