import React, { useEffect, useState } from "react";
import { APIURL } from "../../Methods/Fetch";
import { axiosGet } from "../../Methods/Save";
import Menu from "../DriverDashboard/Menu";
import SyncLoader from "react-spinners/SyncLoader";

function Body(props) {
  const { driverDetails } = props;

  const [bookingDetails, setbookingDetails] = useState([]);
  useEffect(() => {
    setLoading(true);
    var url = APIURL() + `driver-history/${driverDetails.id}`;
    axiosGet(url).then((response) => {
      setbookingDetails(response.data[0].response.data);
      setLoading(false);
    });
  }, []);
  const [loading, setLoading] = useState(false);

  return (
    <>
      <SyncLoader
        loading={loading}
        className="react-loader-new"
        color="#9a13d2"
      />
      <section className="banner-sec">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 mx-auto">
              <div className="driver-dashboard-page">
                <Menu driverDetails={driverDetails} />
                <div className="row">
                  {bookingDetails && bookingDetails.length > 0 ? (
                    bookingDetails.map((bookingDetail) => (
                      <div className="col-lg-9 mx-auto">
                        <div className="profile-confirm-page">
                          <div className="profile-cr">
                            <div className="profile-title-flex">
                              <div className="pro-img">
                                <img src="images/avatar-1.jpg" alt />
                              </div>
                              <div className="pro_title_desc">
                                <ul>
                                  <li>
                                    <h6>Customer Name :</h6>
                                    <span>{bookingDetail.customer_name}</span>
                                  </li>
                                  <li>
                                    <h6>Booking ID :</h6>
                                    <span>{bookingDetail.booking_id}</span>
                                  </li>
                                  <li>
                                    <h6>Players :</h6>
                                    <span>{bookingDetail.player}</span>
                                  </li>
                                  <li>
                                    <h6>Booking date :</h6>
                                    <span>{bookingDetail.booking_date}</span>
                                  </li>                                 
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="pickup-box raise-form">
                            <div className="pickup-date">
                              <div className="row">
                                <div className="col-lg-12">
                                  <a
                                    href="#"
                                    className={
                                      bookingDetail.booking_status == 1
                                        ? "btn-accept"
                                        : "btn-reject"
                                    }
                                  >
                                    {bookingDetail.booking_status == 1
                                      ? "Completed"
                                      : "Rejected"}
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="text-center">
                      <h6>No previous bookings</h6>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Body;
