import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

function NearbyAddressModal({
  modal,
  toggle,
  setModal,
  setOpen,
  setShowNearestAddresses,
  showNearestAddresses,
}) {
  const chooseAnotherPostCode = () => {
    setOpen(true);
    toggle();
  };
  const chooseNearestAddress = () => {
    setShowNearestAddresses(!showNearestAddresses);
    toggle();
  };
  return (
    <Modal isOpen={modal} toggle={toggle} backdrop="static">
      <ModalHeader>
        <h4 style={{ fontSize: "17px", margin: "0px" }}>
          No addresses available.
        </h4>
      </ModalHeader>
      <ModalBody>
        <p>
          Sorry there are no addresses available for this postcode.Please select
          another postcode or choose from one of the available nearest pickup
          points.
        </p>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          class="btn btn-info"
          onClick={chooseNearestAddress}
        >
          Choose from nearest addresses
        </button>
        <button
          type="button"
          class="btn btn-warning"
          onClick={chooseAnotherPostCode}
        >
          Choose another postcode
        </button>
      </ModalFooter>
    </Modal>
  );
}

export default NearbyAddressModal;
