import React, { useRef, useState } from "react";
import {
  alphaBetsOnly,
  BlankValidation,
  emailOnly,
} from "../../Methods/ValidateForms";
import { ToastContainer, toast } from "react-toastify";
import { axiosPost } from "../../Methods/Save";
import { APIURL } from "../../Methods/Fetch";
import ReCAPTCHA from "react-google-recaptcha";

function Body() {
  const captchaRef = useRef();

  const [disabled, setDisabled] = useState(false);
  const [userDetails, setUserDetails] = useState({
    email: "",
    name: "",
    enquiry: "",
  });
  const [errors, setErrors] = useState({
    email: "blank",
    name: "blank",
    enquiry: "blank",
  });
  const handleChange = (e) => {
    const { name, value, id } = e.target;
    setUserDetails({ ...userDetails, [name]: value });
    if (value.length == 0) {
      setErrors({ ...errors, [name]: `${id} cannot be empty` });
    } else {
      setErrors({ ...errors, [name]: "" });
    }
  };
  const login = () => {
    setDisabled(true);
    const id = toast.loading("Please wait...");
    var url = APIURL() + "contact-store";
    var data = {
      name: userDetails.name,
      email: userDetails.email,
      message: userDetails.enquiry,
    };
    axiosPost(url, data).then((response) => {
      setDisabled(false);
      if (response.data[0].response.status == "success") {
        toast.update(id, {
          render: "Form submitted successfully",
          type: "success",
          isLoading: false,
          autoClose: 3000,
          theme: "dark",
        });
        setUserDetails({
          email: "",
          name: "",
          enquiry: "",
        });
        setErrors({
          email: "blank",
          name: "blank",
          enquiry: "blank",
        });
      } else
        toast.update(id, {
          render: "Please try again later",
          type: "error",
          isLoading: false,
          autoClose: 3000,
          theme: "dark",
        });
    });
  };
  const validate = () => {
    const result = BlankValidation(userDetails, login, errors, setErrors);
    if (result == false)
      toast.error("Please fill all the mandatory fields!", {
        position: toast.POSITION.TOP_RIGHT,
        theme: "dark",
      });
  };
  function onChangeCaptcha(value) {
    setCaptcha(true);
  }
  const [captcha, setCaptcha] = useState(false);
  return (
    <>
      <ToastContainer />
      <div className="contact-sec">
        <div className="row">
          <div className="col-lg-5 contact-sec-left">
            <h6>Say hi to the team</h6>
            <h2>Contact Us</h2>
            <ul>
              <li>
                <input
                  id="Name"
                  className={
                    errors.name === "blank"
                      ? "form-control"
                      : errors.name == ""
                      ? "form-control is-valid"
                      : "form-control is-invalid"
                  }
                  type="text"
                  tabIndex={1}
                  name="name"
                  value={userDetails.name}
                  onChange={(e) =>
                    alphaBetsOnly(
                      e,
                      setUserDetails,
                      userDetails,
                      setErrors,
                      errors
                    )
                  }
                  placeholder="Enter Your Name"
                  required
                />
                <div class="invalid-feedback">{errors.name}</div>
              </li>
              <li>
                <input
                  id="E-Mail"
                  className={
                    errors.email === "blank"
                      ? "form-control"
                      : errors.email == ""
                      ? "form-control is-valid"
                      : "form-control is-invalid"
                  }
                  type="email"
                  tabIndex={1}
                  name="email"
                  value={userDetails.email}
                  onChange={(e) =>
                    emailOnly(e, setUserDetails, userDetails, setErrors, errors)
                  }
                  placeholder="Enter Your E-Mail"
                  required
                />
                <div class="invalid-feedback">{errors.email}</div>
              </li>
              <li>
                <textarea
                  id="Enquiry Text"
                  className={
                    errors.enquiry === "blank"
                      ? "form-control"
                      : errors.enquiry == ""
                      ? "form-control is-valid"
                      : "form-control is-invalid"
                  }
                  type="enquiry"
                  tabIndex={1}
                  name="enquiry"
                  value={userDetails.enquiry}
                  onChange={(e) => handleChange(e)}
                  placeholder="Enquires"
                  required
                  rows={3}
                  defaultValue={""}
                />
                <div class="invalid-feedback">{errors.enquiry}</div>
              </li>
              <li>
                {" "}
                <ReCAPTCHA
                  sitekey="6LcagNkkAAAAAKq1J1TY9Dvw2DWtGzWeHmXcQ1av"
                  onChange={onChangeCaptcha}
                  ref={captchaRef}
                />
              </li>
              <li>
                <button
                  onClick={validate}
                  type="button"
                  className={
                    disabled == true
                      ? "btn-style-one disabled_btn"
                      : "btn-style-one"
                  }
                  disabled={disabled == true ? true : false}
                >
                  Send
                </button>
              </li>
            </ul>
          </div>
          <div className="col-lg-5 ms-auto contact-sec-right">
            <div className="contact-sec-right-box">
              <h6>Opening Hours</h6>
              <p className="p-0 m-0">
                Monday - Friday
                <br />
                9am - 5pm
                <br />
                Weekend
                <br />
                Closed
              </p>
            </div>
            <div className="contact-sec-right-box border-0">
              <h6>Press:</h6>
              <p>info@noplan.london.com</p>
              <h6>Digital / Marketing:</h6>
              <p>cy.chan@noplan.london.com</p>
              <h6>Collaboration / Commercial:</h6>
              <p>gly-yiuyiu@hotmail.com</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Body;
