import React, { useEffect, useState } from "react";
import Switch from "react-switch";
import Menu from "../DriverDashboard/Menu";
import { ToastContainer, toast } from "react-toastify";
import { APIURL } from "../../Methods/Fetch";
import { axiosPost } from "../../Methods/Save";

function Body(props) {
  const { driverDetails, setDriverDetails } = props;
  const [editMode, setEditMode] = useState({
    name: "",
    phone: "",
  });
  const [checked, setChecked] = useState();
  const handleChange = (nextChecked) => {
    setChecked(nextChecked);
  };
  const handleInput = (e) => {
    const { name, value } = e.target;
    setDriverDetails({ ...driverDetails, [name]: value });
  };
  useEffect(() => {
    if (driverDetails.availibility == "unavailable") setChecked(false);
    else setChecked(true);
  }, []);
  const updateProfile = () => {
    var available = "unavailable";
    if (checked) {
      available = "available";
    }
    var url = APIURL() + "drivers-store";
    var data = {
      ...driverDetails,
      availibility: available,
    };
    axiosPost(url, data).then((response) => {
      console.log("asigali");
      setDriverDetails(data);
      localStorage.setItem("driverDetails", JSON.stringify(data));
    });
  };
  return (
    <>
      <section className="banner-sec">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 mx-auto">
              <div className="driver-dashboard-page">
                <Menu driverDetails={driverDetails} />
                <div className="row">
                  <div className="col-lg-5 col-md-5">
                    <div className="profile-icon-pic">
                      <div className="pf-icon-img">
                        <img
                          src={
                            driverDetails.profile
                              ? driverDetails.profile
                              : "images/avatar-2.jpg"
                          }
                          alt
                        />
                      </div>
                      <div className="pf-text">
                        <h4>{driverDetails.name}</h4>
                        <span>Driver</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-7 col-md-7">
                    <div className="black-box">
                      <div className="raise-ticket">
                        <div className="raise-title">
                          <p>Account Details</p>
                        </div>
                      </div>
                      <div className="spacer1px" />
                      <div className="acc-data">
                        <div className="ac-title">
                          <ul>
                            <li>
                              <div>
                                <span>Name : </span>
                                <a href="#">
                                  <input
                                    className={
                                      editMode.name == true
                                        ? ""
                                        : "edit_profile"
                                    }
                                    name="name"
                                    type="text"
                                    value={driverDetails.name}
                                    onChange={handleInput}
                                    disabled={
                                      editMode.name == true ? false : true
                                    }
                                  />
                                </a>
                              </div>
                              <div className="acc-icon">
                                <a href="#" className="che-icon">
                                  <i className="fal fa-badge-check" />
                                </a>
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) =>
                                    setEditMode({
                                      ...editMode,
                                      name: !editMode.name,
                                    })
                                  }
                                  className="ed-icon"
                                >
                                  <i className="fal fa-edit" />
                                </a>
                              </div>
                            </li>
                            <li>
                              <div>
                                <span>Phone Number : </span>
                                <a href="#">
                                  <input
                                    className={
                                      editMode.phone == true
                                        ? ""
                                        : "edit_profile"
                                    }
                                    name="phone"
                                    type="text"
                                    value={driverDetails.phone}
                                    onChange={handleInput}
                                    disabled={
                                      editMode.phone == true ? false : true
                                    }
                                  />
                                </a>
                              </div>
                              <div className="acc-icon">
                                <a href="#" className="che-icon">
                                  <i className="fal fa-badge-check" />
                                </a>
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) =>
                                    setEditMode({
                                      ...editMode,
                                      phone: !editMode.phone,
                                    })
                                  }
                                  className="ed-icon"
                                >
                                  <i className="fal fa-edit" />
                                </a>
                              </div>
                            </li>
                            <li>
                              <div>
                                <span>E-mail : </span>
                                <a href="#">
                                  <input
                                    className={
                                      editMode.email == true
                                        ? ""
                                        : "edit_profile"
                                    }
                                    name="email"
                                    type="email"
                                    value={driverDetails.email}
                                    onChange={handleInput}
                                    disabled={
                                      editMode.email == true ? false : true
                                    }
                                  />
                                </a>
                              </div>
                              <div className="acc-icon">
                                <a href="#" className="che-icon">
                                  <i className="fal fa-badge-check" />
                                </a>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="black-box-uma">
                      <div className="raise-ticket">
                        <div className="raise-title">
                          <p className="text-center">Availablity</p>
                        </div>
                      </div>
                      <div className="row acc-data raise-form">
                        <label>Are you avalable today?</label>
                        <Switch
                          onChange={handleChange}
                          checked={checked}
                          className="react-switch"
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="mb-3">
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={updateProfile}
                          className="btn-one"
                        >
                          SAVE
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Body;
