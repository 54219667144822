import React, { useEffect, useState } from "react";
import { APIURL } from "../../../Methods/Fetch";
import { axiosGet, axiosPost } from "../../../Methods/Save";
import Icons from "./Icons";

import {
  Autocomplete,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import NearbyAddressModal from "./NearbyAddressModal";

function PartTwo({
  userDetails,
  players,
  eventsObj,
  setPart,
  pay,
  setStreet,
  address,
  setAddress,
  mode,
  setMode,
  toast,
  fullAddress,
  setFullAddress,
}) {
  const [zones, setZones] = useState({});
  const [zone, setZone] = useState();
  const [showNearestAddresses, setShowNearestAddresses] = useState(false);
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [postCode, setPostCode] = useState();
  const [postCodes, setPostCodes] = useState({});
  const [streets, setStreets] = useState({});
  const [open, setOpen] = useState(false);
  const [options, setOptions] = React.useState([]);

  useEffect(() => {
    var url = APIURL() + "zones";

    axiosGet(url).then((response) => {
      setZones(response.data[0].response.data);
      setPostCode("");
    });
  }, []);
  useEffect(() => {
    if (zone && zone != "") {
      // setOptions([]);
      setAddress({});
      var url = APIURL() + "postcodes/" + zone;
      setLoading(true);
      axiosGet(url).then((response) => {
        setPostCodes(response.data[0].response.data);
        setOptions(response.data[0].response.data);
        setOpen(true);
        setStreet("");
        setLoading(false);
      });
    }
  }, [zone]);
  useEffect(() => {
    if (postCode && postCode != "") var url = APIURL() + "address/" + postCode;
    setLoading(true);

    axiosGet(url).then((response) => {
      setStreets(response.data[0].response.data);
    });
  }, [postCode]);
  const handleChange = (e) => {
    setAddress(e.target.value);
  };
  const handleOptionSelected = (option, value) => option.id === value.id;
  useEffect(() => {
    const fetchOption = async () => {
      setPostCode(address);
      var url = APIURL() + "address/" + address.postcode_id;
      setLoading(true);
      const res = await axiosGet(url);
      if (res.data[0].response.data.length == 0) {
        toast.error(
          "There are no addresses available for the selected Postcode.Please choose a nearby available location!",
          {
            position: toast.POSITION.TOP_RIGHT,
            theme: "dark",
          }
        );
        setModal(true);
      } else {
        setOptions(res.data[0].response.data);
        setTimeout(() => {
          setOpen(true);
        }, 1000);
        // setOpen(true);
      }
      setLoading(false);
    };
    if (address?.postcode_id) {
      // setOptions({});
      // setAddress({});
      fetchOption();
    }
  }, [address]);
  const toggle = () => {
    setModal(!modal);
  }; 
  useEffect(() => {
    const fetchData = async () => {
      var url = APIURL() + `nearby-addresses/${postCode.postcode_id}`;

      const res = await axiosGet(url);
      setOptions(res.data[0].response.data);
      setTimeout(() => {
        setOpen(true);
      }, 1000);
    };
    fetchData();
  }, [showNearestAddresses]);
  const handleClear = () => {
    setOptions(postCodes);
    setOpen(true);
  };
  return (
    <>
      <div className="step3-body">
        <div>
          <div className="profile-cr">
            <div className="profile-title-flex">
              <div className="pro-img">
                <img src="images/avatar-1.jpg" alt="asd" />
              </div>
              <div className="pro-title">
                <h4>{userDetails.name}</h4>
                <span>Player :{players}P</span>
              </div>
              <Icons />
            </div>
          </div>
          <div className="book-data1">
            <div className="row">
              <div className="col-lg-12">
                <div className="event-title">
                  <h4>Do you want us to assign a driver for you?</h4>
                </div>
              </div>
            </div>
            <div className="row">
              <FormControl>
                <RadioGroup
                  value={mode}
                  onChange={(e) => setMode(e.target.value)}
                  className="row rowFlex"
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                >
                  <div className="col-lg-6">
                    <FormControlLabel
                      value="driver"
                      control={<Radio />}
                      label="Driver for me"
                    />
                  </div>
                  <div className="col-lg-6">
                    <FormControlLabel
                      value="self"
                      control={<Radio />}
                      label="I will go there myself"
                    />
                  </div>
                </RadioGroup>
              </FormControl>
            </div>

            {mode == "driver" && (
              <div className="row">
                <div className="date-time-text">
                  <h5>Zone</h5>
                </div>
                <div className="col-lg-12">
                  <div className="event-box">
                    <select
                      class="form-select"
                      aria-label="Disabled select example"
                      value={zone}
                      onChange={(e) => setZone(e.target.value)}
                    >
                      <option value="" selected>
                        Select a Zone
                      </option>
                      {zones && zones.length > 0
                        ? zones.map((zone, index) => (
                            <option
                              key={index}
                              selected={zone.zone_id == zone ? true : false}
                              value={zone.zone_id}
                              className="zoneone-zonetwo"
                            >
                              {zone.name}
                            </option>
                          ))
                        : ""}
                    </select>
                  </div>
                  {zone && (
                    <div>
                      <Autocomplete
                        className="form-control"
                        options={options}
                        getOptionLabel={(option) =>
                          option.name
                            ? option.name +
                              ` ${postCode && postCode.name}` +
                              `, ${
                                zones.filter((z) => z.zone_id == zone)[0].name
                              }`
                            : ""
                        }
                        id="auto-complete"
                        autoComplete
                        value={address}
                        loading={loading}
                        open={open}
                        onClose={() => setOpen(false)}
                        isOptionEqualToValue={handleOptionSelected}
                        onChange={(event, newValue) => {
                          if (newValue === null) {
                            handleClear();
                          } else {
                            setAddress(newValue);
                            setOpen(false);
                          }
                        }}
                        onOpen={() => {
                          setOpen(true);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="autoCompleteAddress"
                            label="Select a Postcode and select your address"
                            variant="standard"
                          />
                        )}
                      />
                    </div>
                  )}
                </div>
                {address.address_id && (
                  <div className="mt-2">
                    <div className="date-time-text">
                      <h5>Full address</h5>
                    </div>
                    <div className="event-box">
                      <input
                        type="text"
                        className="form-control"
                        value={fullAddress}
                        onChange={(e) => setFullAddress(e.target.value)}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 mb-3">
            <a href="#!" className="btn-one" onClick={(e) => setPart(1)}>
              Back
            </a>
          </div>
          <div className="col-lg-6">
            <a href="#!" className="btn-two" onClick={(e) => pay()}>
              Pay ${eventsObj.price}
            </a>
          </div>
        </div>
        {mode == "driver" ? (
          <div className="step-seven-2 desc-char step4-playerchoose">
            <p>
            Enjoy a hassle-free excursion with our dedicated chauffeur service for your day-long journey, pick up points Zone 1 and 2. Elevate your experience without the travel worries.
            </p>
          </div>
         
        ) : (
          <div className="desc-char step4-playerchoose">
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum .
            </p>
          </div>
        )}        
      </div>
      <NearbyAddressModal
        modal={modal}
        setModal={setModal}
        toggle={toggle}
        setOpen={setOpen}
        showNearestAddresses={showNearestAddresses}
        setShowNearestAddresses={setShowNearestAddresses}
      />
    </>
  );
}

export default PartTwo;
