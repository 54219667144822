import React from "react";
import { NavLink } from "react-router-dom";

function Menu(props) {
  const { driverDetails } = props;
  return (
    <>
      <div className="driver-title">
        <h4>Welcome {driverDetails.name}</h4>
      </div>
      <div className="driver-main-tab">
        <ul>
          <li>
            <NavLink to="/driver-dashboard" activeClassName="active">
              <i className="fal fa-calendar-alt" />
              <span>Dashboard</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/driver-history" activeClassName="active">
              <i className="far fa-history" />
              <span>History</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/driver-tickets" activeClassName="active">
              <i className="fad fa-ticket" />
              <span>Tickets</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/driver-settings" activeClassName="active">
              <i className="fas fa-cog" />
              <span>Settings</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/driver-calendar" activeClassName="active">
              <i className="far fa-flag" />
              <span>Calendar</span>
            </NavLink>
          </li>
        </ul>
      </div>
    </>
  );
}

export default Menu;
