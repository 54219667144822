import { useEffect } from "react";
import { React, useState } from "react";
import { APIURL } from "../../../Methods/Fetch";
import { axiosGet, axiosPost } from "../../../Methods/Save";
import PaymentModal from "./PaymentModal";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { ToastContainer, toast } from "react-toastify";
import DateAndTimeModal from "./DateAndTimeModal";
import PartOne from "./PartOne";
import PartTwo from "./PartTwo";
import PartOneMobile from "./PartOneMobile";
import PartTwoMobile from "./PartTwoMobile";

function Body() {
  const [modal, setModal] = useState(false);
  const [dateModal, setDateModal] = useState(false);
  const [save, setSave] = useState(false);
  const [bookingId, setBookingId] = useState(
    localStorage.getItem("booking_id")
  );
  const [characterOne, setCharacterOne] = useState(
    JSON.parse(localStorage.getItem("characterOne"))
  );
  const [characterTwo, setCharacterTwo] = useState(
    JSON.parse(localStorage.getItem("characterTwo"))
  );
  const [eventsObj, setEventsObj] = useState(
    JSON.parse(localStorage.getItem("eventsObj"))
  );
  const [hotelsObj, setHotelsObj] = useState(
    JSON.parse(localStorage.getItem("hotelsObj"))
  );
  const [userDetails, setUserDetails] = useState(
    JSON.parse(localStorage.getItem("userDetails"))
  );
  const [players, setPlayers] = useState(localStorage.getItem("players"));
  const [event, setEvent] = useState();
  const [fullAddress, setFullAddress] = useState();
  const [screen, setScreen] = useState(0);
  const [part, setPart] = useState(1);
  const [refresh, setRefresh] = useState(false);
  const [hotel, setHotel] = useState(localStorage.getItem("hotels"));
  const [date, setDate] = useState(localStorage.getItem("date"));
  const [time, setTime] = useState(localStorage.getItem("time"));
  const [selectedDay, setSelectedDay] = useState();
  const [events, setEvents] = useState();
  const [hotels, setHotels] = useState();
  const [street, setStreet] = useState();
  const [mode, setMode] = useState("driver");
  const initialEditState = {
    event: false,
    hotel: false,
    time: false,
    date: false,
  };
  const [editMode, setEditMode] = useState(initialEditState);
  const [address, setAddress] = useState({});
  const [cardDetails, setCardDetails] = useState({
    cardholderName: "",
    cardNumber: "",
    expiryDate: "",
    securityCode: "",
  });
  const [errors, setErrors] = useState({
    cardholderName: "blank",
    cardNumber: "blank",
    expiryDate: "blank",
    securityCode: "blank",
  });
  const toggle = () => setModal(!modal);
  const toggleDateModal = () => setDateModal(!dateModal);
  // useEffect(() => {
  //   if (hotelsObj.id) {
  //     var url = APIURL() + "restName/" + hotelsObj.id;
  //     axiosGet(url).then((response) => {});
  //   }
  // }, [hotelsObj]);
  useEffect(() => {
    if (eventsObj && Object.keys(eventsObj).length > 0)
      setEvent(eventsObj.name);
  }, [eventsObj]);
  useEffect(() => {
    if (eventsObj && Object.keys(eventsObj).length > 0)
      setEvent(eventsObj.name);
  }, [eventsObj]);
  useEffect(() => {
    if (localStorage.getItem("dateObj") != null) {
      setSelectedDay(JSON.parse(localStorage.getItem("dateObj")));
    }
  }, []);
  const callEvents = () => {
    setEvent();
    setEditMode({ ...editMode, event: true });
    toggleDateModal();
  };
  useEffect(() => {
    var url = APIURL() + "events";
    var data = {
      event_time: time,
      event_date: date,
    };
    axiosPost(url, data).then((response) => {
      setEvents(response.data[0].response.data);
      if (response.data[0].response.data.length == 0)
        toast.error("No event available for the selected date and time!", {
          position: toast.POSITION.TOP_RIGHT,
          theme: "dark",
        });
    });
  }, [date, time]);
  useEffect(() => {
    if (editMode.event === true) {
      setHotel();
      setEditMode({ ...editMode, hotel: true });
    }
    var url = APIURL() + "restaurants";
    var data = {
      event: eventsObj.id,
    };
    axiosPost(url, data).then((response) =>
      setHotels(response.data[0].response.data)
    );
  }, [eventsObj]);
  useEffect(() => {
    if (editMode.date == true) {
      setEvent();
      setEditMode({ ...editMode, date: false, event: true });
      setDate(`${selectedDay.year}-${selectedDay.month}-${selectedDay.day}`);
    }
  }, [selectedDay]);
  const verify = () => {
    if (eventsObj.id == "") {
      toast.error("Please select an event!", {
        position: toast.POSITION.TOP_RIGHT,
        theme: "dark",
      });
      return;
    }
    if (hotelsObj.id == "") {
      toast.error("Please select a hotel!", {
        position: toast.POSITION.TOP_RIGHT,
        theme: "dark",
      });
      return;
      // } else toggle();
    } else setPart(2);
  };
  const pay = () => {
    if (
      (mode == "driver") && (Object.keys(address).length == 0 ||
      !address.address_id ||
      !fullAddress ||
      fullAddress?.length == 0)
    ) {
      toast.error("Please select an Address and enter your full address!", {
        position: toast.POSITION.TOP_RIGHT,
        theme: "dark",
      });
      return;
    } else {
      var data = {
        booking_date: date,
        userId: userDetails.id,
        booking_id: bookingId,
        character_one_id: characterOne.id,
        character_two_id: characterTwo.id,
        event: eventsObj.id,
        restaurant: hotelsObj.id,
        price: eventsObj.price,
        event_time: time,
        players: players,
        pickup_location: mode == "driver" ? address.address_id : 0,
        full_address: fullAddress,
      };
      var url = APIURL() + "booking-store";
      axiosPost(url, data).then((response) => {
        setBookingId(response.data[0].response.booking_id);
        toggle();
      });
    }
  };
  useEffect(() => {
    var data = {
      booking_date: date,
      userId: userDetails.id,
      booking_id: bookingId,
      character_one_id: characterOne.id,
      character_two_id: characterTwo.id,
      event: eventsObj.id,
      restaurant: hotelsObj.id,
      price: eventsObj.price,
      event_time: time,
      players: players,
    };
    var url = APIURL() + "booking-store";
    axiosPost(url, data).then((response) => {
      setBookingId(response.data[0].response.booking_id);
    });
  }, [save]);

  // *---------Date and Time Start-------*
  const [selectedTime, setSelectedTime] = useState();
  const [loading, setLoading] = useState(false);

  // *---------Date and Time End-------*
  const changeEvent = (e) => {
    var selectedEvent = events.filter(
      (event) => (event.id == e.target.value)
    )[0];
    setEvent(selectedEvent.name);
    setEventsObj(selectedEvent);
  };
  const changeHotel = (e) => {
    var selectedHotel = hotels.filter(
      (event) => (event.id == e.target.value)
    )[0];   
    setHotel(selectedHotel.resturant_name);
    setHotelsObj(selectedHotel);
  };
  const reset = () => {
    setEventsObj(JSON.parse(localStorage.getItem("eventsObj")));
    setHotelsObj(JSON.parse(localStorage.getItem("hotelsObj")));
    setDate(localStorage.getItem("date"));
    setTime(localStorage.getItem("time"));
    setEditMode(initialEditState);
  };
  const saveChanges = () => {
    setSave(!save);
    setEditMode(initialEditState);
    setScreen(0);
    localStorage.setItem("eventsObj", JSON.stringify(eventsObj));
    localStorage.setItem("hotelsObj", JSON.stringify(hotelsObj));
    localStorage.setItem("date", date);
    localStorage.setItem("time", time);
  };
  useEffect(() => {
    if (screen == 4) {
      setEditMode({
        ...editMode,
        event: true,
        hotel: true,
      });
    } else if (screen == 0) setEditMode(initialEditState);
  }, [screen]);
  return (
    <>
      <ToastContainer />
      <div
        className={
          editMode.date == true || editMode.time == true
            ? "datetimediv"
            : "d-none"
        }
      ></div>
      <div className="desktop-sec">
        <section className="banner-sec">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 mx-auto">
                <div className="top-img">
                  {part == 1 ? (
                    <PartOne
                      userDetails={userDetails}
                      players={players}
                      toggleDateModal={toggleDateModal}
                      editMode={editMode}
                      event={event}
                      changeEvent={changeEvent}
                      events={events}
                      eventsObj={eventsObj}
                      setEditMode={setEditMode}
                      hotel={hotel}
                      changeHotel={changeHotel}
                      hotels={hotels}
                      hotelsObj={hotelsObj}
                      date={date}
                      time={time}
                      reset={reset}
                      saveChanges={saveChanges}
                      verify={verify}
                      street={street}
                      setStreet={setStreet}
                      pay={pay}
                      screen={screen}
                      setScreen={setScreen}
                    />
                  ) : (
                    <PartTwo
                      userDetails={userDetails}
                      players={players}
                      toggleDateModal={toggleDateModal}
                      editMode={editMode}
                      event={event}
                      changeEvent={changeEvent}
                      events={events}
                      eventsObj={eventsObj}
                      setEditMode={setEditMode}
                      hotel={hotel}
                      changeHotel={changeHotel}
                      hotels={hotels}
                      date={date}
                      time={time}
                      reset={reset}
                      saveChanges={saveChanges}
                      verify={verify}
                      setPart={setPart}
                      street={street}
                      setStreet={setStreet}
                      pay={pay}
                      address={address}
                      setAddress={setAddress}
                      mode={mode}
                      setMode={setMode}
                      toast={toast}
                      fullAddress={fullAddress}
                      setFullAddress={setFullAddress}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="mobile-sec">
        <div className="mob-img-box step7">
          {part == 1 ? (
            <PartOneMobile
              userDetails={userDetails}
              players={players}
              toggleDateModal={toggleDateModal}
              editMode={editMode}
              event={event}
              changeEvent={changeEvent}
              events={events}
              eventsObj={eventsObj}
              setEditMode={setEditMode}
              hotel={hotel}
              changeHotel={changeHotel}
              hotels={hotels}
              date={date}
              time={time}
              reset={reset}
              saveChanges={saveChanges}
              verify={verify}
              setPart={setPart}
              setEvent={setEvent}
              setHotel={setHotel}
              screen={screen}
              setScreen={setScreen}
            />
          ) : (
            <PartTwoMobile
              userDetails={userDetails}
              players={players}
              toggleDateModal={toggleDateModal}
              editMode={editMode}
              event={event}
              changeEvent={changeEvent}
              events={events}
              eventsObj={eventsObj}
              setEditMode={setEditMode}
              hotel={hotel}
              changeHotel={changeHotel}
              hotels={hotels}
              date={date}
              time={time}
              reset={reset}
              saveChanges={saveChanges}
              verify={verify}
              setPart={setPart}
              setEvent={setEvent}
              setHotel={setHotel}
              street={street}
              setStreet={setStreet}
              pay={pay}
            />
          )}

          <img src="images/scene7.gif" alt="" />
        </div>
      </div>

      <PaymentModal
        setCardDetails={setCardDetails}
        cardDetails={cardDetails}
        setErrors={setErrors}
        errors={errors}
        modal={modal}
        setModal={setModal}
        toggle={toggle}
        // character_id={character.id}
        price={Number(eventsObj.price)}
        bookingId={bookingId}
        setRefresh={setRefresh}
        refresh={refresh}
        toast={toast}
        eventsObj={eventsObj}
      />
      <DateAndTimeModal
        selectedDay={selectedDay}
        setSelectedDay={setSelectedDay}
        selectedTime={time}
        setSelectedTime={setTime}
        setTime={setTime}
        setLoading={setLoading}
        date={date}
        setDate={setDate}
        dateModal={dateModal}
        toggleDateModal={toggleDateModal}
        callEvents={callEvents}
        setScreen={setScreen}
      />
    </>
  );
}

export default Body;
