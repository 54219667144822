import React, { useEffect, useState } from "react";
import { APIURL } from "../Methods/Fetch";
import { axiosGet } from "../Methods/Save";

function Icons() {
  const [pageDetails, setPageDetails] = useState([]);
  useEffect(() => {
    var url = APIURL() + `website-settings`;
    axiosGet(url).then((response) => {
      setPageDetails(response.data.data);
    });
  }, []);
  return (
    <>
      {/* <div className="social-box">
        <ul>
          <li className="facebook">
            <a target="_blank" href={pageDetails.facebook}>
              <i className="fab fa-facebook-square" />
              Facebook
            </a>
          </li>
          <li className="instagram">
            <a target="_blank" href={pageDetails.instagram}>
              <i className="fab fa-instagram" />
              Instagram
            </a>
          </li>
          <li className="tiktok">
            <a target="_blank" href={pageDetails.tiktok}>
              <i className="fab fa-tiktok" />
              Tik Tok
            </a>
          </li>
        </ul>
      </div> */}
    </>
  );
}

export default Icons;
