import { React, useEffect } from "react";
import Footer from "../../Common/Footer";
import Header from "../../Common/Header";
import Icons from "../../Common/Icons";
import Body from "../../Components/OrderProcess/StepThree/Body";

function StepOne(props) {
  const { setBackGroundColor } = props;
  useEffect(() => {
    setBackGroundColor("step4-fullbg");
  }, []);
  return (
    <>
      <Header />
      <Icons />
      <div className="box-margin">
        <Body />
      </div>
    </>
  );
}

export default StepOne;
