import { Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { APIURL } from "../../Methods/Fetch";
import { axiosGet } from "../../Methods/Save";

function Body() {
  const [pageDetails, setPageDetails] = useState([]);
  useEffect(() => {
    var url = APIURL() + "careers-list";
    axiosGet(url).then((response) => {
      setPageDetails(response.data[0].response.data);
    });
  }, []);
  console.log(pageDetails);
  return (
    <>
      <div className="bodywork-sec">
        <div className="row align-items-center">
          <div className="col-lg-12">
            <div className="about-content ml-36">
              <h1 className="text-center">Join NOPLAN</h1>
              <p className="m-0 text-center">
                We are currently recruiting for a number of roles across
                multiple areas of our growing business. Please see the available
                opportunities below, and fill out the corresponding online form
                should you feel one of these listings is the right fit. We are
                excited to hear from you and thank you for your interest in
                NOPLAN.
              </p>
            </div>
          </div>
          <hr className="spacer30px" />
          <div className="row jobs-listing">
            {pageDetails && pageDetails.length > 0 ? (
              pageDetails.map((pd) => (
                <div className="col-lg-4 mb-3">
                  <div className="jobs-listing-box">
                    <h4>{pd.title}</h4>
                    <div
                      dangerouslySetInnerHTML={{ __html: pd.description }}
                    ></div>
                    <div className="text-end">
                      <Link to={pd.btn_link} className="btn-style-one small">
                        {pd.btn_name} <i className="far fa-chevron-right" />
                      </Link>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <Skeleton
                className="fullwidth_skeleton"
                sx={{ bgcolor: "#ffffff61" }}
                variant="rectangular"
                height={400}
              />
            )}
          </div>
          {/* <div class="col-lg-12 text-center">
  <a href="#" class="btn-style-one small "> <i class="fal fa-sign-in-alt"></i> &nbsp;Login</a>
    </div>*/}
        </div>
      </div>
    </>
  );
}

export default Body;
