import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { SyncLoader } from "react-spinners";
import { APIURL } from "../../Methods/Fetch";
import { axiosGet } from "../../Methods/Save";
import Menu from "../DriverDashboard/Menu";

function Body(props) {
  const { driverDetails } = props;
  const [tickets, setTickets] = useState([]);

  useEffect(() => {
    setLoading(true);
    var url = APIURL() + `ticket/listing/${driverDetails.id}`;
    axiosGet(url).then((response) => {
      setTickets(response.data[0].response.data);
      setLoading(false);
    });
  }, []);
  const [loading, setLoading] = useState(false);
  return (
    <>
      <SyncLoader
        loading={loading}
        className="react-loader-new"
        color="#9a13d2"
      />
      <section className="banner-sec">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 mx-auto">
              <div className="driver-dashboard-page">
                <Menu driverDetails={driverDetails} />
                <div className="raise-ticket">
                  <div className="raise-title">
                    <p>Raise a Ticket</p>
                  </div>
                  <div className="raise-btn-box">
                    <Link to="/driver-raise-ticket" className="btn-four">
                      Raise a Ticket
                    </Link>
                  </div>
                  </div>
                  <hr />
                {tickets && tickets.length > 0
                  ? tickets.map((ticket, index) => (
                      <div className="tickets-box">
                        <div className="tickets-box-top">
                          <div className="tickets-box-top1">
                            <h5>Ticket id</h5>
                            <span>#{ticket.id}</span>
                          </div>
                          <div className="tickets-box-top2">
                            Status:{" "}
                            <span
                              className={
                                ticket.status_id == 1
                                  ? "text-success"
                                  : "text-danger"
                              }
                            >
                              {ticket.status}
                            </span>{" "}
                            <br /> Priority:{" "}
                            <span
                              className={
                                ticket.priority_id == 1
                                  ? "text-danger"
                                  : ticket.priority_id == 2
                                  ? "text-warning"
                                  : "text-success"
                              }
                            >
                              {ticket.priority}
                            </span>
                          </div>
                        </div>
                        <div className="tickets-box-btm">
                          <span>
                            <i className="far fa-clock" /> {ticket.created_at}
                          </span>
                          <Link
                            to={`/driver-ticket/${ticket.id}`}
                            className="btn-design1"
                          >
                            View
                          </Link>
                        </div>
                      </div>
                    ))
                  : ""}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Body;
