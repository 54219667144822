import React from "react";
import { Link } from "react-router-dom";

function ThankYouPage() {
  return (
    <>
      <div>
        <div className="thanku-page">
          <img src="images/thanku-icon.png" alt />
          <h1>Thank You!</h1>
          <p>Thank Your For Choosing Pickup Address Your Pickup Address is </p>
          <h5>BST,125bc23 Station Road</h5>
          <Link to="/dashboard">GO TO DASHBOARD</Link>
        </div>
        <a href="#" className="back-to-top" style={{ display: "none" }}>
          <i className="fa fa-arrow-up" aria-hidden="true" />
        </a>
      </div>
    </>
  );
}

export default ThankYouPage;
