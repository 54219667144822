import React, { useState } from "react";
import { axiosPost } from "../../../Methods/Save";
import { ToastContainer, toast } from "react-toastify";
import { APIURL } from "../../../Methods/Fetch";
import { BlankValidation, emailOnly } from "../../../Methods/ValidateForms";
// import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Modal from "react-bootstrap/Modal";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Link, useNavigate } from "react-router-dom";

function LoginModal(props) {
  const { modal, toggle } = props;
  const navigate = useNavigate();

  const validate = () => {
    const result = BlankValidation(userDetails, login, errors, setErrors);
    if (result == false)
      toast.error("Please fill all the mandatory fields!", {
        position: toast.POSITION.TOP_RIGHT,
        theme: "dark",
      });
  };
  const login = () => {
    const id = toast.loading("Please wait...");
    var data = { ...userDetails };
    var url = APIURL() + "login";
    axiosPost(url, data)
      .then((res) => {
        localStorage.setItem(
          "userDetails",
          JSON.stringify({ ...res.data.user_data, id: res.data.user_id })
        );
        localStorage.setItem("userId", JSON.stringify(res.data.user_id));
        localStorage.removeItem("driverId");
        localStorage.removeItem("driverDetails");
        toast.update(id, {
          render: res.data.message,
          type: "success",
          isLoading: false,
          autoClose: 3000,
          theme: "dark",
        });
        navigate("/step-seven");
      })
      .catch((err) => {
        var message = "";
        if (err.response.data.email) {
          setErrors({ ...errors, email: err.response.data.email });
          message = err.response.data.email;
        }
        if (err.response.data.password) {
          setErrors({ ...errors, password: err.response.data.password });
          message = err.response.data.password;
        }
        toast.update(id, {
          render: message,
          type: "error",
          isLoading: false,
          autoClose: 3000,
          theme: "dark",
        });
      });
  };
  const [userDetails, setUserDetails] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({
    email: "blank",
    password: "blank",
  });
  const [showPassword, setShowPassword] = useState(false);
  const handleChange = (e) => {
    const { name, value, id } = e.target;
    setUserDetails({ ...userDetails, [name]: value });
    if (value.length == 0) {
      setErrors({ ...errors, [name]: `${id} cannot be empty` });
    } else {
      setErrors({ ...errors, [name]: "" });
    }
  };

  return (
    <>
      <Modal
        show={modal}
        onHide={toggle}
        backdrop="static"
        className="modal-new-box"
        centered
      >
        <Modal.Header>
          <h4 style={{ fontSize: "17px", margin: "0px" }}>
            Login to your account
          </h4>
        </Modal.Header>
        <Modal.Body>
          <div className="card-content register_uma">
            <div className="form-group">
              <div className="input-group nm-gp">
                <span className="nm-gp-pp">
                  <i className="fas fa-envelope-open" />
                </span>
                <input
                  id="Email"
                  className={
                    errors.email === "blank"
                      ? "form-control"
                      : errors.email == ""
                      ? "form-control is-valid"
                      : "form-control is-invalid"
                  }
                  type="email"
                  tabIndex={1}
                  name="email"
                  value={userDetails.email}
                  onChange={(e) =>
                    emailOnly(e, setUserDetails, userDetails, setErrors, errors)
                  }
                  placeholder="Enter Your Email"
                  required
                />
                <div class="invalid-feedback">{errors.email}</div>
              </div>
            </div>
            <div className="form-group mb-1">
              <div className="input-group nm-gp">
                <span className="nm-gp-pp">
                  <i className="fas fa-lock" />
                </span>
                <input
                  id="Password"
                  className={
                    errors.password === "blank"
                      ? "form-control"
                      : errors.password == ""
                      ? "form-control is-valid"
                      : "form-control is-invalid"
                  }
                  type={showPassword ? "text" : "password"}
                  tabIndex={2}
                  name="password"
                  value={userDetails.password}
                  onChange={(e) => handleChange(e)}
                  placeholder="Enter Your Password"
                  required
                />
                {showPassword ? (
                  <VisibilityIcon
                    onClick={(e) => setShowPassword(false)}
                    style={{
                      position: "absolute",
                      right: "13px",
                      top: "11px",
                      cursor: "pointer",
                      zIndex: "99999",
                    }}
                  />
                ) : (
                  <VisibilityOffIcon
                    onClick={(e) => setShowPassword(true)}
                    style={{
                      position: "absolute",
                      right: "13px",
                      top: "11px",
                      cursor: "pointer",
                      zIndex: "99999",
                    }}
                  />
                )}{" "}
                <div class="invalid-feedback">{errors.password}</div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="submit"
            className="btn btn-primary"
            onClick={(e) => validate()}
          >
            Log In
          </button>
          <button
            type="button"
            class="btn btn-danger"
            onClick={(e) => {
              toggle();
            }}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default LoginModal;
