import axios from "axios";
import React, { useRef, useState } from "react";
import { APIURL } from "../../Methods/Fetch";
import { ToastContainer, toast } from "react-toastify";

function Body(props) {
  const ref = useRef(null);
  const { userDetails, setUserDetails } = props;
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserDetails({ ...userDetails, [name]: value });
  };
  const [editMode, setEditMode] = useState({
    email: "",
    phone: "",
  });
  const [profileImage, setProfileImage] = useState();
  const updateProfile = () => {
    console.log("hii");
    const id = toast.loading("Please wait...");
    var url = APIURL() + "user-update";
    let data = new FormData();
    if (profileImage) data.append("image", profileImage, profileImage.name);
    data.append("name", userDetails.name);
    data.append("email", userDetails.email);
    data.append("date", userDetails.date);
    data.append("phone_no", userDetails.phone);
    data.append("username", userDetails.username);
    data.append("age", userDetails.age);
    data.append("landmark", userDetails.landmark);
    data.append("city", userDetails.city);
    data.append("state", userDetails.state);
    data.append("user_id", userDetails.id);

    axios
      .post(url, data, {
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          Authorization: "Bearer NOPLAN@12345!",
          "Content-Type": `multipart/form-data;`,
        },
      })
      .then((response) => {
        if (response.data[0].response.status == "success") {
          toast.update(id, {
            render: "Profile updated Succesfully",
            type: "success",
            isLoading: false,
            autoClose: 3000,
            theme: "dark",
          });
          setUserDetails({
            ...response.data[0].response.data,
            profile: response.data[0].response.image,
            date: response.data[0].response.data.dob.split(" ")[0],
          });
          localStorage.setItem(
            "userDetails",
            JSON.stringify({
              ...response.data[0].response.data,
              profile: response.data[0].response.image,
            })
          );
          // console.log(response.data[0].response.data);
        } else {
          toast.update(id, {
            render: "There was some error, please try again",
            type: "error",
            isLoading: false,
            autoClose: 3000,
            theme: "dark",
          });
        }
      })
      .catch((error) => {
        //handle error
      });
  };
  const handleImageChange = (event) => {
    setProfileImage(event.target.files[0]);
  };
  console.log("userDetails", userDetails);
  return (
    <>
      <ToastContainer />
      <section className="banner-sec">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 mx-auto">
              <div className="driver-dashboard-page">
                <div className="row">
                  <div className="col-lg-5 col-md-5">
                    <div className="profile-icon-pic">
                      <div className="pf-icon-img">
                        <img
                          src={
                            userDetails && userDetails.profile
                              ? userDetails.profile
                              : "images/avatar-2.jpg"
                          }
                          alt="avatar2"
                        />
                        <i
                          onClick={(e) => ref.current.click()}
                          className="fa fa-upload upload_icon"
                          aria-hidden="true"
                        ></i>
                        <input
                          id="myInput"
                          type="file"
                          ref={ref}
                          style={{ display: "none" }}
                          onChange={(e) => console.log(e.target.value)}
                          accept="image/*"
                          onChange={handleImageChange}
                        />
                      </div>
                      <div className="pf-text">
                        <h4>{userDetails.name}</h4>
                        <span>{userDetails.city}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-7 col-md-7">
                    <div className="black-box">
                      <div className="raise-ticket">
                        <div className="raise-title">
                          <p>Account Details</p>
                        </div>
                      </div>
                      <div className="spacer1px" />
                      <div className="acc-data">
                        <div className="ac-title">
                          <ul>
                            <li>
                              <div className="acc-text">
                                <span>Email Address : </span>
                                <a href="#">
                                  <input
                                    className="edit_profile"
                                    name="email"
                                    type="text"
                                    value={userDetails.email}
                                    onChange={handleChange}
                                    disabled={
                                      editMode.email == true ? false : true
                                    }
                                  />
                                </a>
                              </div>
                              <div className="acc-icon">
                                <a href="#" className="che-icon">
                                  <i className="fal fa-badge-check" />
                                </a>
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) =>
                                    setEditMode({
                                      ...editMode,
                                      email: !editMode.email,
                                    })
                                  }
                                  className="ed-icon"
                                >
                                  <i className="fal fa-edit" />
                                </a>
                              </div>
                            </li>
                            <li>
                              <div className="acc-text">
                                <span>Phone Number : </span>
                                <a href="#">
                                  <input
                                    className="edit_profile"
                                    name="phone"
                                    type="number"
                                    value={userDetails.phone}
                                    onChange={handleChange}
                                    disabled={
                                      editMode.phone == true ? false : true
                                    }
                                  />
                                </a>
                              </div>
                              <div className="acc-icon">
                                <a href="#" className="che-icon">
                                  <i className="fal fa-badge-check" />
                                </a>
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) =>
                                    setEditMode({
                                      ...editMode,
                                      phone: !editMode.phone,
                                    })
                                  }
                                  className="ed-icon"
                                >
                                  <i className="fal fa-edit" />
                                </a>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="black-box">
                      <div className="raise-ticket">
                        <div className="raise-title">
                          <p>Personal Details</p>
                        </div>
                      </div>
                      <div className="spacer1px" />
                      <div className="acc-data raise-form">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="mb-3">
                              <label htmlFor className="form-label">
                                Name
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="name"
                                value={userDetails.name}
                                onChange={(e) => handleChange(e)}
                                placeholder="Enter Your Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="mb-3">
                              <label htmlFor className="form-label">
                                Birthday
                              </label>
                              <div className="date-pick">
                                <input
                                  className="form-control"
                                  type="date"
                                  id="datepicker"
                                  name="date"
                                  value={userDetails.date}
                                  onChange={(e) => handleChange(e)}
                                  placeholder="Enter Your Name"
                                />
                                <i
                                  style={{ pointerEvents: "none" }}
                                  className="fal fa-calendar-alt"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="mb-3">
                              <label htmlFor className="form-label">
                                Landmark
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="landmark"
                                value={userDetails.landmark}
                                onChange={(e) => handleChange(e)}
                                placeholder="Enter Your Landmark"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="mb-3">
                              <label htmlFor className="form-label">
                                Town/City
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="city"
                                value={userDetails.city}
                                onChange={(e) => handleChange(e)}
                                placeholder="Enter Your City"
                              />
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="mb-3">
                              <label htmlFor className="form-label">
                                State
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="state"
                                value={userDetails.state}
                                onChange={(e) => handleChange(e)}
                                placeholder="Enter Your State"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="mb-3">
                              <a
                                style={{ cursor: "pointer" }}
                                onClick={updateProfile}
                                className="btn-one"
                              >
                                SAVE
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Body;
