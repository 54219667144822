import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { APIURL } from "../../Methods/Fetch";
import { axiosPost } from "../../Methods/Save";
import { BlankValidation, emailOnly } from "../../Methods/ValidateForms";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

function Body() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [userDetails, setUserDetails] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({
    email: "blank",
    password: "blank",
  });
  const handleChange = (e) => {
    const { name, value, id } = e.target;
    setUserDetails({ ...userDetails, [name]: value });
    if (value.length == 0) {
      setErrors({ ...errors, [name]: `${id} cannot be empty` });
    } else {
      setErrors({ ...errors, [name]: "" });
    }
  };

  const login = () => {
    const id = toast.loading("Please wait...");
    var data = { ...userDetails };
    var url = APIURL() + "driverlogin";
    axiosPost(url, data)
      .then((res) => {
        localStorage.setItem(
          "driverDetails",
          JSON.stringify({ ...res.data.driver_data, id: res.data.user_id })
        );
        localStorage.setItem("driverId", JSON.stringify(res.data.user_id));
        localStorage.removeItem("userId");
        localStorage.removeItem("userDetails");
        toast.update(id, {
          render: res.data.message,
          type: "success",
          isLoading: false,
          autoClose: 3000,
          theme: "dark",
        });
        navigate("/driver-dashboard");
      })
      .catch((err) => {
        var message = "";
        if (err.response.data.email) {
          setErrors({ ...errors, email: err.response.data.email });
          message = err.response.data.email;
        }
        if (err.response.data.password) {
          setErrors({ ...errors, password: err.response.data.password });
          message = err.response.data.password;
        }
        toast.update(id, {
          render: message,
          type: "error",
          isLoading: false,
          autoClose: 3000,
          theme: "dark",
        });
      });
  };
  const validate = () => {
    const result = BlankValidation(userDetails, login, errors, setErrors);
    if (result == false)
      toast.error("Please fill all the mandatory fields!", {
        position: toast.POSITION.TOP_RIGHT,
        theme: "dark",
      });
  };

  return (
    <>
      <ToastContainer />
      <main className="d-flex">
        <div className="main-container">
          <div className="row nm-row">
            <div className="col-lg-6 nm-bgi d-none d-lg-flex">
              <div className="overlay">
                <div className="hero-item">
                  <a href="index.html" aria-label="Nimoy">
                    <img src="images/logo-wh.png" alt="Logo" />
                  </a>
                </div>
                <div className="hero-item hero-item-1">
                  <h2>Some Title Goes Here</h2>
                  <h2>Some Title Goes Here</h2>
                </div>
              </div>
            </div>
            <div className="col-lg-6 nm-mb-1 nm-mb-md-1 nm-aic">
              <div className="card register_uma">
                <div className="card-content">
                  <div className="header">
                    <h4>Driver Login</h4>
                  </div>
                  <div className="form-group">
                    <div className="input-group nm-gp">
                      <span className="nm-gp-pp">
                        <i className="fas fa-envelope-open" />
                      </span>
                      <input
                        id="Email"
                        className={
                          errors.email === "blank"
                            ? "form-control"
                            : errors.email == ""
                            ? "form-control is-valid"
                            : "form-control is-invalid"
                        }
                        type="email"
                        tabIndex={1}
                        name="email"
                        value={userDetails.email}
                        onChange={(e) =>
                          emailOnly(
                            e,
                            setUserDetails,
                            userDetails,
                            setErrors,
                            errors
                          )
                        }
                        placeholder="Enter Your Email"
                        required
                      />
                      <div class="invalid-feedback">{errors.email}</div>
                    </div>
                  </div>
                  <div className="form-group mb-1">
                    <div className="input-group nm-gp">
                      <span className="nm-gp-pp">
                        <i className="fas fa-lock" />
                      </span>
                      <input
                        id="Password"
                        className={
                          errors.password === "blank"
                            ? "form-control"
                            : errors.password == ""
                            ? "form-control is-valid"
                            : "form-control is-invalid"
                        }
                        type={showPassword ? "text" : "password"}
                        tabIndex={2}
                        name="password"
                        value={userDetails.password}
                        onChange={(e) => handleChange(e)}
                        placeholder="Enter your Password"
                        required
                      />
                      {showPassword ? (
                        <VisibilityIcon
                          onClick={(e) => setShowPassword(false)}
                          style={{
                            position: "absolute",
                            right: "13px",
                            top: "11px",
                            cursor: "pointer",
                            zIndex: "99999",
                          }}
                        />
                      ) : (
                        <VisibilityOffIcon
                          onClick={(e) => setShowPassword(true)}
                          style={{
                            position: "absolute",
                            right: "13px",
                            top: "11px",
                            cursor: "pointer",
                            zIndex: "99999",
                          }}
                        />
                      )}{" "}
                      <div class="invalid-feedback">{errors.password}</div>
                    </div>
                  </div>
                  <div className="d-flex nm-jcb nm-mb-1 nm-mt-1 justify-content-end">
                    <Link className="forgotpw" to="/forgot-password-driver">
                      Forgot password?
                    </Link>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-block btn-primary nm-btn"
                    onClick={(e) => validate()}
                  >
                    Log In
                  </button>
                </div>
                <p className="text-center nm-ft-b nm-wh mb-0 noacnt">
                  No account yet ? <Link to="/become-a-driver">Signup</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Body;
