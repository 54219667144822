import React from "react";
import Icons from "./Icons";
import VideoScreen from "./VideoScreen";
import DateTime from "./DateTime";
import RestrauntAndTimeScreen from "./RestrauntAndTimeScreen";
import WardrobeScreen from "./WardrobeScreen";

function PartOne({
  userDetails,
  players,
  toggleDateModal,
  editMode,
  event,
  changeEvent,
  events,
  eventsObj,
  setEditMode,
  hotel,
  changeHotel,
  hotels,
  date,
  time,
  reset,
  saveChanges,
  verify,
  screen,
  setScreen,
  hotelsObj
}) {
  return (
    <div className="step3-body">
      <div>
        <div className="profile-cr">
          <div className="profile-title-flex">
            <div className="pro-img">
              <img src="images/avatar-1.jpg" alt="asd" />
            </div>
            <div className="pro-title">
              <h4>{userDetails.name}</h4>
              <span>Player :{players}P</span>
            </div>
            <Icons
              screen={screen}
              setScreen={setScreen}
              toggleDateModal={toggleDateModal}
            />
          </div>
        </div>
        {screen == 0 || screen == 4 ? (
          <DateTime
            toggleDateModal={toggleDateModal}
            editMode={editMode}
            event={event}
            changeEvent={changeEvent}
            events={events}
            eventsObj={eventsObj}
            setEditMode={setEditMode}
            hotel={hotel}
            changeHotel={changeHotel}
            hotels={hotels}
            date={date}
            time={time}
            reset={reset}
            saveChanges={saveChanges}
            verify={verify}
            hotelsObj={hotelsObj}
          />
        ) : screen == 1 ? (
          <VideoScreen />
        ) : screen == 5 ? (
          <RestrauntAndTimeScreen
            event={event}
            hotels={hotels}
            date={date}
            time={time}
          />
        ) : screen == 2 ? (
          <WardrobeScreen setScreen={setScreen} />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default PartOne;
