import React from "react";
import { Link } from "react-router-dom";

function Body() {
  return (
    <div className="intro-box">
      <video id="myVideo3" muted autoPlay loop>
        <source src="images/intro-vid.mp4" type="video/mp4" />
        Your browser does not support HTML5 video.
      </video>
      <ul>
        <li>
          <Link to="/get-started">- Get Started!</Link>
        </li>
        <li>
          <Link to="/about-us">- About Us</Link>
        </li>
      </ul>
    </div>
  );
}

export default Body;
