import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

function DriverMenus(props) {
  const navigate = useNavigate();

  const { driverDetails } = props;
  const driverlogout = () => {
    localStorage.removeItem("driverDetails");
    localStorage.removeItem("driverId");
    navigate("/");
  };
  const [openMenu, setOpenMenu] = useState(false);
  return (
    <>
      <div className="prof_img">
        <div className="dropdown">
          <a
            className={openMenu ? "list_btn show" : "list_btn"}
            role="button"
            id="dropdownMenuLink2"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            onClick={(e) => setOpenMenu(!openMenu)}
          >
            <img
              src={
                driverDetails && driverDetails.profile
                  ? driverDetails.profile
                  : "images/avatar-1.jpg"
              }
              alt
              title
            />
          </a>
          <div
            className={
              openMenu
                ? "dropdown-menu new_drop_cont show"
                : "dropdown-menu new_drop_cont"
            }
            aria-labelledby="dropdownMenuLink2"
            style={{}}
          >
            <ul>
              <li className="dropdown-item">
                <NavLink to="/driver-settings" activeClassName="active">
                  <i className="fas fa-male" />
                  Edit Profile
                </NavLink>
              </li>
              <li className="dropdown-item">
                <NavLink to="/driver-dashboard" activeClassName="active">
                  <i className="fas fa-cogs" />
                  Driver Dashboard
                </NavLink>
              </li>
              <li className="dropdown-item">
                <NavLink to="/change-password-driver" activeClassName="active">
                  <i className="fas fa-key" />
                  Change Password
                </NavLink>
              </li>
              <li className="dropdown-item">
                <a onClick={driverlogout} style={{ cursor: "pointer" }}>
                  <i className="fas fa-power-off" />
                  Sign Out
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default DriverMenus;
