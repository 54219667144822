import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { APIURL } from "../../Methods/Fetch";
import { axiosGet } from "../../Methods/Save";
import Skeleton from "@mui/material/Skeleton";

function Body() {
  const [pageDetails, setPageDetails] = useState([]);
  useEffect(() => {
    var url = APIURL() + "home";
    axiosGet(url).then((response) => {
      console.log(response);
      setPageDetails(response.data[0].response.data);
    });
  }, []);
  return (
    <>
      <div className="desktop-sec">
        <section className="banner-sec">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 mx-auto">
                <Link to="/step-one">
                  <div className="top-img">
                    <div className="presslogo-link">
                      <Link to="/step-one">
                        <img src="images/blank.png" alt="blank" />
                      </Link>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="mobile-sec">
        <Link to="/step-one">
          <div className="mob-img-box">
            <div className="presslogo-link">
              <Link to="/step-one">
                <img src="images/blank.png" alt="gifimage" />
              </Link>
            </div>
            {pageDetails.mobile_banner ? (
              <img src={pageDetails.mobile_banner} alt="gifimage" />
            ) : (
              <Skeleton
                className="fullwidth_skeleton"
                sx={{ bgcolor: "#ffffff61" }}
                variant="rectangular"
                height={400}
              />
            )}
          </div>
        </Link>
      </div>
    </>
  );
}

export default Body;
