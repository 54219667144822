import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";

function EventModal(props) {
  const { modal, toggle, selectedEvent } = props;
  console.log(selectedEvent);
  return (
    <>
      <Modal
        centered
        className="modal-new-box events_modal_map"
        show={modal}
        onHide={toggle}
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title style={{ fontSize: "17px", margin: "0px" }}>
            {selectedEvent.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul >
            <li>Booking id : {selectedEvent.booking_no}</li>
            <li>
              Start :{" "}
              {selectedEvent &&
                selectedEvent.start &&
                selectedEvent.start.toLocaleString()}
            </li>
            <li>
              End :{" "}
              {selectedEvent &&
                selectedEvent.end &&
                selectedEvent.end.toLocaleString()}
            </li>
            <li>Restraunt :{selectedEvent.resturant}</li>
            <li>User Name :{selectedEvent.username}</li>
            <li>Price :{selectedEvent.price}</li>
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            class="btn btn-danger"
            onClick={(e) => toggle()}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default EventModal;
