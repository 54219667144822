import React, { useEffect, useState } from "react";
import { APIURL } from "../../Methods/Fetch";
import { axiosGet } from "../../Methods/Save";
import SyncLoader from "react-spinners/SyncLoader";

function Body() {
  //   const [value, setValue] = React.useState(0);

  //   const handleChange = (event, newValue) => {
  //     setValue(newValue);
  //   };
  const [pageDetails, setPageDetails] = useState([]);
  const [faqTypes, setFaqTypes] = useState([]);
  const [selectedFaqTypes, setSelectedFaqTypes] = useState();
  useEffect(() => {
    setLoading(true);
    var url = "";
    selectedFaqTypes
      ? (url = APIURL() + `faq/${selectedFaqTypes}`)
      : (url = APIURL() + `faq`);
    axiosGet(url).then((response) => {
      setLoading(false);
      setPageDetails(response.data.faq);
      setFaqTypes(response.data.faq_types);
    });
  }, [selectedFaqTypes]);
  const changeSelectedFaqTypes = (id) => {
    if (id !== selectedFaqTypes) setSelectedFaqTypes(id);
    else setSelectedFaqTypes();
  };
  const [loading, setLoading] = useState(false);
  return (
    <>
      <SyncLoader
        loading={loading}
        className="react-loader-new"
        color="#9a13d2"
      />
      <div className="qustionanswer">
        <div className="row">
          <div className="col-lg-12">
            <h3 className="title">FAQ</h3>
          </div>
          <div className="col-lg-12">
            <div className="question-ans-tab">
              {faqTypes &&
                faqTypes.length > 0 &&
                faqTypes.map((faqType) => (
                  <a
                    href="#!"
                    className={faqType.id == selectedFaqTypes ? "active" : ""}
                    onClick={(e) => changeSelectedFaqTypes(faqType.id)}
                  >
                    <div className="question-ans-tabbox">
                      <i className={faqType.icon} />
                      {faqType.name}
                    </div>
                  </a>
                ))}
            </div>
          </div>
        </div>
      </div>
      <div className="question-answer-accordian">
        <div className="row">
          <div className="col-lg-12">
            <div class="tab-content">
              {pageDetails &&
                pageDetails.length > 0 &&
                pageDetails.map((pd, index) => (
                  <div
                    class="accordion accordion-flush"
                    id="accordionFlushExample"
                    key={index}
                  >
                    <div class="accordion-item">
                      <h2 class="accordion-header" id={`flush-heading${index}`}>
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#flush-collapse${index}`}
                          aria-expanded="false"
                          aria-controls={`flush-collapse${index}`}
                        >
                          {pd.faq_title}
                        </button>
                      </h2>
                      <div
                        id={`flush-collapse${index}`}
                        class="accordion-collapse collapse"
                        aria-labelledby={`flush-heading${index}`}
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div
                          class="accordion-body"
                          dangerouslySetInnerHTML={{ __html: pd.faq_desc }}
                        ></div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Body;
