import React, { useState } from "react";
import {
  alphaBetsOnly,
  BlankValidation,
  emailOnly,
} from "../../Methods/ValidateForms";
import { ToastContainer, toast } from "react-toastify";
import { APIURL } from "../../Methods/Fetch";
import { axiosPost } from "../../Methods/Save";
import { useNavigate } from "react-router-dom";

function Body() {
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState({
    name: "",
    email: "",
    phone_number: "",
    home_address: "",
  });
  const [errors, setErrors] = useState({
    name: "blank",
    email: "blank",
    phone_number: "blank",
    home_address: "blank",
  });
  const handleChange = (e) => {
    const { name, value, id } = e.target;
    setUserDetails({ ...userDetails, [name]: value });
    if (value.length == 0) {
      setErrors({ ...errors, [name]: `${id} cannot be empty` });
    } else {
      setErrors({ ...errors, [name]: "" });
    }
  };
  const validate = () => {
    const result = BlankValidation(userDetails, register, errors, setErrors);
    if (result == false)
      toast.error("Please fill all the mandatory fields!", {
        position: toast.POSITION.TOP_RIGHT,
        theme: "dark",
      });
  };
  const register = () => {
    const id = toast.loading("Please wait...");
    var data = { ...userDetails };
    var url = APIURL() + "editor/store";
    axiosPost(url, data)
      .then((res) => {
        console.log(res);
        toast.update(id, {
          render: res.data[0].response.msg,
          type: "success",
          isLoading: false,
          autoClose: 3000,
          theme: "dark",
        });
        setTimeout(() => {
          navigate("/");
        }, 1000);
      })
      .catch((err) => {
        // if (err.response.data.errors.email) {
        //   setErrors({ ...errors, email: err.response.data.errors.email[0] });
        // }
        toast.update(id, {
          render: "There was some error",
          type: "error",
          isLoading: false,
          autoClose: 3000,
          theme: "dark",
        });
      });
  };
  return (
    <>
      {" "}
      <ToastContainer />
      <div className="bodywork-sec">
        <div className="row align-items-center">
          <div className="row jobs-listing">
            <div className="col-lg-12">
              <div className="jobs-listing-box noneshadow careerdtls">
                <h4>Editor</h4>
                <p>
                  Managing and creating content for a blog or group of blogs.
                  This includes researching and writing articles, editing and
                  proofreading content, and coordinating with other team members
                  and contributors. The editor must also be familiar with SEO
                  best practices and able to promote the blog on social media
                  and other platforms.
                </p>
                <h5>Apply Now</h5>
                <div className="row">
                  <div className="col-lg-6 driver-form">
                    <label>
                      Name <span>*</span>
                    </label>
                    <input
                      id="Name"
                      className={
                        errors.name === "blank"
                          ? "form-control"
                          : errors.name == ""
                          ? "form-control is-valid"
                          : "form-control is-invalid"
                      }
                      type="text"
                      tabIndex={1}
                      name="name"
                      value={userDetails.name}
                      onChange={(e) =>
                        alphaBetsOnly(
                          e,
                          setUserDetails,
                          userDetails,
                          setErrors,
                          errors
                        )
                      }
                      placeholder="Enter Your Name"
                      required
                    />
                    <div class="invalid-feedback">{errors.name}</div>
                  </div>
                  <div className="col-lg-6 driver-form">
                    <label>
                      Email <span>*</span>
                    </label>
                    <input
                      id="Email"
                      className={
                        errors.email === "blank"
                          ? "form-control"
                          : errors.email == ""
                          ? "form-control is-valid"
                          : "form-control is-invalid"
                      }
                      type="email"
                      tabIndex={1}
                      name="email"
                      value={userDetails.email}
                      onChange={(e) =>
                        emailOnly(
                          e,
                          setUserDetails,
                          userDetails,
                          setErrors,
                          errors
                        )
                      }
                      placeholder="Enter Your Email"
                      required
                    />
                    <div class="invalid-feedback">{errors.email}</div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 driver-form">
                    <label>
                      Phone Number <span>*</span>
                    </label>
                    <input
                      id="Phone Number"
                      className={
                        errors.phone_number === "blank"
                          ? "form-control"
                          : errors.phone_number == ""
                          ? "form-control is-valid"
                          : "form-control is-invalid"
                      }
                      type="number"
                      tabIndex={1}
                      name="phone_number"
                      value={userDetails.phone_number}
                      onChange={(e) => handleChange(e)}
                      placeholder="Enter Your Phone Number"
                      required
                      onWheel={(event) => event.currentTarget.blur()}
                    />
                    <div class="invalid-feedback">{errors.phone_number}</div>
                  </div>
                  <div className="col-lg-6 driver-form">
                    <label>
                      Home Address <span>*</span>
                    </label>
                    <input
                      id="Home Address"
                      className={
                        errors.home_address === "blank"
                          ? "form-control"
                          : errors.home_address == ""
                          ? "form-control is-valid"
                          : "form-control is-invalid"
                      }
                      type="text"
                      tabIndex={1}
                      name="home_address"
                      value={userDetails.home_address}
                      onChange={(e) => handleChange(e)}
                      placeholder="Enter Your Home Address"
                      required
                    />
                    <div class="invalid-feedback">{errors.home_address}</div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 driver-form">
                    <button
                      onClick={validate}
                      type="button"
                      className="btn-style-one "
                    >
                      Send
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Body;
