import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import DateAndTime from "../StepThree/DateAndTime";

function DateAndTimeModal(props) {
  const {
    dateModal,
    toggleDateModal,
    selectedDay,
    setSelectedDay,
    selectedTime,
    setSelectedTime,
    setTime,
    setLoading,
    date,
    setDate,
    callEvents,
    setScreen,
  } = props;
  console.log("selectedDay", selectedDay);

  return (
    <Modal
      size="xl"
      className="full-width-modal dateModalStepSeven"
      isOpen={dateModal}
      toggle={toggleDateModal}
    >
      <ModalHeader>
        <h4 style={{ fontSize: "17px", margin: "0px" }}>Date and time</h4>
      </ModalHeader>
      <ModalBody>
        <DateAndTime
          selectedDay={selectedDay}
          setSelectedDay={setSelectedDay}
          selectedTime={selectedTime}
          setSelectedTime={setSelectedTime}
          setTime={setTime}
          setLoading={setLoading}
          date={date}
          setDate={setDate}
          setScreen={setScreen}
        />
      </ModalBody>
      <ModalFooter>
        <button
          onClick={(e) => {
            toggleDateModal();
            setScreen && setScreen(0);
          }}
          type="button"
          class="btn btn-danger"
        >
          Close
        </button>
        <button
          onClick={(e) => {
            callEvents();
            setScreen && setScreen(4);
          }}
          type="button"
          class="btn btn-success"
        >
          Save
        </button>
      </ModalFooter>
    </Modal>
  );
}

export default DateAndTimeModal;
