import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { APIURL } from "../../../Methods/Fetch";
import { axiosPost } from "../../../Methods/Save";
import { ToastContainer, toast } from "react-toastify";
import SyncLoader from "react-spinners/SyncLoader";

function Body() {
  const navigate = useNavigate();
  const [time, setTime] = useState(localStorage.getItem("time"));
  const [date, setDate] = useState(localStorage.getItem("date"));
  const [events, setEvents] = useState();
  const [loading, setLoading] = useState(false);
  // const [selectedEvents, setSelectedEvents] = useState(JSON.parse(localStorage.getItem("events")));
  const [selectedEvents, setSelectedEvents] = useState(
    JSON.parse(localStorage.getItem("eventsObj"))
  );
  useEffect(() => {
    setLoading(true);
    var url = APIURL() + "events";
    var data = {
      event_time: time,
      event_date: date,
    };
    axiosPost(url, data).then((response) => {
      setLoading(false);
      setEvents(response.data[0].response.data);
      if (response.data[0].response.data.length == 0) {
        toast.error("No events available for the following date and time !", {
          position: toast.POSITION.TOP_RIGHT,
          theme: "dark",
        });
      }
    });
  }, [time, date]);
  const selectEvent = (event) => {
    // if (selectedEvents.includes(event)) {
    //   setSelectedEvents((selectedEvents) =>
    //     selectedEvents.filter((selected_id) => selected_id !== id)
    //   );
    // } else setSelectedEvents((oldArray) => [...selectedEvents, id]);
    setSelectedEvents(event);
  };
  console.log(selectedEvents);
  const saveInLocal = () => {
    if (Object.keys(selectedEvents).length > 0) {
      localStorage.setItem("events", selectedEvents.name);
      localStorage.setItem("eventsObj", JSON.stringify(selectedEvents));
      navigate("/step-five");
    } else {
      toast.error("Please select an event !", {
        position: toast.POSITION.TOP_RIGHT,
        theme: "dark",
      });
    }
  };

  return (
    <>
      <SyncLoader
        loading={loading}
        className="react-loader-new"
        color="#9a13d2"
      />
      <ToastContainer />
      <div className="desktop-sec">
        <section className="banner-sec">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 mx-auto">
                <div className="top-img">
                  <div className="step-hashtag">
                    <ul>
                      {events && events.length > 0
                        ? events.map((event) => (
                            <li
                              className={
                                selectedEvents.id == event.id ? "selected" : ""
                              }
                            >
                              <a href="#!" onClick={(e) => selectEvent(event)}>
                                #{event.name}
                                {selectedEvents.id == event.id && (
                                  <span>£ {event.price}</span>
                                )}
                              </a>
                            </li>
                          ))
                        : ""}
                    </ul>
                    <div className="step4-playerchoose">
                      <p>
                        Uncover the essence: Choose the defining element or
                        keyword that will shape your exceptional event.
                      </p>
                    </div>
                  </div>

                  <div class="scene-prevbtn">
                    <Link to="/step-three">
                      <span>Back</span>
                    </Link>
                  </div>
                  <div className="scene-nextbtn">
                    <a style={{ cursor: "pointer" }} onClick={saveInLocal}>
                      <span> Next</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="mobile-sec ">
        <div className="mob-img-box step4">
          <div className="step-hashtag">
            <ul>
              {events && events.length > 0
                ? events.map((event) => (
                    <li
                      className={
                        selectedEvents.id == event.id ? "selected" : ""
                      }
                    >
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={(e) => selectEvent(event)}
                      >
                        #{event.name}
                      </a>
                    </li>
                  ))
                : ""}
            </ul>
          </div>
          <div class="scene-prevbtn">
            <Link to="/step-three">
              <span>Back</span>
            </Link>
          </div>
          <div className="scene-nextbtn">
            <a style={{ cursor: "pointer" }} onClick={saveInLocal}>
              <span>Next</span>
            </a>
          </div>
          <img src="images/new5.gif" alt />
        </div>
      </div>
    </>
  );
}

export default Body;
