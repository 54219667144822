import React from "react";

function RestrauntAndTimeScreen({ event, hotels, date, time }) {
  return (
    <div className="book-data2">
      <div className="row">
        <div className="col-lg-12">
          <div className="event-title">
            <h4>Review Menu</h4>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4 reviewMenuHeading">Event</div>
        <div className="col-lg-4 reviewMenuHeading">Restaurant</div>
        <div className="col-lg-4 reviewMenuHeading">Date and Time</div>
      </div>
      <div className="row mt-3">
        <div className="col-lg-4 reviewMenuHeading">{event}</div>
        <div className="col-lg-4 reviewMenuHeading">
          {hotels[0]?.resturant_name}
        </div>
        <div className="col-lg-4 reviewMenuHeading">
          {date}
          <br></br> {time}
        </div>
      </div>
    </div>
  );
}

export default RestrauntAndTimeScreen;
