import React, { useEffect, useState } from "react";
import { APIURL } from "../../Methods/Fetch";
import { axiosGet } from "../../Methods/Save";
import SyncLoader from "react-spinners/SyncLoader";

function Body() {
  const [pageDetails, setPageDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    var url = APIURL() + "fetch-terms";
    axiosGet(url).then((response) => {
      setPageDetails(response.data[0].response.data);
      setLoading(false);
    });
  }, []);
  console.log("pageDetails", pageDetails);
  return (
    <>
      <SyncLoader
        loading={loading}
        className="react-loader-new"
        color="#9a13d2"
      />
      <div className="question-answer-accordian">
        <div className="row">
          <div className="col-lg-10 mx-auto">
            <h3 className="title">Terms & Conditions</h3>
          </div>
          <div className="col-lg-10 mx-auto pt-4 pb-3">
            <div
              className="accordion accordion-flush"
              id="accordionFlushExample"
            >
              {pageDetails && pageDetails.length > 0
                ? pageDetails.map((pd, index) => (
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id={`flush-heading${index}`}
                      >
                        <button
                          className={
                            index == 0
                              ? "accordion-button"
                              : "accordion-button collapsed"
                          }
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#flush-collapse${index}`}
                          aria-expanded="false"
                          aria-controls={`flush-collapse${index}`}
                        >
                          {pd.title}
                        </button>
                      </h2>
                      <div
                        id={`flush-collapse${index}`}
                        className={
                          index == 0
                            ? "accordion-collapse show"
                            : "accordion-collapse collapse"
                        }
                        aria-labelledby={`flush-heading${index}`}
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div
                          className="accordion-body"
                          dangerouslySetInnerHTML={{ __html: pd.description }}
                        ></div>
                      </div>
                    </div>
                  ))
                : ""}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Body;
