import React from "react";
import { useState } from "react";
import { emailOnly } from "../../Methods/ValidateForms";

function NewsLetter() {
  const [userDetails, setUserDetails] = useState({
    EMAIL: "",
  });
  const [errors, setErrors] = useState({
    EMAIL: "blank",
  });
  const submitForm = (e) => {
    if (errors.EMAIL != "") {
      e.preventDefault();
      setErrors({ ...errors, email: "Must be a valid E-Email" });
    }
  };
  return (
    <>
      <div className="email-newsletter">
        <div class="mb-3">
          <label for="" class="form-label">
            Subscribe to our newsletter
          </label>
          <div id="mc_embed_signup">
            <form
              action="https://noplan-london.us11.list-manage.com/subscribe/post?u=5734ca1e8d52ca061254d8cc3&amp;id=55cf85f9bb&amp;f_id=00179de0f0"
              method="post"
              id="mc-embedded-subscribe-form"
              name="mc-embedded-subscribe-form"
              className="validate"
              target="_blank"
              noValidate
            >
              <div id="mc_embed_signup_scroll">
                <div className="mc-field-group">
                  <div className="news-input-box">
                    <input
                      type="email"
                      name="EMAIL"
                      className={
                        errors.EMAIL === "blank"
                          ? "form-control required email form-control"
                          : errors.EMAIL == ""
                          ? "required email form-control form-control is-valid"
                          : "required email form-control form-control is-invalid"
                      }
                      id="mce-EMAIL"
                      required
                      value={userDetails.EMAIL}
                      onChange={(e) =>
                        emailOnly(
                          e,
                          setUserDetails,
                          userDetails,
                          setErrors,
                          errors
                        )
                      }
                    />
                    <div class="invalid-feedback">{errors.EMAIL}</div>

                    <input
                      type="submit"
                      onClick={(e) => submitForm(e)}
                      defaultValue="Subscribe"
                      name="subscribe"
                      id="mc-embedded-subscribe"
                      className="button"
                    />
                  </div>
                  <span id="mce-EMAIL-HELPERTEXT" className="helper_text" />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewsLetter;
