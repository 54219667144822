import { React, useEffect, useState } from "react";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import Icons from "../Common/Icons";
import Body from "../Components/DriverRaiseTickets/Body";

function DriverRaiseTicket(props) {
  const { setBackGroundColor } = props;
  useEffect(() => {
    setBackGroundColor("bg-texture-gradient gradient2");
  }, []);
  const [driverDetails, setDriverDetails] = useState(
    JSON.parse(localStorage.getItem("driverDetails"))
  );
  return (
    <>      
      <Header dashBoard={true} driverDetails={driverDetails} driver={true}/>
      <Icons />
      <div className="box-margin">
        <Body driverDetails={driverDetails}/>
        {/* <Footer className="border-top" /> */}
      </div>
    </>
  );
}

export default DriverRaiseTicket;
