import { Skeleton } from "@mui/material";
import { React, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { APIURL } from "../../Methods/Fetch";
import { axiosGet } from "../../Methods/Save";

function Body() {
  const [pageDetails, setPageDetails] = useState([]);
  useEffect(() => {
    var url = APIURL() + "privacy-page-details";
    axiosGet(url).then((response) => {
      console.log(response);
      setPageDetails(response.data[0].response.data);
    });
  }, []);
  return (
    <>
      <div className="contact-sec">
        <div className="row">         
          <div className="col-lg-12 what-we-do-content">
            <h4>{pageDetails ? pageDetails.title : ""}</h4>
            <div
              dangerouslySetInnerHTML={{
                __html: pageDetails ? pageDetails.description : "",
              }}
            ></div>            
          </div>
        </div>
      </div>
    </>
  );
}

export default Body;
