import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

function Icons({ toggleDateModal, screen, setScreen }) {
  const navigate = useNavigate();
  const goTo = (url) => {
    navigate(url);
  };
  const changeScreen = (screenId) => {
    if (screenId === screen) setScreen(0);
    else setScreen(screenId);
  };
  return (
    <div className="pro-icon-boxnn">
      <ul className="step_seven_icons">
        <li className={screen == 1 ? "active" : ""}>
          <a className="btn_anchor" onClick={(e) => changeScreen(1)}>
            <img src="images/steps-btn4.png" alt="asd" />
          </a>
        </li>
        <li className={screen == 2 ? "active" : ""}>
          <a
            onClick={(e) => {
              changeScreen(2);
            }}
            className="btn_anchor"
          >
            <img src="images/steps-btn3.png" alt="asd" />
          </a>
        </li>
        <li className={screen == 3 ? "active" : ""}>
          <a
            onClick={(e) => {
              toggleDateModal();
              changeScreen(3);
            }}
            className="btn_anchor"
          >
            <img src="images/steps-btn1.png" alt="asd" />
          </a>
        </li>
        <li className={screen == 4 ? "active" : ""}>
          <a
            onClick={(e) => {
              changeScreen(4);
            }}
            className="btn_anchor"
          >
            <img src="images/steps-btn2.png" alt="asd" />
          </a>
        </li>

        {/* <li className={screen == 5 ? "active" : ""}>
          <a className="btn_anchor" onClick={(e) => changeScreen(5)}>
            <img src="images/steps-btn5.png" alt="asd" />
          </a>
        </li> */}
      </ul>
    </div>
  );
}

export default Icons;
