import { Skeleton } from "@mui/material";
import { React, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { APIURL } from "../../Methods/Fetch";
import { axiosGet } from "../../Methods/Save";

function Body() {
  const [pageDetails, setPageDetails] = useState([]);
  useEffect(() => {
    var url = APIURL() + "what-we-do";
    axiosGet(url).then((response) => {
      console.log(response);
      setPageDetails(response.data[0].response.data);
    });
  }, []);
  console.log(pageDetails);
  return (
    <>
      <div className="contact-sec">
        <div className="row">
          <div className="col-lg-5">
            {pageDetails.image ? (
              <img src={pageDetails.image} alt="a" />
            ) : (
              <Skeleton
                className="fullwidth_skeleton"
                sx={{ bgcolor: "#ffffff61" }}
                variant="rectangular"
                height={400}
              />
            )}
          </div>
          <div className="col-lg-7 what-we-do-content">
            <h4>{pageDetails ? pageDetails.title : ""}</h4>
            <div
              dangerouslySetInnerHTML={{
                __html: pageDetails ? pageDetails.description : "",
              }}
            ></div>
            <Link
              to={pageDetails ? pageDetails.btn_link : ""}
              className="btn-style-two"
            >
              {pageDetails ? pageDetails.btn_name : ""}
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default Body;
