import React, { useEffect } from "react";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { APIURL } from "../../Methods/Fetch";
import { axiosGet } from "../../Methods/Save";

function MobileMenu(props) {
  const { loggedIn, driverLoggedIn } = props;
  const [pageDetails, setPageDetails] = useState([]);
  useEffect(() => {
    var url = APIURL() + `website-settings`;
    axiosGet(url).then((response) => {
      setPageDetails(response.data.data);
    });
  }, []);
  return (
    <>
      <nav class="pushy pushy-right mobile-sec" data-focus="#first-link">
        <div class="pushy-content">
          <ul>
            <li class="pushy-link">
              <NavLink to="/what-we-do" activeClassName="active">
                WHAT WE DO
              </NavLink>
            </li>
            <li class="pushy-link">
              <NavLink to="/blogs" activeClassName="active">
                BLOG
              </NavLink>
            </li>
            <li class="pushy-link">
              <NavLink to="/contact-us" activeClassName="active">
                CAREER
              </NavLink>
            </li>
            <li class="pushy-link">
              <NavLink to="/about-us" activeClassName="active">
                About us
              </NavLink>
            </li>
            <li class="pushy-link">
              <NavLink to="" activeClassName="active">
                Our Partners
              </NavLink>
            </li>
          </ul>
          {loggedIn ? (
            <div class="menu_contact_details">
              <NavLink to="/dashboard" activeClassName="active">
                Dashboard<i class="fal fa-sign-in-alt"></i>
              </NavLink>
            </div>
          ) : driverLoggedIn ? (
            <div class="menu_contact_details">
              <NavLink to="/driver-dashboard" activeClassName="active">
                Driver Dashboard<i class="fal fa-sign-in-alt"></i>
              </NavLink>
            </div>
          ) : (
            <div>
              <div class="menu_contact_details">
                <NavLink to="/login" activeClassName="active">
                  Login<i class="fal fa-sign-in-alt"></i>
                </NavLink>
              </div>
              <div class="menu_contact_details">
                <NavLink to="/register" activeClassName="active">
                  Sign Up<i class="fal fa-lock-alt"></i>
                </NavLink>
              </div>
            </div>
          )}

          <div class="mobile-social-meida">
            <ul>
              <li>
                <a target="_blank" href={pageDetails.facebook}>
                  <i class="fab fa-facebook-square"></i>
                </a>
              </li>
              <li>
                <a target="_blank" href={pageDetails.instagram}>
                  <i class="fab fa-instagram"></i>
                </a>
              </li>
              <li>
                <a target="_blank" href={pageDetails.tiktok}>
                  <i class="fab fa-tiktok"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default MobileMenu;
