import { Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SyncLoader } from "react-spinners";
import { APIURL } from "../../Methods/Fetch";
import { axiosGet } from "../../Methods/Save";

function Body() {
  const navigate = useNavigate();
  const [pageDetails, setPageDetails] = useState([]);
  const [id, setId] = useState(localStorage.getItem("blogId"));
  const [similarArticles, setSimilarArticles] = useState([]);
  useEffect(() => {
    setLoading(true);
    var url = APIURL() + `blog-details/${id}`;
    axiosGet(url).then((response) => {
      setPageDetails(response.data[0].response.data);
      setSimilarArticles(response.data[0].response.article[0]);
      setLoading(false);
    });
  }, [id]);
  const goTo = (blog) => {
    localStorage.setItem("blogId", blog.id);
    setId(blog.id);
    var para = blog.article_title.toLowerCase().split(" ").join("-");
    navigate(`/blog/${para}`);
  };
  const [loading, setLoading] = useState(false);

  return (
    <>
      <SyncLoader
        loading={loading}
        className="react-loader-new"
        color="#9a13d2"
      />
      <div className="bodywork-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="nk-blog-post nk-blog-post-single">
              <div className="nk-post-text mt-0">
                <h1 className="nk-post-title h4">{pageDetails.banner_title}</h1>
                <div className="nk-post-img">
                  {pageDetails.banner_img ? (
                    <img
                      src={pageDetails.banner_img}
                      alt="Grab your sword and fight the Horde"
                    />
                  ) : (
                    <Skeleton
                      className="fullwidth_skeleton"
                      sx={{ bgcolor: "#ffffff61" }}
                      variant="rectangular"
                      height={400}
                    />
                  )}
                </div>
                <div className="nk-gap-1" />
                <div className="nk-post-by">
                  <img
                    src={
                      pageDetails.author_img
                        ? pageDetails.author_img
                        : "/images/avatar-2.jpg"
                    }
                    alt={pageDetails.author}
                    className="rounded-circle"
                    width={35}
                  />
                  by <a href={pageDetails.author_link}>{pageDetails.author}</a>{" "}
                  in {pageDetails.publish_date}
                  <div className="nk-post-categories">
                    {pageDetails.category &&
                      pageDetails.category.length > 0 &&
                      pageDetails.category.map((cat) => (
                        <span className="bg-main-2">{cat.category_name}</span>
                      ))}
                  </div>
                </div>
                <div className="nk-post-sharesocial">
                  Share:
                  <div className="sharethis-inline-share-buttons"></div>
                </div>
                <div className="nk-gap" />
                <div
                  dangerouslySetInnerHTML={{ __html: pageDetails.banner_desc }}
                ></div>
                <div className="nk-gap" />
                <blockquote className="nk-blockquote">
                  <div className="nk-blockquote-icon">
                    <span>"</span>
                  </div>
                  <div className="nk-blockquote-content">
                    {pageDetails.comment}
                  </div>
                  <div className="nk-blockquote-author">
                    <span>{pageDetails.comment_author}</span>
                  </div>
                </blockquote>
                <div className="nk-gap" />

                <div className="nk-gap" />
              </div>
              <div
                dangerouslySetInnerHTML={{ __html: pageDetails.article_body }}
              ></div>
              <div className="nk-gap-2" />
              {similarArticles ? (
                <div>
                  <h3 className="nk-decorated-h-2">
                    <span>
                      <span className="text-main-1">Similar</span> Articles
                    </span>
                  </h3>
                  <div className="nk-gap" />
                  <div className="row">
                    {similarArticles.map((similar_article) => (
                      <div className="col-md-6">
                        {/* START: Post */}
                        <div className="nk-blog-post">
                          <a
                            onClick={(e) => goTo(similar_article)}
                            className="nk-post-img btn_anchor"
                          >
                            <img
                              src={similar_article.article_image}
                              alt="We found a witch! May we burn her?"
                            />
                            {/*<span className="nk-post-comments-count">7</span>*/}
                            <span className="nk-post-categories">
                              <span className="bg-main-2">
                                {similar_article.article_category}
                              </span>
                            </span>
                          </a>
                          <div className="nk-gap" />
                          <h2 className="nk-post-title h4">
                            <a
                              onClick={(e) => goTo(similar_article)}
                              className="btn_anchor"
                            >
                              {similar_article.article_title}
                            </a>
                          </h2>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <>
                  <h1 style={{ textAlign: "center" }}>No Similar Articles</h1>
                  <Skeleton
                    className="fullwidth_skeleton"
                    sx={{ bgcolor: "#ffffff61" }}
                    variant="rectangular"
                    height={400}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Body;
