import { React, useEffect } from "react";
import Footer from "../../Common/Footer";
import Header from "../../Common/Header";
import Icons from "../../Common/Icons";
import Body from "../../Components/OrderProcess/StepTwo/Body";

function StepTwo(props) {
  const { setBackGroundColor } = props;
  useEffect(() => {
    setBackGroundColor("step3-fullbg");
  }, []);
  return (
    <>
      <Header />
      <Icons />
      <div className="box-margin">
        <Body />
        {/* <Footer className="border-top" /> */}
      </div>
    </>
  );
}

export default StepTwo;
