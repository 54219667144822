import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { APIURL } from "../../Methods/Fetch";
import { axiosGet, axiosPost } from "../../Methods/Save";
import { Modal, ModalBody } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { BlankValidation } from "../../Methods/ValidateForms";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Dropzone from "react-dropzone";
import DeleteIcon from "@mui/icons-material/Delete";
import Menu from "../DriverDashboard/Menu";
import { SyncLoader } from "react-spinners";

function Body(props) {
  const { driverDetails } = props;
  const params = useParams();
  const { id } = params;
  const [img, setImg] = useState();
  const [refresh, setRefresh] = useState(false);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [ticket, setTicket] = useState([]);
  const [ticketLogs, setTicketLogs] = useState([]);

  useEffect(() => {
    if (id) {
      setLoading(true);
      var url = APIURL() + `ticket-details/${id}`;
      axiosGet(url).then((response) => {
        setTicket(response.data[0].response.ticket);
        setTicketLogs(response.data[0].response.data);
        setLoading(false);
      });
    }
  }, [id, refresh]);
  const [ticketDetails, setTicketDetails] = useState({
    description: "",
  });
  const [errors, setErrors] = useState({
    description: "blank",
  });
  const [uploadedImages, setUploadedImages] = useState([]);
  const validate = () => {
    const result = BlankValidation(
      ticketDetails,
      raiseTicket,
      errors,
      setErrors
    );
    if (result == false)
      toast.error("Please fill all the mandatory fields!", {
        position: toast.POSITION.TOP_RIGHT,
        theme: "dark",
      });
  };
  const raiseTicket = () => {
    const id = toast.loading("Please wait...");
    var data = { ...ticketDetails, user_id: driverDetails.id };
    var url = APIURL() + `ticket-reply/${ticket.id}`;
    axiosPost(url, data, "", "", uploadedImages)
      .then((res) => {
        toast.update(id, {
          render: res.data[0].response.msg,
          type: "success",
          isLoading: false,
          autoClose: 3000,
          theme: "dark",
        });
        setUploadedImages([]);
        setTicketDetails({
          description: "",
        });
        setRefresh(!refresh);
        // localStorage.setItem("userId", res.data.data.id);
        // localStorage.setItem("userDetails", JSON.stringify(res.data.data));
        // setTimeout(() => {
        //   navigate("/dashboard");
        // }, 1000);
      })
      .catch((err) => {
        if (err.response.data.errors.email) {
          setErrors({ ...errors, email: err.response.data.errors.email[0] });
        }
        toast.update(id, {
          render: err.response.data.message,
          type: "error",
          isLoading: false,
          autoClose: 3000,
          theme: "dark",
        });
      });
  };
  const getImageFromUpload = (data) => {
    return URL.createObjectURL(data);
  };
  const handleImageRemove = (data) => {
    const filteredPeople = uploadedImages.filter(
      (item) => item.name !== data.name
    );
    setUploadedImages(filteredPeople);
  };
  const [loading, setLoading] = useState(false);
  return (
    <>
      <SyncLoader
        loading={loading}
        className="react-loader-new"
        color="#9a13d2"
      />
      <ToastContainer />
      <section className="banner-sec">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 mx-auto">
              <div className="driver-dashboard-page">
                <Menu driverDetails={driverDetails} />
                <div className="black-box">
                  <div className="raise-ticket">
                    <div className="raise-title">
                      <p>{ticket.subject}</p>
                    </div>
                    <div className="raise-btn-box">
                      <ul>
                        <li>
                          <span>Status : </span>
                          <a href="#" className="open-txt">
                            Open
                          </a>
                        </li>
                        <li>
                          <span>Priority : </span>
                          <a href="#" className="red-txt">
                            High
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="spacer1px" />
                  <div className="raise-form">
                    <div className="row">
                      <div className="col-lg-12 text-end">
                        <p>#{ticket.ticket_no}</p>
                      </div>
                      {ticketLogs &&
                        ticketLogs.length > 0 &&
                        ticketLogs.map((log) => (
                          <div className="col-lg-12">
                            <div className="ticket-view-box">
                              <div className="ticket-title-flex">
                                <div className="tic-title">
                                  <p>
                                    {log.admin_name
                                      ? log.admin_name
                                      : log.user_name}
                                  </p>
                                </div>
                                <div className="tic-time-dt">
                                  <ul>
                                    <li>
                                      <i className="fal fa-clock" /> {log.time}
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="tic-desc">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: log.description,
                                  }}
                                ></div>
                              </div>
                              <div className="user-acth-btn">
                                {log.attachment && log.attachment.length > 0
                                  ? log.attachment.map((att, index) => (
                                      <button
                                        key={index}
                                        onClick={(e) => {
                                          setImg(att.attachment_name);
                                          toggle();
                                        }}
                                        className="atch-btn"
                                      >
                                        <i className="fal fa-paperclip" />
                                        Attachement {index + 1}
                                      </button>
                                    ))
                                  : ""}
                              </div>
                            </div>
                          </div>
                        ))}
                      <div className="raise-form border border-primary">
                        <div className="row">
                          <h3 className="text-center">Reply</h3>
                          <div className="col-lg-12">
                            <div className="mb-3">
                              <label htmlFor>Description</label>
                              <CKEditor
                                editor={ClassicEditor}
                                data=""
                                onReady={(editor) => {
                                  // You can store the "editor" and use when it is needed.
                                }}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  setTicketDetails({
                                    ...ticketDetails,
                                    description: data,
                                  });
                                }}
                                onBlur={(event, editor) => {}}
                                onFocus={(event, editor) => {}}
                              />
                              <div class="invalid-feedback">
                                {errors.description}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12 mb-5">
                            <div
                              className="tab-pane active react-dropzone"
                              id="home"
                              role="tabpanel"
                            >
                              <h5>Add attachments</h5>
                              <Dropzone
                                accept="image/*"
                                onDrop={(acceptedFiles) => {
                                  acceptedFiles.map((res) => {
                                    if (
                                      res.type == "image/jpeg" ||
                                      res.type == "image/jpg" ||
                                      res.type == "image/png"
                                    ) {
                                      setUploadedImages((oldArray) => [
                                        ...oldArray,
                                        res,
                                      ]);
                                    } else {
                                    }
                                  });
                                }}
                              >
                                {({
                                  getRootProps,
                                  getInputProps,
                                  isDragActive,
                                }) => (
                                  <div
                                    {...getRootProps({
                                      className: "dropzone",
                                    })}
                                  >
                                    <input {...getInputProps()} />
                                    {isDragActive ? (
                                      <p>Drop the files here ...</p>
                                    ) : (
                                      <p>
                                        Drag 'n' drop some files here, or click
                                        to select files
                                      </p>
                                    )}
                                  </div>
                                )}
                              </Dropzone>
                              {uploadedImages.length > 0 && (
                                <React.Fragment>
                                  <h4 style={{ marginTop: "20px" }}>
                                    Uploaded Files :
                                  </h4>
                                  <div className="row">
                                    {uploadedImages.map((res) => (
                                      <div className="col-lg-2 col-md-2">
                                        <img
                                          style={{
                                            height: "100px",
                                            width: "100%",
                                          }}
                                          src={getImageFromUpload(res)}
                                          alt="img"
                                        />
                                        <DeleteIcon
                                          onClick={() => handleImageRemove(res)}
                                          style={{
                                            marginTop: "-124%",
                                            marginLeft: "86%",
                                            background: "#fff",
                                            color: "red",
                                            cursor: "pointer",
                                          }}
                                        />
                                      </div>
                                    ))}
                                  </div>
                                </React.Fragment>
                              )}
                            </div>
                          </div>
                          <a onClick={validate} href="#" className="btn-one">
                            Reply
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal className="ticket_modal" isOpen={modal} toggle={toggle}>
        <ModalBody>
          <img src={img} alt="attachment" />
        </ModalBody>
      </Modal>
    </>
  );
}

export default Body;
