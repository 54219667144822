import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { APIURL } from "../../Methods/Fetch";
import { axiosPost } from "../../Methods/Save";
import { BlankValidation, emailOnly } from "../../Methods/ValidateForms";
import axios from "axios";

function Body(props) {
  const { driver } = props;
  const navigate = useNavigate();

  const validate = () => {
    const result = BlankValidation(userDetails, login, errors, setErrors);
    if (result == false)
      toast.error("Please fill all the mandatory fields!", {
        position: toast.POSITION.TOP_RIGHT,
        theme: "dark",
      });
  };
  const [userDetails, setUserDetails] = useState({
    email: "",
  });
  const [errors, setErrors] = useState({
    email: "blank",
  });
  const login = () => {
    const id = toast.loading("Please wait...");
    var data = { ...userDetails };
    var forgotPwUrl = "forgot-password";
    if (driver) forgotPwUrl = "drivers-forgot-password";
    var url = APIURL() + forgotPwUrl;
    axiosPost(url, data)
      .then((res) => {
        console.log(res);
        toast.update(id, {
          render: res.data[0].response.msg,
          type: "success",
          isLoading: false,
          autoClose: 3000,
          theme: "dark",
        });
        // navigate("/");
      })
      .catch((err) => {
        var message = "";
        console.log(err);
        setErrors({ ...errors, email: err.response.data[0].response.msg });
        message = err.response.data[0].response.msg;
        toast.update(id, {
          render: message,
          type: "error",
          isLoading: false,
          autoClose: 3000,
          theme: "dark",
        });
      });
  };
  return (
    <>
      <ToastContainer />
      <main className="d-flex">
        <div className="main-container">
          <div className="row nm-row">
            <div className="col-lg-6 nm-bgi d-none d-lg-flex">
              <div className="overlay">
                <div className="hero-item">
                  <a href="index.html" aria-label="Nimoy">
                    <img src="images/logo-wh.png" alt="Logo" />
                  </a>
                </div>
                <div className="hero-item hero-item-1">
                  <h2>Some Title Goes Here</h2>
                  <h2>Some Title Goes Here</h2>
                </div>
              </div>
            </div>
            <div className="col-lg-6 nm-mb-1 nm-mb-md-1 nm-aic">
              <div className="card">
                <div className="card-content">
                  <div className="header">
                    <h4>Forgot Password{driver ? " Driver" : ""}</h4>
                  </div>
                  <div className="form-group">
                    <div className="input-group nm-gp">
                      <span className="nm-gp-pp">
                        <i className="fas fa-envelope-open" />
                      </span>
                      <input
                        id="Email"
                        className={
                          errors.email === "blank"
                            ? "form-control"
                            : errors.email == ""
                            ? "form-control is-valid"
                            : "form-control is-invalid"
                        }
                        type="email"
                        tabIndex={1}
                        name="email"
                        value={userDetails.email}
                        onChange={(e) =>
                          emailOnly(
                            e,
                            setUserDetails,
                            userDetails,
                            setErrors,
                            errors
                          )
                        }
                        placeholder="Enter Your Email"
                        required
                      />
                      <div class="invalid-feedback">{errors.email}</div>
                    </div>
                  </div>

                  <button
                    type="submit"
                    className="btn btn-block btn-primary nm-btn"
                    onClick={(e) => validate()}
                  >
                    Log In
                  </button>
                </div>
                <p className="text-center nm-ft-b nm-wh mb-0 noacnt">
                  No account yet?{" "}
                  <Link to={driver ? "/become-a-driver" : "/register"}>
                    Signup
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Body;
