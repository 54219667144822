import { React, useEffect, useState } from "react";
import Footer from "../../Common/Footer";
import Header from "../../Common/Header";
import Icons from "../../Common/Icons";
import Body from "../../Components/OrderProcess/StepFour/Body";
import { APIURL } from "../../Methods/Fetch";
import {  axiosPost } from "../../Methods/Save";

function StepFour(props) {
  const { setBackGroundColor } = props;
  useEffect(() => {
    setBackGroundColor("step5-fullbg");
  }, []); 
  return (
    <>
      <Header />
      <Icons />
      <div className="box-margin">
        <Body />
        {/* <Footer className="border-top" /> */}
      </div>
    </>
  );
}

export default StepFour;
