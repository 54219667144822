import React from "react";

function DateTime({
  editMode,
  event,
  changeEvent,
  events,
  eventsObj,
  hotel,
  changeHotel,
  hotels,
  date,
  time,
  saveChanges,
  verify,
  reset,
  setEditMode,
  hotelsObj
}) {
  console.log(hotelsObj,"hotelsObj");
  console.log(eventsObj,"eventsObj");
  return (
    <>
      <div className="book-data">
        <div className="row">
          <div className="col-lg-12">
            <div className="event-title">
              <h4>Event Details</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-6">
            <div className="date-time-text">
              <h5>Event</h5>
            </div>
            <div className="event-box">
              <select
                class="form-select"
                aria-label="Disabled select example"
                disabled={editMode.event ? false : true}
                value={eventsObj?.id}
                onChange={(e) => {
                  changeEvent(e);
                  // console.log("e.target.value", e.target.value);
                  // setEvent(e.target.value.name);
                  // setEventsObj(e.target.value);
                }}
              >
                {event ? (
                  ""
                ) : (
                  <option value="" selected>
                    Select an event
                  </option>
                )}

                {events && events.length > 0
                  ? events.map((singelEvent) => (
                      <option
                        value={singelEvent.id}
                        selected={singelEvent.id == eventsObj.id ? true : false}
                      >
                        {singelEvent.name}
                      </option>
                    ))
                  : ""}
              </select>
              <a
                className={editMode.event == false ? "" : "d-none"}
                onClick={(e) =>
                  setEditMode({
                    ...editMode,
                    event: !editMode.event,
                  })
                }
                style={{ cursor: "pointer" }}
              >
                <i class="fal fa-edit"></i>
              </a>
            </div>
          </div>
          <div className="col-lg-6 col-md-6">
            <div className="date-time-text">
              <h5>Restaurant</h5>
            </div>
            <div className="event-box">
              <select
                class="form-select"
                aria-label="Disabled select example"
                disabled={editMode.hotel ? false : true}
                value={hotelsObj?.id}
                onChange={(e) => changeHotel(e)}
              >
                {hotel ? (
                  ""
                ) : (
                  <option value="" selected>
                    Select an hotel
                  </option>
                )}
                {hotels && hotels.length > 0
                  ? hotels.map((singelHotel) => (
                      <option
                        selected={
                          singelHotel.id == hotelsObj?.id ? true : false
                        }
                        value={singelHotel.id}
                      >
                        {singelHotel.resturant_name}
                      </option>
                    ))
                  : ""}
              </select>
              <a
                className={editMode.hotel == false ? "" : "d-none"}
                onClick={(e) =>
                  setEditMode({
                    ...editMode,
                    hotel: !editMode.hotel,
                  })
                }
                style={{ cursor: "pointer" }}
              >
                <i class="fal fa-edit"></i>
              </a>
            </div>
          </div>
          <div className="col-lg-6 col-md-6">
            <div className="date-time-text">
              <h5>Date</h5>
            </div>
            <div className="event-box">
              <h5>{date}</h5>
              {/*<a
                                className={editMode.date == false ? "" : "d-none"}
                                onClick={(e) =>
                                  setEditMode({
                                    ...editMode,
                                    date: !editMode.date,
                                  })
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <i class="fal fa-edit"></i>
                              </a>*/}
            </div>
          </div>
          <div className="col-lg-6 col-md-6">
            <div className="date-time-text">
              <h5>Time</h5>
            </div>
            <div className="event-box">
              <h5>{time}</h5>
              {/*<a
                                className={editMode.time == false ? "" : "d-none"}
                                onClick={(e) =>
                                  setEditMode({
                                    ...editMode,
                                    time: !editMode.time,
                                  })
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <i class="fal fa-edit"></i>
                              </a>*/}
            </div>
          </div>
          {/*<div className="col-lg-6 mb-3">
                            <a
                              onClick={(e) => setSave(!save)}
                              href="#!"
                              className="btn-one"
                            >
                              Save
                            </a>
                            </div>*/}

          {editMode.event == true ? (
            <div className="row">
              <div className="col-lg-6 mb-3 col-md-6">
                <a onClick={reset} className="btn-one btn_anchor">
                  Reset
                </a>
              </div>
              <div className="col-lg-6 col-md-6">
                <a onClick={saveChanges} className="btn-two btn_anchor">
                  Save Changes
                </a>
              </div>
            </div>
          ) : (
            <>
              <div className="col-lg-6 mb-3 col-md-6">
                <a className="btn-one btn_anchor">Edit</a>
              </div>
              <div className="col-lg-6 col-md-6">
                <a className="btn-two btn_anchor" onClick={(e) => verify()}>
                  Next
                </a>
              </div>
            </>
          )}
          <div className="desc-char step4-playerchoose">
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum .{" "}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default DateTime;
