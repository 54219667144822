import { React, useEffect, useState } from "react";
import Footer from "../../Common/Footer";
import Header from "../../Common/Header";
import Icons from "../../Common/Icons";
import Body from "../../Components/OrderProcess/StepOne/Body";
import VideoModal from "../../Components/OrderProcess/StepOne/VideoModal";

function StepOne(props) {
  const { setBackGroundColor } = props;
  useEffect(() => {
    setBackGroundColor("step2-fullbg");
  }, []);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  return (
    <>
      
      <Header />
      <Icons />

      <div className="box-margin">
        <Body toggle={toggle} />
      </div>
      <VideoModal toggle={toggle} modal={modal} setModal={setModal} />
    </>
  );
}

export default StepOne;
