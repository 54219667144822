import React, { useEffect, useState } from "react";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { useNavigate } from "react-router-dom/dist";
import { Calendar, utils } from "react-modern-calendar-datepicker-fix";
import TimeKeeper from "react-timekeeper";
import { APIURL } from "../../../Methods/Fetch";
import { axiosGet, axiosPost } from "../../../Methods/Save";
import { SyncLoader } from "react-spinners";
import DateAndTime from "./DateAndTime";

function Body() {
  const navigate = useNavigate();
  const [selectedDay, setSelectedDay] = useState();
  const [players, setPlayers] = useState(localStorage.getItem("players"));
  const [char1, setChar1] = useState();
  const [char2, setChar2] = useState();
  const [date, setDate] = useState();
  const [selectedTime, setSelectedTime] = useState(
    localStorage.getItem("time")
  );
  const [pick, setPick] = useState(false);
  const [availableDays, setAvailableDays] = useState([]);
  const [time, setTime] = useState("12:34pm");
  const [showTime, setShowTime] = useState(false);
  useEffect(() => {
    if (Number(players) > 0) {
      setChar1(JSON.parse(localStorage.getItem("characterOne")));
      if (Number(players) == 2)
        setChar2(JSON.parse(localStorage.getItem("characterTwo")));
    }
  }, [players]);

  const [loading, setLoading] = useState(false);
  const [id, setId] = useState();
  return (
    <>
      <SyncLoader
        loading={loading}
        className="react-loader-new"
        color="#9a13d2"
      />
      <div className="desktop-sec">
        <section
          className="banner-sec step-three-body"
          onClick={(e) => setPick(true)}
        >
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 mx-auto">
                <div className="top-img">
                  <div className={pick == true ? "" : "d-none"}>
                    <div
                      className={
                        showTime ? "step4-calendar d-none" : "step4-calendar"
                      }
                    >
                      <DateAndTime
                        selectedDay={selectedDay}
                        setSelectedDay={setSelectedDay}
                        selectedTime={selectedTime}
                        setSelectedTime={setSelectedTime}
                        setTime={setTime}
                        setLoading={setLoading}
                        date={date}
                        setDate={setDate}
                      />
                    </div>
                  </div>
                  <div className="step4-cpl">
                    {Number(players) == 1 ? (
                      <img src={char1 ? char1.image : ""} alt="char1" />
                    ) : (
                      <div className="d-flex">
                        <img src={char1 ? char1.image : ""} alt="char1" />
                        <img src={char2 ? char2.image : ""} alt="char2" />
                      </div>
                    )}
                  </div>
                  <div className="presslogo-link">
                    <img src="images/blank.png" alt="blank" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="mobile-sec ">
        <div className="mob-img-box step4">
          <div class="presslogo-link">
            <a href="#" onClick={(e) => setPick(true)}>
              <img src="images/blank.png" alt="blank" />
            </a>
          </div>
          <div className={pick == true ? "" : "d-none"}>
            <div
              className={showTime ? "step4-calendar d-none" : "step4-calendar"}
            >
              <DateAndTime
                selectedDay={selectedDay}
                setSelectedDay={setSelectedDay}
                selectedTime={selectedTime}
                setSelectedTime={setSelectedTime}
                setTime={setTime}
                setLoading={setLoading}
                date={date}
                setDate={setDate}
              />
            </div>
          </div>
          <div className="step4-cpl">
            {Number(players) == 1 ? (
              <img src={char1 ? char1.image : ""} alt="char1" />
            ) : (
              <div className="d-flex">
                <img src={char1 ? char1.image : ""} alt="char1" />
                <img src={char2 ? char2.image : ""} alt="char2" />
              </div>
            )}
          </div>
          <img src="images/scene3.gif" alt />
        </div>
      </div>
    </>
  );
}

export default Body;
