import React, { useEffect, useState } from "react";
import { APIURL } from "../../Methods/Fetch";
import { axiosGet, axiosPost } from "../../Methods/Save";
import Menu from "./Menu";
import { ToastContainer, toast } from "react-toastify";
import SyncLoader from "react-spinners/SyncLoader";

function Body(props) {
  const { driverDetails } = props;
  const [bookingDetails, setbookingDetails] = useState([]);
  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    setLoading(true);
    var url = APIURL() + `driver-dashboard/${driverDetails.id}`;
    axiosGet(url).then((response) => {
      setbookingDetails(response.data[0].response.data);
      setLoading(false);
    });
  }, [refresh]);
  const acceptBooking = (bookingDetail) => {
    var url = APIURL() + "driver-booking-status";
    var data = {
      booking_id: bookingDetail.booking_id,
      driver_id: driverDetails.id,
      status: 2,
    };
    axiosPost(url, data).then((response) => {
      setRefresh(!refresh);
      toast.success("Booking Accepted!", {
        position: toast.POSITION.TOP_RIGHT,
        theme: "dark",
      });
    });
  };
  const rejectBooking = (bookingDetail) => {
    var url = APIURL() + "driver-booking-status";
    var data = {
      booking_id: bookingDetail.booking_id,
      driver_id: driverDetails.id,
      status: 3,
    };
    axiosPost(url, data).then((response) => {
      setRefresh(!refresh);
      toast.success("Booking Cancelled!", {
        position: toast.POSITION.TOP_RIGHT,
        theme: "dark",
      });
    });
  };
  const [loading, setLoading] = useState(false);

  return (
    <>
      <SyncLoader
        loading={loading}
        className="react-loader-new"
        color="#9a13d2"
      />
      <ToastContainer />
      <section className="banner-sec">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 mx-auto">
              <div className="driver-dashboard-page">
                <Menu driverDetails={driverDetails} />
                <div className="row">
                  {bookingDetails && bookingDetails.length > 0 ? (
                    bookingDetails.map((bookingDetail) => (
                      <div className="col-lg-9 mx-auto mb-4">
                        <div className="profile-confirm-page">
                          <div className="profile-cr">
                            <div className="profile-title-flex">
                              <div className="pro-img">
                                <img src="images/avatar-1.jpg" alt />
                              </div>
                              <div className="pro_title_desc">
                                <ul>
                                  <li>
                                    <h6>Customer Name : </h6>
                                    <span>{bookingDetail.customer_name}</span>
                                  </li>
                                  <li>
                                    <h6>Booking ID : </h6>
                                    <span>{bookingDetail.booking_id}</span>
                                  </li>
                                  <li>
                                    <h6> Players : </h6>
                                    <span>{bookingDetail.player}</span>
                                  </li>
                                  <li>
                                    <h6> Booking date : </h6>
                                    <span>{bookingDetail.booking_date}</span>
                                  </li>
                                  <li>
                                    <h6> Phone : </h6>
                                    <span>{bookingDetail.customer_phone}</span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="pickup-box raise-form">
                            <div className="pickup-date">
                              <div className="row">
                                <div className="col-lg-12 col-md-10">
                                  <div className="order-ptb hh-grayBox pt45">
                                    {bookingDetail.routes &&
                                    bookingDetail.routes.length > 0 ? (
                                      bookingDetail.routes.map(
                                        (route, index) => (
                                          <div
                                            className={
                                              route.pickup_status == 0
                                                ? "order-tracking"
                                                : "order-tracking completed"
                                            }
                                          >
                                            <div className="pikup-addres">
                                              <span>
                                                <i className="fal fa-truck-pickup" />{" "}
                                                {index == 0
                                                  ? "PICKUP"
                                                  : index + 1 ==
                                                    bookingDetail.routes.length
                                                  ? "DROP"
                                                  : "STOP"}
                                              </span>
                                              <h5>{route.pickup_location}</h5>
                                            </div>
                                            <span className="is-complete" />
                                          </div>
                                        )
                                      )
                                    ) : (
                                      <h4>N/A</h4>
                                    )}
                                  </div>
                                </div>
                                {bookingDetail &&
                                bookingDetail.booking_status == "1" ? (
                                  <div className="row">
                                    <div className="col-lg-6">
                                      <a
                                        onClick={(e) =>
                                          acceptBooking(bookingDetail)
                                        }
                                        href="#"
                                        className="btn-one"
                                      >
                                        Accept
                                      </a>
                                    </div>
                                    <div className="col-lg-6">
                                      <a
                                        onClick={(e) =>
                                          rejectBooking(bookingDetail)
                                        }
                                        href="#"
                                        className="btn-two"
                                      >
                                        Reject
                                      </a>
                                    </div>
                                  </div>
                                ) : (
                                  <a href="#" className="btn-accept">
                                    Accepted
                                  </a>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="text-center">
                      <h6>No active bookings</h6>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Body;
