import React, { useEffect } from "react";
import { utils } from "react-modern-calendar-datepicker-fix";

function LocalStorage() {
  useEffect(() => {
    localStorage.setItem("charType", JSON.stringify({}));
    localStorage.setItem("characterOne", JSON.stringify({}));
    localStorage.setItem("characterTwo", JSON.stringify({}));
    localStorage.setItem("eventsObj", JSON.stringify({}));
    localStorage.setItem("players", "");
    localStorage.setItem("booking_id", "");
    localStorage.setItem("activateSelectSecondPlayer", "no");
    localStorage.setItem("showChars", "no");
    localStorage.setItem("hotelsObj", JSON.stringify({}));
    localStorage.removeItem("hotels");
    localStorage.removeItem("dateObj");
    localStorage.removeItem("time");
    localStorage.removeItem("date");
    localStorage.setItem(
      "customerRegistration",
      JSON.stringify({
        name: "",
        phone: "",
        email: "",
      })
    );
    localStorage.setItem(
      "eventDetails",
      JSON.stringify({
        time: "",
        date: "",
        event: "",
        hotel: "",
      })
    );
  }, []);
  return <></>;
}

export default LocalStorage;
