import React, { useEffect, useState } from "react";
import { APIURL } from "../../../Methods/Fetch";
import { axiosGet } from "../../../Methods/Save";

function PartTwoMobile({
  userDetails,
  players,
  toggleDateModal,
  editMode,
  event,
  changeEvent,
  events,
  eventsObj,
  setEditMode,
  hotel,
  changeHotel,
  hotels,
  date,
  time,
  reset,
  saveChanges,
  verify,
  setEvent,
  setHotel,
  setPart,
  street,
  setStreet,
  pay,
}) {
  const [zones, setZones] = useState({});
  const [zone, setZone] = useState();
  const [postCode, setPostCode] = useState();
  const [postCodes, setPostCodes] = useState({});
  const [streets, setStreets] = useState({});
  useEffect(() => {
    var url = APIURL() + "zones";

    axiosGet(url).then((response) => {
      setZones(response.data[0].response.data);
      setPostCode("");
    });
  }, []);
  useEffect(() => {
    if (zone && zone != "") var url = APIURL() + "postcodes/" + zone;

    axiosGet(url).then((response) => {
      setPostCodes(response.data[0].response.data);
      setStreet("");
    });
  }, [zone]);
  useEffect(() => {
    if (postCode && postCode != "") var url = APIURL() + "address/" + postCode;

    axiosGet(url).then((response) => {
      setStreets(response.data[0].response.data);
    });
  }, [postCode]);

  return (
    <div className="step3-body">
      <div>
        <div className="profile-cr">
          <div className="profile-title-flex">
            <div className="pro-img">
              <img src="images/avatar-1.jpg" alt />
              <div className="pro-title">
                <h4>{userDetails.name}</h4>
                <span>Player : {players}P</span>
              </div>
            </div>
          </div>
        </div>
        <div className="book-data">
          <div className="row">
            <div className="col-lg-12">
              <div className="event-title">
                <h4>Pickup Location</h4>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="date-time-text">
                <h5>Zone</h5>
              </div>
              <div className="event-box">
                <select
                  class="form-select"
                  aria-label="Disabled select example"
                  value={zone}
                  onChange={(e) => setZone(e.target.value)}
                >
                  <option value="" selected>
                    Select a Zone
                  </option>
                  {zones && zones.length > 0
                    ? zones.map((zone) => (
                        <option
                          selected={zone.zone_id == zone ? true : false}
                          value={zone.zone_id}
                        >
                          {zone.name}
                        </option>
                      ))
                    : ""}
                </select>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="date-time-text">
                <h5>PostCodes</h5>
              </div>
              <div className="event-box">
                <select
                  class="form-select"
                  aria-label="Disabled select example"
                  value={postCode}
                  onChange={(e) => setPostCode(e.target.value)}
                >
                  <option value="" selected>
                    Select a PostCode
                  </option>
                  {postCodes && postCodes.length > 0
                    ? postCodes.map((postCode) => (
                        <option
                          selected={
                            postCode.postcode_id == postCode ? true : false
                          }
                          value={postCode.postcode_id}
                        >
                          {postCode.name}
                        </option>
                      ))
                    : ""}
                </select>
                {/*<a
                  className={
                    editMode.hotel == false ? "" : "d-none"
                  }
                  onClick={(e) =>
                    setEditMode({
                      ...editMode,
                      hotel: !editMode.hotel,
                    })
                  }
                  style={{ cursor: "pointer" }}
                >
                  <i class="fal fa-edit"></i>
                </a>*/}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="date-time-text">
                <h5>Addresses</h5>
              </div>
              <div className="event-box">
                <select
                  class="form-select"
                  aria-label="Disabled select example"
                  value={street}
                  onChange={(e) => setStreet(e.target.value)}
                >
                  <option value="" selected>
                    Select an Address
                  </option>
                  {streets && streets.length > 0
                    ? streets.map((street) => (
                        <option
                          selected={street.address_id == street ? true : false}
                          value={street.address_id}
                        >
                          {street.name}
                        </option>
                      ))
                    : ""}
                </select>
                {/*<a
                  className={
                    editMode.hotel == false ? "" : "d-none"
                  }
                  onClick={(e) =>
                    setEditMode({
                      ...editMode,
                      hotel: !editMode.hotel,
                    })
                  }
                  style={{ cursor: "pointer" }}
                >
                  <i class="fal fa-edit"></i>
                </a>*/}
              </div>
            </div>
            <div className="col-lg-6 mb-3">
              <a href="#!" className="btn-one" onClick={(e) => setPart(1)}>
                Back
              </a>
            </div>
            <div className="col-lg-6">
              <a href="#!" className="btn-two" onClick={(e) => pay()}>
                Pay
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PartTwoMobile;
