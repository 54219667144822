import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { APIURL } from "../../Methods/Fetch";
import { axiosPost } from "../../Methods/Save";
import { BlankValidation, emailOnly } from "../../Methods/ValidateForms";
import { useGoogleLogin } from "@react-oauth/google";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import axios from "axios";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

function Body({ setShowRegister }) {
  const navigate = useNavigate();
  const [user, setUser] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [profile, setProfile] = useState();
  const [userDetails, setUserDetails] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({
    email: "blank",
    password: "blank",
  });
  const handleChange = (e) => {
    const { name, value, id } = e.target;
    setUserDetails({ ...userDetails, [name]: value });
    if (value.length == 0) {
      setErrors({ ...errors, [name]: `${id} cannot be empty` });
    } else {
      setErrors({ ...errors, [name]: "" });
    }
  };

  const login = () => {
    const id = toast.loading("Please wait...");
    var data = { ...userDetails };
    var url = APIURL() + "login";
    axiosPost(url, data)
      .then((res) => {
        localStorage.setItem(
          "userDetails",
          JSON.stringify({
            ...res.data.user_data,
            id: res.data.user_id,
            date: res.data.user_data.date.split(" ")[0],
          })
        );
        localStorage.setItem("userId", JSON.stringify(res.data.user_id));
        localStorage.removeItem("driverId");
        localStorage.removeItem("driverDetails");
        toast.update(id, {
          render: res.data.message,
          type: "success",
          isLoading: false,
          autoClose: 3000,
          theme: "dark",
        });
        navigate("/dashboard");
      })
      .catch((err) => {
        var message = "";
        if (err.response.data.email) {
          setErrors({ ...errors, email: err.response.data.email });
          message = err.response.data.email;
        }
        if (err.response.data.password) {
          setErrors({ ...errors, password: err.response.data.password });
          message = err.response.data.password;
        } else {
          // setErrors({ ...errors, password: err.response.data.error });
          message = err.response.data.error;
        }
        toast.update(id, {
          render: message,
          type: "error",
          isLoading: false,
          autoClose: 3000,
          theme: "dark",
        });
      });
  };
  const validate = () => {
    const result = BlankValidation(userDetails, login, errors, setErrors);
    if (result == false)
      toast.error("Please fill all the mandatory fields!", {
        position: toast.POSITION.TOP_RIGHT,
        theme: "dark",
      });
  };
  const Googlelogin = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setUser(codeResponse);
      console.log(codeResponse);
    },
    onError: (error) => console.log("Login Failed:", error),
  });
  const responseFacebook = (response) => {
    setProfile(response);
  };
  useEffect(() => {
    if (user) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          setProfile(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [user]);
  console.log(profile);
  useEffect(() => {
    if (profile) {
      const id = toast.loading("Please wait...");
      var data = { ...profile };
      var url = APIURL() + "facebooklogin";
      axiosPost(url, data)
        .then((res) => {
          localStorage.setItem(
            "userDetails",
            JSON.stringify({ ...res.data.user_data, id: res.data.user_id })
          );
          localStorage.setItem("userId", JSON.stringify(res.data.user_id));
          toast.update(id, {
            render: res.data.message,
            type: "success",
            isLoading: false,
            autoClose: 3000,
            theme: "dark",
          });
          navigate("/dashboard");
        })
        .catch((err) => {
          var message = "";
          if (err.response.data.response == "false") {
            register();
          }
          if (err.response.data.password) {
            setErrors({ ...errors, password: err.response.data.password });
            message = err.response.data.password;
          }
          toast.update(id, {
            render: message,
            type: "error",
            isLoading: false,
            autoClose: 3000,
            theme: "dark",
          });
        });
    }
  }, [profile]);
  const register = () => {
    const id = toast.loading("Please wait...");
    var data = { ...profile };
    var url = APIURL() + "register";
    axiosPost(url, data)
      .then((res) => {
        console.log();
        toast.update(id, {
          render: res.data.msg,
          type: "success",
          isLoading: false,
          autoClose: 3000,
          theme: "dark",
        });
        localStorage.setItem("userId", res.data.data.id);
        localStorage.setItem("userDetails", JSON.stringify(res.data.data));
        setTimeout(() => {
          navigate("/dashboard");
        }, 1000);
      })
      .catch((err) => {
        if (err.response.data.errors.email) {
          setErrors({ ...errors, email: err.response.data.errors.email[0] });
        }
        toast.update(id, {
          render: err.response.data.message,
          type: "error",
          isLoading: false,
          autoClose: 3000,
          theme: "dark",
        });
      });
  };
  return (
    <>
      <ToastContainer />
      <main className="d-flex">
        <div className="main-container">
          <div className="row nm-row">
            <div className="col-lg-6 nm-bgi d-none d-lg-flex">
              <div className="overlay">
                <div className="hero-item">
                  <a href="index.html" aria-label="Nimoy">
                    <img src="images/logo-wh.png" alt="Logo" />
                  </a>
                </div>
                <div className="hero-item hero-item-1">
                  <h2>Some Title Goes Here</h2>
                  <h2>Some Title Goes Here</h2>
                </div>
              </div>
            </div>
            <div className="col-lg-6 nm-mb-1 nm-mb-md-1 nm-aic">
              <div className="card register_uma">
                <div className="card-content">
                  <div className="header">
                    <h4>Login to Your Account</h4>
                  </div>
                  <div className="form-group">
                    <div className="input-group nm-gp">
                      <span className="nm-gp-pp">
                        <i className="fas fa-envelope-open" />
                      </span>
                      <input
                        id="Email"
                        className={
                          errors.email === "blank"
                            ? "form-control"
                            : errors.email == ""
                            ? "form-control is-valid"
                            : "form-control is-invalid"
                        }
                        type="email"
                        tabIndex={1}
                        name="email"
                        value={userDetails.email}
                        onChange={(e) =>
                          emailOnly(
                            e,
                            setUserDetails,
                            userDetails,
                            setErrors,
                            errors
                          )
                        }
                        placeholder="Enter Your Email"
                        required
                      />
                      <div class="invalid-feedback">{errors.email}</div>
                    </div>
                  </div>
                  <div className="form-group mb-1">
                    <div className="input-group nm-gp">
                      <span className="nm-gp-pp">
                        <i className="fas fa-lock" />
                      </span>
                      <input
                        id="Password"
                        className={
                          errors.password === "blank"
                            ? "form-control"
                            : errors.password == ""
                            ? "form-control is-valid"
                            : "form-control is-invalid"
                        }
                        type={showPassword ? "text" : "password"}
                        tabIndex={2}
                        name="password"
                        value={userDetails.password}
                        onChange={(e) => handleChange(e)}
                        placeholder="Enter Your Password"
                        required
                      />
                      {showPassword ? (
                        <VisibilityIcon
                          onClick={(e) => setShowPassword(false)}
                          style={{
                            position: "absolute",
                            right: "13px",
                            top: "11px",
                            cursor: "pointer",
                            zIndex: "99999",
                          }}
                        />
                      ) : (
                        <VisibilityOffIcon
                          onClick={(e) => setShowPassword(true)}
                          style={{
                            position: "absolute",
                            right: "13px",
                            top: "11px",
                            cursor: "pointer",
                            zIndex: "99999",
                          }}
                        />
                      )}{" "}
                      <div class="invalid-feedback">{errors.password}</div>
                    </div>
                  </div>
                  <div className="d-flex nm-jcb nm-mb-1 nm-mt-1 justify-content-end">
                    <Link className="forgotpw" to="/forgot-password">
                      Forgot Password?
                    </Link>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-block btn-primary nm-btn"
                    onClick={(e) => validate()}
                  >
                    Log In
                  </button>
                  <p className="divider">
                    Or continue with your social profile:
                  </p>
                  <div className="row social nm-mb-1">
                    <div className="col-md-4 mb-2 mb-md-0">
                      <FacebookLogin
                        appId="628727258708098"
                        // autoLoad
                        fields="email"
                        callback={responseFacebook}
                        render={(renderProps) => (
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={renderProps.onClick}
                            className="btn btn-block nm-btn btn-facebook"
                          >
                            <i className="fab fa-facebook-f nm-mr-1h" />
                            Facebook
                          </a>
                        )}
                      />
                    </div>
                    <div className="col-md-4">
                      <a
                        onClick={Googlelogin}
                        style={{ cursor: "pointer" }}
                        className="btn btn-block nm-btn btn-google"
                      >
                        <i className="fab fa-google nm-mr-1h" />
                        Google
                      </a>
                    </div>
                    <div className="col-md-4">
                      <a href="#" className="btn btn-block nm-btn btn-google">
                        <i className="fab fa-apple" />
                        Apple
                      </a>
                    </div>
                  </div>
                  <p className="text-center nm-ft-b nm-wh mb-0 noacnt">
                    No account yet?{" "}
                    <a
                      className="btn_anchor"
                      style={{ color: "#cb52ce" }}
                      onClick={() => setShowRegister(true)}
                    >
                      Sign Up
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Body;
