import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { APIURL } from "../../Methods/Fetch";
import { axiosGet } from "../../Methods/Save";

function Body(props) {
  const navigate = useNavigate();
  const { userDetails } = props;

  const [bookings, setBookings] = useState();
  useEffect(() => {
    setLoading(true);
    var url = APIURL() + `my-booking/${userDetails.id}`;
    axiosGet(url).then((response) => {
      setBookings(response.data[0].response.data);
      setLoading(false);
    });
  }, []);
  const edit = (event) => {
    console.log("event", event);
    var dateobj = event.booking_date.split("-");
    console.log({
      day: Number(dateobj[2]),
      month: Number(dateobj[1]),
      year: Number(dateobj[0]),
    });
    localStorage.setItem(
      "dateObj",
      JSON.stringify({
        day: Number(dateobj[2]),
        month: Number(dateobj[1]),
        year: Number(dateobj[0]),
      })
    );
    localStorage.setItem("date", event.booking_date);
    localStorage.setItem("time", event.booking_time);
    localStorage.setItem(
      "eventsObj",
      JSON.stringify({ id: event.event_id, price: event.price })
    );
    localStorage.setItem(
      "hotelsObj",
      JSON.stringify({ id: event.resturant_id })
    );
    localStorage.setItem(
      "characterOne",
      JSON.stringify({ id: event.char_one })
    );
    localStorage.setItem(
      "characterTwo",
      JSON.stringify({ id: event.char_two })
    );
    localStorage.setItem("hotels", event.resturant_id);
    localStorage.setItem("players", event.players);
    localStorage.setItem("booking_id", event.id);
    navigate("/step-three");
  };
  const [loading, setLoading] = useState(false);
  return (
    <>
      <section className="banner-sec">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 mx-auto">
              <div className="driver-dashboard-page">
                <div className="row">
                  <div className="col-lg-7" />
                  <div className="col-lg-5">
                    <div className="search-box">
                      <select name id className="form-select">
                        <option value selected>
                          Search by date
                        </option>
                        <option value>2023-01-22</option>
                        <option value>2023-01-22</option>
                        <option value>2023-01-22</option>
                        <option value>2023-01-22</option>
                        <option value>2023-01-22</option>
                        <option value>2023-01-22</option>
                      </select>
                      <a href="#">
                        <i className="fal fa-search" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="black-box">
                  <div className="raise-ticket">
                    <div className="raise-title">
                      <p>Bookings</p>
                    </div>                    
                  </div>
                  <div className="spacer1px" />
                  <div className="cs-table">
                    <div className="table_responsive_maas">
                      {bookings && bookings.length > 0 ? (
                        <table className="table" width="100%">
                          <thead>
                            <tr>
                              <th>Event Name</th>
                              <th>Event Date </th>
                              <th>Event Time </th>
                              <th>Action </th>
                            </tr>
                          </thead>
                          <tbody>
                            {bookings.map((booking) => (
                              <tr>
                                <td>{booking.event_name}</td>
                                <td>{booking.booking_date}</td>
                                <td>{booking.booking_time}</td>
                                <td>
                                  <div className="dropdown edit-c-btn">
                                    <a
                                      className="list_btn"
                                      role="button"
                                      id="dropeditbtn"
                                      data-bs-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <i className="fas fa-ellipsis-v" />
                                    </a>
                                    <div
                                      className="dropdown-menu new_drop_cont"
                                      aria-labelledby="dropeditbtn"
                                    >
                                      <ul>
                                        <li className="dropdown-item">
                                          <a
                                            onClick={(e) => edit(booking)}
                                            href="#"
                                          >
                                            <i className="fal fa-edit" />
                                            Edit
                                          </a>
                                        </li>
                                        <li className="dropdown-item">
                                          <a href="#">
                                            <i className="fas fa-trash-alt" />
                                            Delete
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <h1 className="text-center">No Previous Bookings</h1>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Body;
