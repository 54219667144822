import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { APIURL } from "../../Methods/Fetch";
import { axiosGet } from "../../Methods/Save";

function Body(props) {
  const { userDetails } = props;
  const [notification, setNotification] = useState([]);

  useEffect(() => {
    if (userDetails) {
      var url = APIURL() + `user-all-notifications/${userDetails.id}`;
      axiosGet(url).then((response) => {
        setNotification(response.data[0].response.data);
      });
    }
  }, [userDetails]);
  return (
    <>
      <section className="banner-sec">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 mx-auto">
              <div className="driver-dashboard-page">
                <div className="black-box">
                  <div className="raise-ticket">
                    <div className="raise-title">
                      <p>Notifications</p>
                    </div>
                  </div>
                  <div className="spacer1px" />
                  <div className="cs-table">
                    <div className="table_responsive_maas">
                      <table className="table" width="100%">
                        <thead>
                          <tr>
                            <th>Notification</th>
                            <th>Date </th>
                            <th>Action </th>
                          </tr>
                        </thead>
                        <tbody>
                          {notification && notification.length > 0
                            ? notification.map((noti) => (
                                <tr>
                                  <td>{noti.data}</td>
                                  <td>{noti.created_at}</td>
                                  <td>
                                    <div className="dropdown edit-c-btn">
                                      <a
                                        className="list_btn"
                                        role="button"
                                        id="dropeditbtn"
                                        data-bs-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                      >
                                        <i className="fas fa-ellipsis-v" />
                                      </a>
                                      <div
                                        className="dropdown-menu new_drop_cont"
                                        aria-labelledby="dropeditbtn"
                                      >
                                        <ul>
                                          <li className="dropdown-item">
                                            <a href="#">
                                              <i className="fas fa-trash-alt" />
                                              Delete
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              ))
                            : ""}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Body;
