import { React, useEffect, useState } from "react";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import Icons from "../Common/Icons";
import Body from "../Components/Notification/Body";
import { APIURL } from "../Methods/Fetch";
import { axiosGet } from "../Methods/Save";

function Notifications(props) {
  const { setBackGroundColor } = props;
  useEffect(() => {
    setBackGroundColor("bg-texture-gradient gradient2");
  }, []);
  const [userDetails, setUserDetails] = useState(
    JSON.parse(localStorage.getItem("userDetails"))
  );
  useEffect(() => {
    var url = APIURL() + `user-read-all-notifications/${userDetails.id}`;
    axiosGet(url).then((response) => {});
  }, []);
  return (
    <>
      <Header dashBoard={true} userDetails={userDetails} />
      <Icons />
      <div className="box-margin bgcolor9">
        <Body userDetails={userDetails} />
        {/* <Footer className="border-top" /> */}
      </div>
    </>
  );
}

export default Notifications;
