import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { APIURL } from "../../Methods/Fetch";
import { axiosPost } from "../../Methods/Save";
import { BlankValidation } from "../../Methods/ValidateForms";

function Body(props) {
  const { LoginInUserDetails } = props;
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState({
    id: LoginInUserDetails.id,
    previous_password: "",
    new_password: "",
    confirm_password: "",
  });
  const [errors, setErrors] = useState({
    previous_password: "blank",
    new_password: "blank",
    confirm_password: "blank",
  });
  const handleChange = (e) => {
    const { name, value, id } = e.target;
    setUserDetails({ ...userDetails, [name]: value });
    if (value.length == 0) {
      setErrors({ ...errors, [name]: `${id} cannot be empty` });
    } else {
      setErrors({ ...errors, [name]: "" });
    }
  };

  const login = () => {
    const id = toast.loading("Please wait...");
    var data = { ...userDetails };
    var url = APIURL() + "drivers-change-password";
    axiosPost(url, data)
      .then((res) => {
        toast.update(id, {
          render: "Passwrod changed successfully",
          type: "success",
          isLoading: false,
          autoClose: 3000,
          theme: "dark",
        });
        setTimeout(() => {
          navigate("/driver-dashboard");
        }, 2000);
      })
      .catch((err) => {
        var message = "";
        if (err.response.data.email) {
          setErrors({ ...errors, email: err.response.data.email });
          message = err.response.data.email;
        }
        if (err.response.data.password) {
          setErrors({ ...errors, password: err.response.data.password });
          message = err.response.data.password;
        }
        toast.update(id, {
          render: message,
          type: "error",
          isLoading: false,
          autoClose: 3000,
          theme: "dark",
        });
      });
  };
  const validate = () => {
    const result = BlankValidation(userDetails, login, errors, setErrors);
    if (result == false)
      toast.error("Please fill all the mandatory fields!", {
        position: toast.POSITION.TOP_RIGHT,
        theme: "dark",
      });
  };
  return (
    <>
      <ToastContainer />
      <main className="d-flex">
        <div className="main-container">
          <div className="row nm-row">
            <div className="col-lg-6 nm-bgi d-none d-lg-flex">
              <div className="overlay">
                <div className="hero-item">
                  <a href="index.html" aria-label="Nimoy">
                    <img src="images/logo-wh.png" alt="Logo" />
                  </a>
                </div>
                <div className="hero-item hero-item-1">
                  <h2>Some Title Goes Here</h2>
                  <h2>Some Title Goes Here</h2>
                </div>
              </div>
            </div>
            <div className="col-lg-6 nm-mb-1 nm-mb-md-1 nm-aic">
              <div className="card">
                <div className="card-content">
                  <div className="header">
                    <h4>Change Password Driver</h4>
                  </div>
                  <div className="form-group mb-1">
                    <div className="input-group nm-gp">
                      <span className="nm-gp-pp">
                        <i className="fas fa-lock" />
                      </span>
                      <input
                        id="Old Password"
                        className={
                          errors.previous_password === "blank"
                            ? "form-control"
                            : errors.previous_password == ""
                            ? "form-control is-valid"
                            : "form-control is-invalid"
                        }
                        type="password"
                        tabIndex={2}
                        name="previous_password"
                        value={userDetails.previous_password}
                        onChange={(e) => handleChange(e)}
                        placeholder="Enter your Old Password"
                        required
                      />
                      <div class="invalid-feedback">
                        {errors.previous_password}
                      </div>
                    </div>
                  </div>
                  <div className="form-group mb-1">
                    <div className="input-group nm-gp">
                      <span className="nm-gp-pp">
                        <i className="fas fa-lock" />
                      </span>
                      <input
                        id="New Password"
                        className={
                          errors.new_password === "blank"
                            ? "form-control"
                            : errors.new_password == ""
                            ? "form-control is-valid"
                            : "form-control is-invalid"
                        }
                        type="password"
                        tabIndex={2}
                        name="new_password"
                        value={userDetails.new_password}
                        onChange={(e) => handleChange(e)}
                        placeholder="Enter your New Password"
                        required
                      />
                      <div class="invalid-feedback">{errors.new_password}</div>
                    </div>
                  </div>
                  <div className="form-group mb-1">
                    <div className="input-group nm-gp">
                      <span className="nm-gp-pp">
                        <i className="fas fa-lock" />
                      </span>
                      <input
                        id="Confirm Password"
                        className={
                          errors.confirm_password === "blank"
                            ? "form-control"
                            : errors.confirm_password == ""
                            ? "form-control is-valid"
                            : "form-control is-invalid"
                        }
                        type="password"
                        tabIndex={2}
                        name="confirm_password"
                        value={userDetails.confirm_password}
                        onChange={(e) => handleChange(e)}
                        placeholder="Confirm Password"
                        required
                      />
                      <div class="invalid-feedback">
                        {errors.confirm_password}
                      </div>
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-block btn-primary nm-btn"
                    onClick={(e) => validate()}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Body;
