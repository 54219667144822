import { React, useEffect } from "react";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import Icons from "../Common/Icons";
import LocalStorage from "../Components/Homepage/LocalStorage";
import Body from "../Components/LandingPage/Body";

function LandingPage(props) {
  const { setBackGroundColor } = props;
  useEffect(() => {
    setBackGroundColor("step1-intro");
  }, []);
  return (
    <>
      <LocalStorage />
      <Header />
      <Icons />
      <div className="box-margin">
        <Body />
      </div>
    </>
  );
}

export default LandingPage;
