import React, { useState } from "react";
import {
  alphaBetsOnly,
  BlankValidation,
  emailOnly,
} from "../../Methods/ValidateForms";
import { ToastContainer, toast } from "react-toastify";
import { axiosPost } from "../../Methods/Save";
import { APIURL } from "../../Methods/Fetch";
import { useNavigate } from "react-router-dom";

function Body() {
  const navigate = useNavigate();

  const [errors, setErrors] = useState({
    name: "blank",
    ph_no: "blank",
    email: "blank",
    address: "blank",
    id: "blank",
    car: "blank",
    age: "blank",
    drivingExperience: "blank",
    modelNumber: "blank",
    NiNumber: "blank",
    blackSuit: "blank",
  });
  const [bookingDetails, setBookingDetails] = useState({
    name: "",
    ph_no: "",
    email: "",
    address: "",
    id: "",
    car: "",
    age: "",
    drivingExperience: "",
    modelNumber: "",
    NiNumber: "",
    blackSuit: "",
  });
  const [uploadedImages, setUploadedImages] = useState([]);
  console.log(uploadedImages);

  const handleChange = (e) => {
    const { name, value, id } = e.target;
    setBookingDetails({ ...bookingDetails, [name]: value });
    if (value.length == 0) {
      setErrors({ ...errors, [name]: `${id} cannot be empty` });
    } else {
      setErrors({ ...errors, [name]: "" });
    }
  };
  const validate = () => {
    const result = BlankValidation(bookingDetails, register, errors, setErrors);
    if (result == false)
      toast.error("Please fill all the mandatory fields!", {
        position: toast.POSITION.TOP_RIGHT,
        theme: "dark",
      });
  };
  console.log("bookingDetails", bookingDetails);
  const register = () => {
    const id = toast.loading("Please wait...");
    var data = { ...bookingDetails };
    var url = APIURL() + "driver-register";
    axiosPost(url, data, "", "", uploadedImages)
      .then((res) => {
        console.log();
        toast.update(id, {
          render: res.data.msg,
          type: "success",
          isLoading: false,
          autoClose: 3000,
          theme: "dark",
        });
        localStorage.setItem("userId", res.data.data.id);
        localStorage.setItem("userDetails", JSON.stringify(res.data.data));
        setTimeout(() => {
          navigate("/dashboard");
        }, 1000);
      })
      .catch((err) => {
        if (err.response.data.errors.email) {
          setErrors({ ...errors, email: err.response.data.errors.email[0] });
        }
        toast.update(id, {
          render: err.response.data.message,
          type: "error",
          isLoading: false,
          autoClose: 3000,
          theme: "dark",
        });
      });
  };
  return (
    <div className="booking-driver2">
      <ToastContainer />
      <div className="row">
        <div className="col-lg-6 driver-form">
          <label>
            Full Name <span>*</span>
          </label>
          <input
            type="text"
            id="Full Name"
            name="name"
            value={bookingDetails.name}
            onChange={(e) =>
              alphaBetsOnly(
                e,
                setBookingDetails,
                bookingDetails,
                setErrors,
                errors
              )
            }
            placeholder="Username"
            className={
              errors.name === "blank"
                ? "form-control"
                : errors.name == ""
                ? "form-control is-valid"
                : "form-control is-invalid"
            }
          />
          <div class="invalid-feedback">{errors.name}</div>
        </div>
        <div className="col-lg-6 driver-form">
          <label>
            Phone Number <span>*</span>
          </label>
          <input
            type="number"
            onWheel={(event) => event.currentTarget.blur()}
            id="Phone Number"
            name="ph_no"
            value={bookingDetails.ph_no}
            onChange={(e) => handleChange(e)}
            placeholder="+123 456-6789"
            className={
              errors.ph_no === "blank"
                ? "form-control"
                : errors.ph_no == ""
                ? "form-control is-valid"
                : "form-control is-invalid"
            }
          />
          <div class="invalid-feedback">{errors.ph_no}</div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 driver-form">
          <label>
            Email <span>*</span>
          </label>
          <input
            type="email"
            id="E-Mail"
            name="email"
            value={bookingDetails.email}
            onChange={(e) =>
              emailOnly(e, setBookingDetails, bookingDetails, setErrors, errors)
            }
            placeholder="Your email address"
            className={
              errors.email === "blank"
                ? "form-control"
                : errors.email == ""
                ? "form-control is-valid"
                : "form-control is-invalid"
            }
          />
          <div class="invalid-feedback">{errors.email}</div>
        </div>
        <div className="col-lg-6 driver-form">
          <label>
            Home Address <span>*</span>
          </label>
          <input
            type="text"
            placeholder="Address"
            className={
              errors.address === "blank"
                ? "form-control"
                : errors.address == ""
                ? "form-control is-valid"
                : "form-control is-invalid"
            }
            id="Address"
            name="address"
            value={bookingDetails.address}
            onChange={(e) => handleChange(e)}
          />
          <div class="invalid-feedback">{errors.address}</div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 driver-form">
          <label>
            ID <span>*</span>
          </label>
          <select
            className={
              errors.id === "blank"
                ? "form-control"
                : errors.id == ""
                ? "form-control is-valid"
                : "form-control is-invalid"
            }
            id="Type Of Id"
            name="id"
            value={bookingDetails.id}
            onChange={(e) => handleChange(e)}
          >
            <option value="" selected>
              Select a Id{" "}
            </option>
            <option value="1">Driving License </option>
            <option value="2">Passport</option>
          </select>
          <div class="invalid-feedback">{errors.id}</div>
        </div>
        <div className="col-lg-6 driver-form">
          <label>
            Do you have a Car <span>*</span>
          </label>
          <select
            className={
              errors.car === "blank"
                ? "form-control"
                : errors.car == ""
                ? "form-control is-valid"
                : "form-control is-invalid"
            }
            id="Car"
            name="car"
            value={bookingDetails.car}
            onChange={(e) => handleChange(e)}
          >
            <option value="" selected>
              Select
            </option>
            <option value="1">Yes</option>
            <option value="0">No</option>
          </select>
          <div class="invalid-feedback">{errors.car}</div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 driver-form">
          <label>
            Age <span>*</span>
          </label>
          <input
            type="text"
            placeholder="Age"
            className={
              errors.age === "blank"
                ? "form-control"
                : errors.age == ""
                ? "form-control is-valid"
                : "form-control is-invalid"
            }
            id="Age"
            name="age"
            value={bookingDetails.age}
            onChange={(e) => handleChange(e)}
          />
          <div class="invalid-feedback">{errors.age}</div>
        </div>
        <div className="col-lg-6 driver-form">
          <label>
            Driving Experience <span>*</span>
          </label>
          <input
            type="text"
            placeholder="Driving Experience"
            className={
              errors.drivingExperience === "blank"
                ? "form-control"
                : errors.drivingExperience == ""
                ? "form-control is-valid"
                : "form-control is-invalid"
            }
            id="Driving Experience"
            name="drivingExperience"
            value={bookingDetails.drivingExperience}
            onChange={(e) => handleChange(e)}
          />
          <div class="invalid-feedback">{errors.drivingExperience}</div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 driver-form">
          <label>
            Model Number <span>*</span>
          </label>
          <input
            type="text"
            placeholder="Model Number"
            className={
              errors.modelNumber === "blank"
                ? "form-control"
                : errors.modelNumber == ""
                ? "form-control is-valid"
                : "form-control is-invalid"
            }
            id="Model Number"
            name="modelNumber"
            value={bookingDetails.modelNumber}
            onChange={(e) => handleChange(e)}
          />
          <div class="invalid-feedback">{errors.modelNumber}</div>
        </div>
        <div className="col-lg-6 driver-form">
          <label>
            NI Number <span>*</span>
          </label>
          <input
            id="Ni Number"
            name="NiNumber"
            value={bookingDetails.NiNumber}
            onChange={(e) => handleChange(e)}
            type="text"
            placeholder="NI Number"
            className={
              errors.NiNumber === "blank"
                ? "form-control"
                : errors.NiNumber == ""
                ? "form-control is-valid"
                : "form-control is-invalid"
            }
          />
          <div class="invalid-feedback">{errors.NiNumber}</div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 driver-form">
          <label>
            Black Suit <span>*</span>
          </label>
          <select
            className={
              errors.blackSuit === "blank"
                ? "form-control"
                : errors.blackSuit == ""
                ? "form-control is-valid"
                : "form-control is-invalid"
            }
            id="Black Suit"
            name="blackSuit"
            value={bookingDetails.blackSuit}
            onChange={(e) => handleChange(e)}
          >
            <option value="" selected>
              Select
            </option>
            <option value="1">Yes</option>
            <option value="0">No</option>
          </select>
          <div class="invalid-feedback">{errors.email}</div>
        </div>
        <div className="col-lg-6 driver-form">
          <label>
            Upload Your CV (Optional)<span>*</span>
          </label>
          <div>
            <input
              type="file"
              id="upload"
              hidden
              onChange={(e) =>
                setUploadedImages((oldArray) => [
                  ...oldArray,
                  e.target.files[0],
                ])
              }
            />
            <label htmlFor="upload" className="file-up">
              Upload
            </label>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 driver-form">
          <label>Responsible to booking</label>
          <div className="driver-form-checkbox">
            <div className="chkboxmain">
              <input id="Option1" type="checkbox" />
              <label className="checkbox" htmlFor="Option1">
                YES
              </label>
            </div>
            <div className="chkboxmain">
              <input id="Option2" type="checkbox" />
              <label className="checkbox" htmlFor="Option2">
                NO
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 text-center">
          <button onClick={validate} type="button" className="btn-style-two">
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}

export default Body;
