import React from "react";
import Body from "../Components/Login/Body";
import RegisterBody from "../Components/Register/Body";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

function LoginModal({
  loginModal,
  toggleLoginModal,
  showRegister,
  setShowRegister,
}) {
  return (
    <>
      <Modal
        size="xl"
        className="full-width-modal"
        isOpen={loginModal}
        toggle={toggleLoginModal}
      >
        <ModalHeader>
          <h4 style={{ fontSize: "17px", margin: "0px" }}>
            {showRegister ? "Register" : "Login"}
          </h4>
        </ModalHeader>
        <ModalBody>
          {showRegister ? (
            <RegisterBody setShowRegister={setShowRegister} />
          ) : (
            <Body setShowRegister={setShowRegister} />
          )}
        </ModalBody>
        <ModalFooter>
          <button
            onClick={(e) => {
              toggleLoginModal();
            }}
            type="button"
            class="btn btn-danger"
          >
            Close
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default LoginModal;
