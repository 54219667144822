import React, { useEffect, useState } from "react";
import { APIURL } from "../../../Methods/Fetch";
import { axiosGet } from "../../../Methods/Save";
import Skeleton from "@mui/material/Skeleton";
import { Link, useNavigate } from "react-router-dom/dist";
// import Carousel from "react-multi-carousel";
import { ToastContainer, toast } from "react-toastify";
import "react-multi-carousel/lib/styles.css";
import Carousel from "better-react-carousel";
import { SyncLoader } from "react-spinners";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";

function Body() {
  const navigate = useNavigate();
  const [showChars, setShowChars] = useState(localStorage.getItem("showChars"));
  const [activateSelectSecondPlayer, setActivateSelectSecondPlayer] = useState(
    localStorage.getItem("activateSelectSecondPlayer")
  );
  const [players, setPlayers] = useState(localStorage.getItem("players"));
  const [characters, setCharacters] = useState();
  const [reselectChar1, setReselectChar1] = useState();
  const [showNext, setShowNext] = useState(false);
  const [charTypes, setCharTypes] = useState();
  const [selectedCharTypes, setSelectedCharType] = useState(
    JSON.parse(localStorage.getItem("charType"))
  );
  const [selectedCharObj, setSelectedCharObj] = useState(
    JSON.parse(localStorage.getItem("characterOne"))
  );
  const [selectedCharObj2, setSelectedCharObj2] = useState(
    JSON.parse(localStorage.getItem("characterTwo"))
  );
  // const [selectedCharObj, setSelectedCharObj] = useState(
  //   localStorage.getItem("selectedChar")
  // );

  useEffect(() => {
    if (selectedCharTypes && Object.keys(selectedCharTypes).length > 0) {
      var url = APIURL() + `chars/${selectedCharTypes.id}`;
      axiosGet(url).then((response) => {
        setCharacters(response.data[0].response.data);
      });
    }
  }, [selectedCharTypes]);
  useEffect(() => {
    setLoading(true);
    var url = APIURL() + "types";
    axiosGet(url).then((response) => {
      setCharTypes(response.data[0].response.data);
      setLoading(false);
    });
  }, []);
  const selectCharacter1 = () => {
    if (players == 1) setShowNext(true);
    else {
      toast.success("Please Select Second Player!", {
        position: toast.POSITION.TOP_CENTER,
        theme: "dark",
      });
      setActivateSelectSecondPlayer("yes");
    }
  };
  const selectCharacterType = (charType) => {
    if (players != "") setSelectedCharType(charType);
    else {
      toast.error("Please select Players!", {
        position: toast.POSITION.TOP_RIGHT,
        theme: "dark",
      });
    }
  };
  const selectCharacter = (char) => {
    if (activateSelectSecondPlayer == "yes") {
      if (selectedCharObj.id == char.id) {
        toast.error("Unselected first player!", {
          position: toast.POSITION.TOP_CENTER,
          theme: "dark",
        });
        setActivateSelectSecondPlayer("no");
        setSelectedCharObj({});
        // setReselectChar1(true);
        setSelectedCharObj2({});
      } else {
        setSelectedCharObj2(char);
        setShowNext(true);
      }
    } else {
      setSelectedCharObj(char);
      selectCharacter1();
    }
  };
  const saveInLocal = () => {
    localStorage.setItem(
      "activateSelectSecondPlayer",
      activateSelectSecondPlayer
    );
    localStorage.setItem("players", players);
    localStorage.setItem("showChars", showChars);
    localStorage.setItem("charType", JSON.stringify(selectedCharTypes));
    localStorage.setItem("characterOne", JSON.stringify(selectedCharObj));
    if (players == 2)
      localStorage.setItem("characterTwo", JSON.stringify(selectedCharObj2));
    navigate("/step-three");
  };
  const [loading, setLoading] = useState(false);
  console.log("activateSelectSecondPlayer", activateSelectSecondPlayer);

  return (
    <>
      <SyncLoader
        loading={loading}
        className="react-loader-new"
        color="#9a13d2"
      />
      <ToastContainer />

      <div className="desktop-sec">
        <section className="banner-sec">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 mx-auto">
                <div className="top-img">
                  <div className="step3-body">
                    <div className="step3-playerchoose">
                      {showChars == "yes" ? (
                        <div className="player-arrow">
                          <ul>
                            <li className="active">
                              <a href="#">{players}PLAYER</a>
                            </li>
                          </ul>
                        </div>
                      ) : (
                        <div className="player-arrow">
                          <h2 style={{ cursor: "pointer" }}>Select Player!</h2>
                          <ul>
                            <li>
                              <a
                                className={
                                  players
                                    ? players == 1
                                      ? "active"
                                      : "inactive"
                                    : ""
                                }
                                href="#"
                                onClick={(e) => setPlayers(1)}
                              >
                                1PLAYER
                              </a>
                            </li>
                            <li>
                              <a
                                className={
                                  players
                                    ? players == 2
                                      ? "active"
                                      : "inactive"
                                    : ""
                                }
                                href="#"
                                onClick={(e) => setPlayers(2)}
                              >
                                2PLAYER
                              </a>
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>
                    <div className="step3-playerchoose">
                      {showChars == "yes" ? "" : <h2>Select Your Style</h2>}

                      <div
                        className={
                          selectedCharTypes &&
                          Object.keys(selectedCharTypes).length > 0
                            ? "d-none"
                            : "uma-char-types"
                        }
                      >
                        {charTypes && charTypes.length > 0 ? (
                          charTypes.map((charType) => (
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={(e) => selectCharacterType(charType)}
                            >
                              {charType.name}
                            </a>
                          ))
                        ) : (
                          <Skeleton
                            className="fullwidth_skeleton"
                            sx={{ bgcolor: "#ffffff61" }}
                            variant="rectangular"
                            height={400}
                          />
                        )}
                      </div>
                      {selectedCharTypes &&
                      Object.keys(selectedCharTypes).length > 0 &&
                      showChars == "no" ? (
                        <div className="desc-char setp4-box">
                          <h2>{selectedCharTypes.name}</h2>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: selectedCharTypes.description,
                            }}
                          ></p>
                       
                          <div className="btn-box-yesno">
                            <button
                              onClick={(e) => setShowChars("yes")}
                              className="btn-yes"
                            >
                              Yes
                            </button>
                            <button
                              onClick={(e) => setSelectedCharType()}
                              className="btn-no"
                            >
                              No
                            </button>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {showChars == "yes" && selectedCharTypes ? (
                        <div className="desc-char setp4-box">
                          <h2>{selectedCharTypes.name}</h2>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: selectedCharTypes.description,
                            }}
                          ></p>
                          {characters && characters.length > 0 ? (
                            <Carousel cols={2} rows={1} hideArrow={false}>
                              {characters.map((char, index) => (
                                <Carousel.Item key={index}>
                                  <div className="flex-char">
                                    <div className="p-tag">
                                      <h2
                                        className={
                                          char.id == selectedCharObj.id
                                            ? ""
                                            : "opa_zero"
                                        }
                                      >
                                        P1
                                      </h2>
                                      <h2
                                        className={
                                          char.id == selectedCharObj2.id
                                            ? ""
                                            : "opa_zero"
                                        }
                                      >
                                        P2
                                      </h2>
                                    </div>

                                    <a
                                      style={{ border: "1px solid red" }}
                                      className={
                                        Object.keys(selectedCharObj).length ===
                                        0
                                          ? ""
                                          : char.id == selectedCharObj.id ||
                                            char.id == selectedCharObj2.id
                                          ? "active"
                                          : "grayScaled"
                                      }
                                      style={{ cursor: "pointer" }}
                                      onClick={(e) => {
                                        selectCharacter(char);
                                      }}
                                    >
                                      <img src={char.image} alt="char" />
                                    </a>
                                  </div>
                                </Carousel.Item>
                              ))}
                            </Carousel>
                          ) : (
                            <Skeleton
                              sx={{ bgcolor: "#ffffff61" }}
                              variant="rectangular"
                              height={150}
                              className="fullwidth_skeleton"
                            />
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div class="scene-prevbtn">
                    <Link to="/step-one">
                      <span>Back</span>
                    </Link>
                  </div>
                  {showNext ? (
                    <div class="scene-nextbtn">
                      <a onClick={saveInLocal} className="btn_anchor">
                        <span> Next</span>
                      </a>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="mobile-sec ">
        <div className="mob-img-box step3">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 mx-auto">
                <div className="top-img">
                  <div className="step3-body">
                    <div className="step3-playerchoose">
                      {showChars == "yes" ? (
                        <div className="player-arrow">
                          <ul>
                            <li className="active">
                              <a href="#">{players}PLAYER</a>
                            </li>
                          </ul>
                        </div>
                      ) : (
                        <div className="player-arrow">
                          <h2 style={{ cursor: "pointer" }}>Select Player!</h2>
                          <ul>
                            <li className="active">
                              <a
                                className={
                                  players
                                    ? players == 1
                                      ? "active btn_anchor"
                                      : "inactive btn_anchor"
                                    : "btn_anchor"
                                }
                                onClick={(e) => setPlayers(1)}
                              >
                                1PLAYER
                              </a>
                            </li>
                            <li>
                              <a
                                className={
                                  players
                                    ? players == 2
                                      ? "active btn_anchor"
                                      : "inactive btn_anchor"
                                    : "btn_anchor"
                                }
                                onClick={(e) => setPlayers(2)}
                              >
                                2PLAYER
                              </a>
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>
                    <div className="step3-playerchoose ">
                      {showChars == "yes" ? "" : <h2>Select Your Style</h2>}

                      <div
                        className={
                          selectedCharTypes &&
                          Object.keys(selectedCharTypes).length > 0
                            ? "d-none"
                            : "uma-char-types"
                        }
                      >
                        {charTypes && charTypes.length > 0 ? (
                          charTypes.map((charType) => (
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={(e) => selectCharacterType(charType)}
                            >
                              {charType.name}
                            </a>
                          ))
                        ) : (
                          <Skeleton
                            className="fullwidth_skeleton"
                            sx={{ bgcolor: "#ffffff61" }}
                            variant="rectangular"
                            height={400}
                          />
                        )}
                      </div>
                      {selectedCharTypes &&
                      Object.keys(selectedCharTypes).length > 0 &&
                      showChars == "no" ? (
                        <div className="desc-char">
                          <h2 className="sl-style">{selectedCharTypes.name}</h2>

                          <p
                            dangerouslySetInnerHTML={{
                              __html: selectedCharTypes.description,
                            }}
                          ></p>
                          <div className="btn-box-yesno">
                            <button
                              onClick={(e) => setShowChars("yes")}
                              className="btn-yes"
                            >
                              Yes
                            </button>
                            <button
                              onClick={(e) => setSelectedCharType()}
                              className="btn-no"
                            >
                              No
                            </button>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {showChars == "yes" && selectedCharTypes ? (
                        <div className="desc-char">
                          <h2 className="sl-style">{selectedCharTypes.name}</h2>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: selectedCharTypes.description,
                            }}
                          ></p>

                          {characters && characters.length > 0 ? (
                            <Splide
                              options={{
                                perPage: 1,
                                pagination: false,
                              }}
                            >
                              {characters.map((char, index) => (
                                <SplideSlide key={index}>
                                  <div className="flex-char uma-splide">
                                    <div>
                                      <h2
                                        className={
                                          char.id == selectedCharObj.id
                                            ? ""
                                            : "opa_zero"
                                        }
                                      >
                                        P1
                                      </h2>
                                      <h2
                                        className={
                                          char.id == selectedCharObj2.id
                                            ? ""
                                            : "opa_zero"
                                        }
                                      >
                                        P2
                                      </h2>
                                    </div>

                                    <a
                                      style={{ border: "1px solid red" }}
                                      className={
                                        Object.keys(selectedCharObj).length ===
                                        0
                                          ? ""
                                          : char.id == selectedCharObj.id ||
                                            char.id == selectedCharObj2.id
                                          ? "active"
                                          : "grayScaled"
                                      }
                                      style={{ cursor: "pointer" }}
                                      onClick={(e) => {
                                        selectCharacter(char);
                                      }}
                                    >
                                      <img src={char.image} alt="char" />
                                    </a>
                                  </div>
                                </SplideSlide>
                              ))}
                            </Splide>
                          ) : (
                            <Skeleton
                              sx={{ bgcolor: "#ffffff61" }}
                              variant="rectangular"
                              height={150}
                              className="fullwidth_skeleton"
                            />
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div class="scene-prevbtn">
                  <Link to="/step-one">
                    <span>Back</span>
                  </Link>
                </div>
                {showNext ? (
                  <div class="scene-nextbtn">
                    <a onClick={saveInLocal} className="btn_anchor">
                      <span> Next</span>
                    </a>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <img src="images/scene2.gif" alt />
        </div>
      </div>
    </>
  );
}

export default Body;
