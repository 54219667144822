import React from "react";
import { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

function VideoModal(props) {
  const { modal, toggle } = props;
  return (
    <>
      <Modal className="modal-lg" isOpen={modal} toggle={toggle} backdrop="static">
        <ModalBody>
          <div>
            <button
              type="button"
              className="btn-close"
              style={{ zIndex: 99 }}
              onClick={toggle}
            />
            <video muted autoPlay loop playsInline controls>
              <source src="images/sample-video.mp4" type="video/mp4" />
              Your browser does not support HTML5 video.
            </video>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}

export default VideoModal;
