import { React, useEffect, useState } from "react";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import Icons from "../Common/Icons";
import Body from "../Components/BlogDetails/Body";
import { APIURL } from "../Methods/Fetch";
import { axiosGet } from "../Methods/Save";

function BlogDetails(props) {
  const { setBackGroundColor } = props;
  useEffect(() => {
    setBackGroundColor("bg-texture-gradient gradient2");
  }, []);
  const [pageDetails, setPageDetails] = useState([]);
  useEffect(() => {
    var url = APIURL() + "blog";
    axiosGet(url).then((response) => {
      console.log(response);
      setPageDetails(response.data[0].response.data);
    });
  }, []);
  return (
    <>
      <Header />
      <Icons />
      <div className="box-margin">
        <Body />
        {/* <Footer className="border-top" /> */}
      </div>
    </>
  );
}

export default BlogDetails;
